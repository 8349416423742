var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
export var ModuleSplit = function () {
    var history = useHistory();
    var _a = useState(), url = _a[0], setUrl = _a[1];
    useEffect(function () {
        window.urlHistory = history;
    }, []);
    useEffect(function () {
        var url = location.pathname + location.search;
        var urlSplit = url.split("/");
        urlSplit.splice(0, 2);
        url = urlSplit.join("/");
        var nowDate = new Date();
        url += (url.indexOf("?") > -1 ? "&" : "?") + "timeString=" + nowDate.getTime();
        setUrl(location.origin + "/" + url);
    }, [location.pathname]);
    return (<Wrapper>
    {url ?
        <iframe key={url} style={{ height: "100%", width: "100%" }} width={"100%"} height={"100%"} frameBorder={0} src={url}/> : null}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n"], ["\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n"])));
var templateObject_1;
