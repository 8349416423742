import * as React from "react";
import { useState } from "react";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MyFormWrapper, MyItemWrapper } from "@components/common/myFormWrapper";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import { l } from "@helpers/multiLangaugeHelper";
import { merge, set } from "lodash";
import { MySelect } from "@components/baseComponents/mySelect";
import { BatchUpload } from "@components/batchUpload/batchUpload";
import { FooterBtnWrapper } from "@components/common/footerBtnWrapper";
import { useHistory } from "react-router-dom";
export var AudienceTranslateEdit = function () {
    var history = useHistory();
    var _a = useState({}), formData = _a[0], setFormData = _a[1];
    var onFormChange = function (value, pathKey) {
        var newData = merge({}, formData);
        set(newData, pathKey, value);
        setFormData(newData);
    };
    var save = function () {
        history.push("/techHub/audience/audienceTranslate/manage");
    };
    var checkSaveDisabled = function () {
        return formData.advertiserId;
    };
    return (<CommonWrapper>
    <MyFormWrapper>
      <MyItemWrapper>
        <MyFormItem required label={l("common_advertiser", "广告主")}>
          <MySelect showSearch={true} allowClear={false} style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_select_advertiser", "Select Advertiser")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={formData.advertiserId} onChange={function (v) { return onFormChange(v, "advertiserId"); }}/>
        </MyFormItem>
      </MyItemWrapper>
      <MyItemWrapper>
        <MyFormItem required label={l("audience_audienceTranslate_tag_group", "标签组合")}>
          <BatchUpload />
        </MyFormItem>
      </MyItemWrapper>
    </MyFormWrapper>
    <FooterBtnWrapper buttonList={[
        {
            type: "primary",
            text: l("common_save", "Save"),
            onClick: function () { return save(); },
        },
    ]}/>
  </CommonWrapper>);
};
