import { l } from "@helpers/multiLangaugeHelper";
export var CONTENT_DESC = function () { return [
    {
        title: l("demo_welcome_content_title_1", "Middle platform"),
        circleColor: ["#FFA800", "#FFEECC"],
        desc: l("demo_welcome_content_desc_1", "Integrate holistic products that across media insights planning, execution and measurement process."),
    },
    {
        title: l("demo_welcome_content_title_2", "One Stop Advertising Marketing Solution"),
        circleColor: ["#33E8B4", "#D6F6ED"],
        // tslint:disable-next-line:max-line-length
        desc: l("demo_welcome_content_desc_2", "One- stop advertising precision marketing solution which could apply to the marketing lifecycle from advertising planning, execution to measurement stage."),
    },
    {
        title: l("demo_welcome_content_title_3", "Digital Transformation"),
        circleColor: ["#1890FF", "#CBE6FF"],
        desc: l("demo_welcome_content_desc_3", "Technological main force that helps our clients to achieve digital transformation."),
    },
]; };
