var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
import { ACTION_COLUMNS } from "@/common/commonAction";
import { SEGMENT_NAME_WIDTH } from "@/common/styled";
export var comparisonColumns = function (profileType) { return [
    {
        key: "comparisonName",
        title: l("profiling_profile_comparison_name", "Comparison Name"),
        fixed: "left",
        render: [
            {
                key: "comparisonName",
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
            },
            {
                key: "comparisonId",
                label: l("profiling_profile_comparisonId", "Comparison Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "dimension",
        title: l("profiling_profile_dimension", "Dimension"),
        render: [
            {
                key: "dimension",
                type: TABLE_RENDER_TYPE_ENUM.APPLY
            }
        ]
    },
    {
        key: "status",
        title: l("profiling_profile_statusName", "Status"),
        render: [
            {
                key: "status",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "lastUpdateDate",
        title: l("common_last_update_date", "最后修改日期"),
    },
    {
        key: "operation",
        title: l("common_operation", "Action"),
        fixed: "right",
        render: [
            {
                key: "detailItem",
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                text: l("common_operation_detail", "detail"),
                hide: function (record) { return !record.actionReport; },
                options: {
                    path: "/techHub/profiling/" + profileType + "/dashboard/comparison",
                    params: { id: "comparisonId", profilingTypeId: "profilingTypeId" }
                }
            },
            {
                key: "downloadItem",
                hide: function (record) { return !record.actionDownload; },
                type: TABLE_RENDER_TYPE_ENUM.APPLY
            },
        ]
    },
]; };
export var segmentListColumns = function () { return [
    {
        key: "segmentId",
        width: 100,
        title: l("profiling_profile_segment_id", "Segment Id"),
        align: "right"
    },
    {
        key: "segmentName",
        width: SEGMENT_NAME_WIDTH,
        title: l("profiling_profile_segmentName", "Segment Name"),
    },
    {
        key: "advertiserName",
        title: l("profiling_profile_advertiserName", "Advertiser")
    },
]; };
export var segmentColumns = function (profileType) { return [
    {
        key: "segmentName",
        fixed: "left",
        title: l("common_audience", "人群"),
        render: [
            {
                key: "segmentName",
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
            },
            {
                key: "taskId",
                label: l("common_task_code", "任务Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
            {
                key: "segmentId",
                label: l("common_audience_id", "人群Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "advertiserName",
        title: l("common_advertiser", "Advertiser"),
    },
    {
        key: "audienceSize",
        align: "right",
        title: l("common_audience_size", "人群规模"),
        render: [
            {
                key: "audienceSize",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                }
            }
        ]
    },
    {
        key: "sourceName",
        title: l("common_date_source", "数据源"),
    },
    {
        key: "dimension",
        title: l("profiling_profile_dimension", "Dimension"),
        render: [
            {
                key: "dimension",
                type: TABLE_RENDER_TYPE_ENUM.APPLY
            }
        ]
    },
    {
        key: "status",
        title: l("profiling_profile_statusName", "Status"),
        render: [
            {
                key: "status",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "lastUpdateDate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
                label: l("common_last_update", "最后修改") + ": ",
            },
            {
                key: "lastApprovedDate",
                label: l("common_last_approved", "最后审批") + ": ",
            }
        ]
    },
    {
        key: "operation",
        title: l("common_operation", "Action"),
        fixed: "right",
        render: [
            {
                key: "detailItem",
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                text: l("common_operation_detail", "detail"),
                hide: function (record) { return !record.actionReport; },
                options: {
                    path: "/techHub/profiling/" + profileType + "/dashboard/segment",
                    params: { id: "taskId", profilingTypeId: "profilingTypeId" }
                }
            },
            {
                key: "editItem",
                text: l("common_edit", "edit"),
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                options: {
                    path: "/techHub/profiling/" + profileType + "/edit",
                    params: {
                        id: "taskId"
                    }
                },
                hide: function (record) { return !record.actionEdit; }
            },
            {
                key: "downloadItem",
                hide: function (record) { return !record.actionDownload; },
                type: TABLE_RENDER_TYPE_ENUM.APPLY
            },
            {
                key: "more",
                type: TABLE_RENDER_TYPE_ENUM.MORE,
                render: __spreadArrays(ACTION_COLUMNS())
            }
        ]
    },
]; };
export var createComparisonColumns = function () { return [
    {
        key: "segmentId",
        width: 100,
        title: l("profiling_profile_segment_id", "Segment Id"),
        align: "right"
    },
    {
        key: "segmentName",
        width: SEGMENT_NAME_WIDTH,
        title: l("profiling_profile_segmentName", "Segment Name")
    },
    {
        key: "advertiserName",
        title: l("profiling_profile_advertiserName", "Advertiser")
    },
    {
        key: "operation",
        title: l("common_operation", "Action"),
        fixed: "right",
        render: [
            {
                key: "delItem",
                text: l("common_delete", "delete"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL
            }
        ]
    },
]; };
