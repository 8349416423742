import * as React from "react";
import { useEffect } from "react";
import { logoutApi } from "@components/contentWrapper/helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
export var Logout = function () {
    useEffect(function () {
        logoutApi()
            .then(msgSuccess)
            .catch(msgError);
    }, []);
    return (<div>已登出</div>);
};
