import { post } from "@helpers/axiosHelper";
import { commonDeleteApi } from "@/common/commonAction";
import { STATUS_OPTIONS_TYPE } from "@/common/const";
export var getList = function (data) {
    return post("/groupme/audex/audience/OTVAudience/getList", data);
};
export var deleteApi = function (data) {
    return commonDeleteApi(data, STATUS_OPTIONS_TYPE.AUDIENCE_OTV_AUDIENCE);
    // return post("/groupme/audex/audience/OTVAudience/deleteByTaskId",data)
};
