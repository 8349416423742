var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getImgUrl } from "@helpers/const";
import { l } from "@helpers/multiLangaugeHelper";
import { msgError } from "@helpers/axiosHelper";
import { outInfoApi } from "@pages/defaultPage/helpers/async";
export var OutLinkPage = function () {
    var _a = useState(), outInfo = _a[0], setOutInfo = _a[1];
    useEffect(function () {
        setOutInfo(null);
        outInfoApi(location.pathname)
            .then(function (res) {
            setOutInfo(res.data);
        })
            .catch(msgError);
    }, [location.pathname]);
    return <Wrapper>
    <img src={getImgUrl("outLink.png")} alt={""}/>
    {outInfo ? <TipsText>
      <p>
        {l("defaultPage_outLine_go_to", "Go to")}
        <a onClick={function () { return window.open(outInfo.url); }}>{outInfo.name}</a>
      </p>
    </TipsText> : null}
  </Wrapper>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  > img {\n    -webkit-user-drag: none;\n  }\n"], ["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  > img {\n    -webkit-user-drag: none;\n  }\n"])));
var TipsText = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  a {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"], ["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  a {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"])));
var templateObject_1, templateObject_2;
