var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";
import { AADLogin, enterLogin, getOpcoTitle, sessionCheck } from "@pages/account/helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { validateUsername } from "@pages/account/helpers/validator";
import { l } from "@helpers/multiLangaugeHelper";
import { getImgUrl, LOCAL_STORAGE_KEY, LOCAL_STORAGE_OPCO_KEY } from "@helpers/const";
import { updateLanguage } from "@helpers/async";
import { MySelect } from "@components/baseComponents/mySelect";
import md5 from "md5";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@helpers/msalConfig";
import { MySpin } from "@components/baseComponents/mySpin";
import { MyModal } from "@components/baseComponents/myModal";
import { getRedirectUrlParams } from "@/utils/unit";
import { SkinWrapper } from "@components/contentWrapper/skinWrapper";
import { MAIN_COLOR } from "@/common/styled";
export var EnterOpco = function () {
    var $msalInstance = new PublicClientApplication(msalConfig);
    var history = useHistory();
    var opco = useParams().opco;
    var hasUsername = queryString.parse(location.search).username;
    // @ts-ignore
    var statsUsername = history.location.state && history.location.state.username;
    // @ts-ignore
    var statsPassword = history.location.state && history.location.state.password;
    var _a = useState({ mainColor: MAIN_COLOR }), skin = _a[0], setSkin = _a[1];
    var directPath = queryString.parse(location.search).directPath;
    var _b = useState(hasUsername || statsUsername), username = _b[0], setUsername = _b[1];
    var _c = useState(statsPassword), password = _c[0], setPassword = _c[1];
    var _d = useState(), expirationDays = _d[0], setExpirationDays = _d[1];
    var _e = useState(), aadFlag = _e[0], setAadFlag = _e[1];
    var _f = useState(), bgImg = _f[0], setBgImg = _f[1];
    var _g = useState(), jumpUrl = _g[0], setJumpUrl = _g[1];
    var _h = useState(true), loading = _h[0], setLoading = _h[1];
    var _j = useState("center"), loginPosition = _j[0], setLoginPosition = _j[1];
    var _k = useState(180), logoWidth = _k[0], setLogoWidth = _k[1];
    useEffect(function () {
        if (window.opco && window.opco !== opco) {
            history.push("/enter/" + window.opco);
            return;
        }
        getOpcoTitle(opco)
            .then(function (res) {
            window.opcoTitle = res.data.opcoTitle || res.data.opco;
            if (res.data.skin) {
                setSkin(res.data.skin);
                window.skin = res.data.skin;
            }
            document.title = res.data.opcoTitle || res.data.opco;
            setAadFlag(res.data.aadFlag);
            setBgImg(res.data.backgroundFileName);
            setLoginPosition(res.data.loginPosition);
            setLogoWidth(res.data.logoWidth || 180);
            setLoading(false);
            if (res.data.icoUrl) {
                var ico = document.getElementById("ico");
                var icoUrl = "/objects/groupm/img/favicon/" + res.data.icoUrl + ".ico";
                ico.href = icoUrl;
            }
        });
        sessionCheck({ opco: opco })
            .then(function (res) {
            location.href = res.data.url ? res.data.url : "/techHub/workplace/index";
        });
    }, [opco]);
    var onLogin = function () {
        var data = { username: username, password: password, opco: opco, languageCode: window.LANG };
        var defaultLocalStorage = {
            lastTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            times: 0,
        };
        if (errorMsg) {
            return;
        }
        if (!(username && username !== "" && password && password !== "")) {
            msgError(l("account_enter_input_username_err", "请输入用户名/密码"));
            return;
        }
        var loginLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (loginLocalStorage && loginLocalStorage !== "") {
            loginLocalStorage = JSON.parse(loginLocalStorage);
            if (moment(loginLocalStorage.lastTime).valueOf() - moment().subtract(30, "minutes").valueOf() < 0) {
                localStorage.removeItem(LOCAL_STORAGE_KEY);
                loginLocalStorage = defaultLocalStorage;
            }
            if (loginLocalStorage && loginLocalStorage.times > 10) {
                msgError(l("account_enter_input_password_err", "账号密码输入错误过多，请30分钟后再试"));
                return;
            }
        }
        else {
            loginLocalStorage = defaultLocalStorage;
        }
        enterLogin(data)
            .then(function (res) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            localStorage.setItem(LOCAL_STORAGE_OPCO_KEY, opco);
            window.LANG = res.data.languageCode;
            if (res.data.expirationDays <= 10) {
                setExpirationDays(res.data.expirationDays);
            }
            else {
                msgSuccess(res);
                setJumpUrl(res.data.url);
                var url = res.data.url || "/techHub/workplace/index";
                if (directPath && directPath !== "") {
                    url = getRedirectUrlParams(true);
                }
                location.href = url;
            }
        })
            .catch(function (err) {
            if (err.response.data.data && err.response.data.data.opcoCode) {
                msgError(err);
                history.push("/enter/" + err.response.data.data.opcoCode, { username: username, password: password });
                return;
            }
            loginLocalStorage.times += 1;
            loginLocalStorage.lastTime = moment().format("YYYY-MM-DD HH:mm:ss");
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(loginLocalStorage));
            loginLocalStorage.times > 10 ?
                msgError(l("account_enter_input_password_err", "账号密码输入错误过多，请30分钟后再试"))
                : msgError(err);
        });
    };
    var replaceStr = function (str, index, char) {
        var nowTime = moment().format("YYYY-MM-DD");
        var strAry = str.split("");
        strAry[index] = char;
        var resloveString = strAry.join("");
        return resloveString + "_" + nowTime;
    };
    var ssoLogin = function () {
        $msalInstance
            .loginPopup({ scopes: [] })
            .then(function (res) {
            var index = moment().get("isoWeekday") - 1;
            var account = res.account.username.toLowerCase();
            var charCodeAscii = account.charCodeAt(index);
            var transformUpperCode = /[a-z0-9]/.test(account[index]) ? String.fromCharCode(charCodeAscii - 32) : account[index];
            var result = replaceStr(account, index, transformUpperCode);
            var data = { username: account, code: md5(result), opco: opco, languageCode: window.LANG, aad: res };
            AADLogin(data).then(function (res) {
                msgSuccess(res);
                localStorage.setItem(LOCAL_STORAGE_OPCO_KEY, opco);
                var url = res.data.url || "/techHub/workplace/index";
                if (directPath && directPath !== "") {
                    url = getRedirectUrlParams(true);
                }
                location.href = url;
            }).catch(function (err) {
                if (err.response.data.data && err.response.data.data.opcoCode) {
                    msgError(err);
                    history.push("/enter/" + err.response.data.data.opcoCode);
                    return;
                }
                history.push("/register?email=" + res.account.username);
                msgError(err);
            });
        })
            .catch(function () {
            msgError(l("enter_account_add_err", "AAD登录失败，可能存在微软账号同步延迟"));
            $msalInstance.logoutPopup()
                .catch(function (error) {
                msgError(error);
            });
        });
    };
    var onCancelUpdate = function () {
        if (expirationDays <= 0) {
            return;
        }
        var url = jumpUrl || "/techHub/workplace/index";
        if (directPath && directPath !== "") {
            url = getRedirectUrlParams(true);
        }
        location.href = url;
    };
    var changeLanguage = function (languageCode) {
        localStorage.setItem("languageCode", languageCode);
        updateLanguage(languageCode)
            .then(function (res) {
            location.reload();
        })
            .catch(msgError);
    };
    var errorMsg = validateUsername(username);
    return <MySpin spinning={loading}>
    <SkinWrapper skin={skin}>
      {loading ? null : <Wrapper bg={bgImg}>
        <EnterWrapper position={loginPosition}>
          <LanguageBtn>
            <MySelect allowClear={false} size={"small"} style={{ width: "90px", fontSize: "12px" }} defaultOptions={[{ label: "简体中文", value: "cn" }, { label: "English", value: "en" }]} value={window.LANG} onChange={function (e) { return changeLanguage(e); }}/>
          </LanguageBtn>
          <EnterContentWrapper>
            <img style={{ width: logoWidth + "px" }} alt={""} src={getImgUrl("logo/" + opco + ".png")}/>
            {aadFlag ? <SingleSignOn onClick={ssoLogin}>
              <SingleSignOnImg src={getImgUrl("enter_input_icon.png")} alt="单点登录icon"/>
              {l("account_enter_inner_groupm", "Sign in with GroupM")}
            </SingleSignOn> : null}
            {aadFlag ? <Or>{l("enter_or_text", "or")}</Or> : null}
            <InputWrapper>
              <EnterInput placeholder={l("email_address", "Email address")} value={username} onChange={function (v) { return setUsername(v.target.value); }} hasError={errorMsg}/>
              {errorMsg ? <InputError>{errorMsg}</InputError> : null}
            </InputWrapper>
            <InputWrapper>
              <EnterInput type="password" placeholder={l("common_password", "Password")} onChange={function (v) { return setPassword(v.target.value); }}/>
            </InputWrapper>
            <EnterBtn className={"enterBtn"} type="primary" onClick={onLogin}>{l("account_enter", "Sign in")}</EnterBtn>
            <TipsText className={"enterLink"}>
              <a className={"forget"} onClick={function () { return history.push("/forgetPassword/" + opco + "?username=" + username); }}>
                {l("account_forget_password", "Forgot password")}
              </a>
              <a className={"special"} onClick={function () { return history.push("/register/" + opco); }}>
                {l("account_click_here", "apply for account")}
              </a>
            </TipsText>
          </EnterContentWrapper>
        </EnterWrapper>
        <MyModal maskClosable={false} closable={!(expirationDays <= 0)} title={l("common_warn_tips", "温馨提示")} visible={expirationDays <= 10} onCancel={onCancelUpdate} footer={<>
            {expirationDays <= 0 ? null :
        <Button type="ghost" onClick={onCancelUpdate}>{l("common_cancel", "取消")}</Button>}
            <Button type="primary" onClick={function () { return history.push("/updatePassword/" + opco + "?username=" + username); }}>{l("common_update_password", "修改密码")}</Button>
          </>}>
          {expirationDays <= 0 ? l("update_password_tips1", "您的账号密码已过期，请立即修改密码") :
        l("update_password_tips_2", "您的账号密码即将在") + expirationDays + l("update_password_tips_3", "天后过期，建议前往修改密码")}
        </MyModal>
      </Wrapper>}
    </SkinWrapper>
  </MySpin>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  min-width: 1280px;\n  min-height: 670px;\n  background: ", ";\n  background-size: cover;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  min-width: 1280px;\n  min-height: 670px;\n  background: ", ";\n  background-size: cover;\n"])), function (props) { return "url(" + getImgUrl(props.bg || "enterOpco_bg.png") + ") no-repeat center"; });
var EnterWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n  margin-left: ", ";\n  margin-right: ", ";\n  box-shadow: 0 0 8px #999;\n"], ["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n  margin-left: ", ";\n  margin-right: ", ";\n  box-shadow: 0 0 8px #999;\n"])), function (props) { return props.position === "right" ? "calc(66% - 232px)" : 0; }, function (props) { return props.position === "left" ? "calc(66% - 232px)" : 0; });
var EnterContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 180px;\n  }\n"], ["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 180px;\n  }\n"])));
var EnterInput = styled(Input)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100% !important;\n  height: 42px !important;\n  border-color: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"], ["\n  width: 100% !important;\n  height: 42px !important;\n  border-color: ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"])), function (props) { return props.hasError ? "#f20 !important" : "#D2D2D2 !important"; });
var InputWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  margin-top: 30px;\n  text-align: center;\n  font-size: 22px;\n"], ["\n  position: relative;\n  margin-top: 30px;\n  text-align: center;\n  font-size: 22px;\n"])));
var InputError = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color: #f20;\n"], ["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color: #f20;\n"])));
var EnterBtn = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 24px auto 16px auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #fff;\n  width: 100% !important;\n  height: 48px;\n  background: #1890FF;\n  border-radius: 4px !important;\n  font-size: 22px;\n"], ["\n  margin: 24px auto 16px auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #fff;\n  width: 100% !important;\n  height: 48px;\n  background: #1890FF;\n  border-radius: 4px !important;\n  font-size: 22px;\n"])));
var TipsText = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 10px;\n  margin-bottom: 0;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 400;\n  color: #7D7D7D;\n  > a {\n    cursor: pointer;\n  }\n  > .special {\n    margin-left: 10px;\n  }\n"], ["\n  margin-top: 10px;\n  margin-bottom: 0;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 400;\n  color: #7D7D7D;\n  > a {\n    cursor: pointer;\n  }\n  > .special {\n    margin-left: 10px;\n  }\n"])));
var LanguageBtn = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  top: 30px;\n  right: 32px;\n  color: #fff;\n"], ["\n  position: absolute;\n  top: 30px;\n  right: 32px;\n  color: #fff;\n"])));
var SingleSignOn = styled(Button)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 56px;\n  color: #0A2856;\n  border-radius: 4px !important;\n  border: 2px solid #0A2856;\n  font-size: 20px;\n"], ["\n  margin-top: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 56px;\n  color: #0A2856;\n  border-radius: 4px !important;\n  border: 2px solid #0A2856;\n  font-size: 20px;\n"])));
var SingleSignOnImg = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 23px;\n  height: 25px;\n  margin-right: 22px;\n"], ["\n  width: 23px;\n  height: 25px;\n  margin-right: 22px;\n"])));
var Or = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 20px;\n  height: 28px;\n  font-size: 20px;\n  font-weight: 400;\n  color: #AAA;\n  line-height: 28px;\n  margin: 26px auto;\n"], ["\n  width: 20px;\n  height: 28px;\n  font-size: 20px;\n  font-weight: 400;\n  color: #AAA;\n  line-height: 28px;\n  margin: 26px auto;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
