var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useState } from "react";
import { l } from "@helpers/multiLangaugeHelper";
import { Tabs } from "antd";
import styled from "styled-components";
import { MySpin } from "@components/baseComponents/mySpin";
import queryString from "query-string";
import { map } from "lodash";
import { getChartsData, getDataOverView, getDataOverViewApi, getTopBasicApi, getTopBasicInfo } from "./helpers/detailData/initData";
import { IconFont } from "@/utils/antdIcon";
import { Wordcloud } from "./component/wordCloud";
import { CommonWrapper } from "@components/common/commonWrapper";
import { CommonTitle } from "@components/common/commonTitle";
import { AreaChart } from "@/components/charts/areaChart/areaChart";
var TabPane = Tabs.TabPane;
var dataOverView = getDataOverView();
var basicInfo = getTopBasicInfo();
var TAB_KEY = {
    CLICK: "1",
    LEAVE: "2",
    LIKE: "3",
    COMMENT: "4",
    FORWARD: "5",
    ATTENTION: "6"
};
export var CreativeDetail = function () {
    var id = queryString.parse(location.search).id;
    var _a = useState(false), chartsLoading = _a[0], setChartsLoading = _a[1];
    var onTabChange = function () {
        setChartsLoading(true);
        setTimeout(function () { return setChartsLoading(false); }, 600);
    };
    return (<Wrapper>
      <LeftWrapper>
        <CommonWrapper>
          <CommonTitle>{l("creative_video_fundamental_info", "视频基本信息")}</CommonTitle>
          <ItemWrapper>
            {map(basicInfo.basic, function (item) { return (<Item>
                <SubTitle>{item.label}:</SubTitle>
                <span>{getTopBasicApi(Number(id))[item.key]}</span>
              </Item>); })}
          </ItemWrapper>
        </CommonWrapper>
        <CommonWrapper>
          <CommonTitle>{l("creative_video_content_analysis", "视频内容分析")}</CommonTitle>
          <ItemWrapper>
            {map(basicInfo.analysis.slice(0, 4), function (item) { return (<Item>
                <SubTitle>{item.label}:</SubTitle>
                <span>{getTopBasicApi(Number(id))[item.key]}</span>
              </Item>); })}
          </ItemWrapper>
          <ItemWrapper>
            {map(basicInfo.analysis.slice(4, 7), function (item) { return (<Item>
                <SubTitle>{item.label}:</SubTitle>
                {item.more ? getTopBasicApi(Number(id))[item.key].map(function (item) { return <span>{item}</span>; }) :
        <span>{getTopBasicApi(Number(id))[item.key]}</span>}
              </Item>); })}
            <Item />
          </ItemWrapper>
        </CommonWrapper>
        <CommonWrapper>
          <CommonTitle>{l("creative_overview_data_summary", "数据概览")}</CommonTitle>
          <ItemWrapper>
            {map(dataOverView, function (item) { return (<Item>
                <SubTitle>
                  <Icon type={item.icon}/>
                  <DataOverViewLabel>{item.label}</DataOverViewLabel>
                </SubTitle>
                <DataOverViewNum>{getDataOverViewApi(Number(id))[item.key]}</DataOverViewNum>
              </Item>); })}
          </ItemWrapper>
        </CommonWrapper>
        <CommonWrapper>
          <CommonTitle>{l("creative_interactive_sequence_analysis", "互动时序分析")}</CommonTitle>
          <MySpin spinning={chartsLoading}>
            <Tabs defaultActiveKey={TAB_KEY.CLICK} onChange={onTabChange} tabBarGutter={40}>
              <TabPane tab={l("creative_insight_detail_click", "点击指数")} key={TAB_KEY.CLICK}>
                <AreaChart type={"interactive"} padding={"auto"} appendPadding={[10, 0, 0, 0]} data={getChartsData("click")} position={"time*value"} lineColor="#36CFC9" areaColor="l (90) 0:rgba(54, 207, 201, 0.4) 0% 1:rgba(54, 207, 201, 0) 100%" shared={false}/>
              </TabPane>
              <TabPane tab={l("creative_insight_detail_leave", "流失分布指数")} key={TAB_KEY.LEAVE}>
                <AreaChart type={"interactive"} padding={"auto"} appendPadding={[10, 0, 0, 0]} data={getChartsData("leave")} position={"time*value"} lineColor="#36CFC9" areaColor="l (90) 0:rgba(54, 207, 201, 0.4) 0% 1:rgba(54, 207, 201, 0) 100%" shared={false}/>
              </TabPane>
              <TabPane tab={l("creative_insight_detail_like", "点赞指数")} key={TAB_KEY.LIKE}>
                <AreaChart type={"interactive"} padding={"auto"} appendPadding={[10, 0, 0, 0]} data={getChartsData("like")} position={"time*value"} lineColor="#36CFC9" areaColor="l (90) 0:rgba(54, 207, 201, 0.4) 0% 1:rgba(54, 207, 201, 0) 100%" shared={false}/>
              </TabPane>
              <TabPane tab={l("creative_insight_detail_comment", "评论指数")} key={TAB_KEY.COMMENT}>
                <AreaChart type={"interactive"} padding={"auto"} appendPadding={[10, 0, 0, 0]} data={getChartsData("comment")} position={"time*value"} lineColor="#36CFC9" areaColor="l (90) 0:rgba(54, 207, 201, 0.4) 0% 1:rgba(54, 207, 201, 0) 100%" shared={false}/>
              </TabPane>
              <TabPane tab={l("creative_insight_detail_forward", "转发指数")} key={TAB_KEY.FORWARD}>
                <AreaChart type={"interactive"} padding={"auto"} appendPadding={[10, 0, 0, 0]} data={getChartsData("forward")} position={"time*value"} lineColor="#36CFC9" areaColor="l (90) 0:rgba(54, 207, 201, 0.4) 0% 1:rgba(54, 207, 201, 0) 100%" shared={false}/>
              </TabPane>
              <TabPane tab={l("creative_insight_detail_attention", "关注指数")} key={TAB_KEY.ATTENTION}>
                <AreaChart type={"interactive"} padding={"auto"} appendPadding={[10, 0, 0, 0]} data={getChartsData("attention")} position={"time*value"} lineColor="#36CFC9" areaColor="l (90) 0:rgba(54, 207, 201, 0.4) 0% 1:rgba(54, 207, 201, 0) 100%" shared={false}/>
              </TabPane>
            </Tabs>
          </MySpin>
        </CommonWrapper>
        <CommonWrapper>
          <CommonTitle>{l("creative_insight_relation_word_cloud", "相关词云")}</CommonTitle>
          <Wordcloud />
        </CommonWrapper>
      </LeftWrapper>
      <RightVideo>
        <video style={{ width: 190, height: 340 }} src={getTopBasicApi(Number(id)).url} controls>
          {l("creative_insight_err_video_tag", "您的浏览器不支持video标签")}
        </video>
      </RightVideo>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
var LeftWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: calc(100% - 200px);\n  height: 100%;\n"], ["\n  width: calc(100% - 200px);\n  height: 100%;\n"])));
var ItemWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 18px;\n"], ["\n  display: flex;\n  margin-bottom: 18px;\n"])));
var Item = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 337px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n"], ["\n  width: 337px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n"])));
var RightVideo = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 190px;\n  height: 340px;\n  position: fixed;\n  right: 14px;\n  top: 86px;\n"], ["\n  width: 190px;\n  height: 340px;\n  position: fixed;\n  right: 14px;\n  top: 86px;\n"])));
var SubTitle = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #7D7D7D;\n  margin-bottom: 4px;\n  line-height: 16px;\n"], ["\n  color: #7D7D7D;\n  margin-bottom: 4px;\n  line-height: 16px;\n"])));
var Icon = styled(IconFont)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 18px;\n"], ["\n  font-size: 18px;\n"])));
var DataOverViewNum = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  color: #30373D;\n  margin-left: 4px;\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  color: #30373D;\n  margin-left: 4px;\n"])));
var DataOverViewLabel = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 12px;\n  color: #30373D;\n"], ["\n  font-size: 12px;\n  color: #30373D;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
