import { find } from "lodash";
export var formatNumber = function (num, toFixed) {
    var f = num;
    if (isNaN(f)) {
        return null;
    }
    f = Math.round(num * Math.pow(10, toFixed)) / Math.pow(10, toFixed); // n 幂
    var s = f.toString();
    var rs = s.indexOf(".");
    // 判定如果是整数，增加小数点再补0
    if (rs < 0) {
        rs = s.length;
        s += ".";
    }
    while (s.length <= rs + toFixed) {
        s += "0";
    }
    return s;
};
// 千分位format
export var thousandFormat = function (num) {
    return num ? String(num).replace(/\B(?=(?:\d{3})+(?!\d))/g, ",") : "0";
};
// 数量单位格式化
export var abbreviateNumber = function (value) {
    var result = "<10K";
    if (value >= 10000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = void 0;
        shortValue = suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value;
        result = shortValue < 1 ? (shortValue * 1000).toFixed(1) + suffixes[suffixNum - 1] :
            shortValue.toFixed(1) + suffixes[suffixNum];
    }
    return result;
};
// 防抖
export var debounce = function (func, wait) {
    if (wait === void 0) { wait = 0; }
    if (typeof func !== "function") {
        throw new TypeError("need a function arguments");
    }
    var timeer = null;
    var result;
    return function () {
        var context = this;
        var args = arguments;
        if (timeer) {
            clearTimeout(timeer);
        }
        timeer = setTimeout(function () {
            result = func.apply(context, args);
        }, wait);
        return result;
    };
};
// 节流
export var throttle = function (func, delay) {
    var prev = Date.now();
    return function () {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
            func.apply(context, args);
            prev = Date.now();
        }
    };
};
export var px = function (px) {
    var DEFAULT_WIDTH = 1280;
    var DEFAULT_HEIGHT = 700;
    var ww = window.innerWidth;
    var wh = window.innerHeight;
    var scale = Math.min(ww / DEFAULT_WIDTH, wh / DEFAULT_HEIGHT);
    // return px * scale + "px"
    return px / 10 + "rem";
};
export var pxH = function (px) {
    var DEFAULT_HEIGHT = 700;
    var wh = window.innerHeight;
    var scale = wh / DEFAULT_HEIGHT;
    // return px * scale + "px"
    return px / 10 + "rem";
};
export var getOptionsLabel = function (option, value) {
    if (!value || (value && value.length === 0))
        return null;
    if (value instanceof Array) {
        var resultList_1 = [];
        value.forEach(function (item) {
            var obj = find(option, function (opt) { return opt.value === item; });
            obj ? resultList_1.push(obj.label) : null;
        });
        if (resultList_1.length > 2) {
            resultList_1.length = 2;
            return resultList_1.join(",") + "...";
        }
        return resultList_1.join(",");
    }
    var obj = find(option, function (item) { return item.value === value; });
    return obj ? obj.label : null;
};
export var getRedirectUrlParams = function (onlyPath) {
    var redirectPath = location.search;
    redirectPath = redirectPath.substring(1, redirectPath.length);
    var linkUrl = redirectPath.substring(redirectPath.indexOf("=") + 1, redirectPath.indexOf("?") > 0 ? redirectPath.indexOf("?") : redirectPath.length);
    var params = redirectPath.substring(redirectPath.indexOf(linkUrl) + linkUrl.length + 1, redirectPath.length);
    if (onlyPath) {
        return "" + linkUrl + (params && params !== "" ? "?" : "") + params;
    }
    return "?directPath=" + linkUrl + (params && params !== "" ? "?" : "") + params;
};
