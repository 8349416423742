import * as React from "react";
import { useEffect, useState } from "react";
import { l } from "@helpers/multiLangaugeHelper";
import { Modal, Slider, Tooltip } from "antd";
import { MySelect } from "@components/baseComponents/mySelect";
import { initTextList } from "@helpers/const";
import { useHistory } from "react-router-dom";
import { filter, map, merge, set } from "lodash";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { FooterBtnWrapper } from "@components/common/footerBtnWrapper";
import { COMMON_OPTIONS_TYPE } from "@/common/const";
import { getAudienceType, getDetail, getRMinMax, saveApi } from "./helpers/async";
import queryString from "query-string";
import { MyInput } from "@components/baseComponents/myInput";
import { MyTextArea } from "@components/baseComponents/myTextArea";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import { MyRadio } from "@/components/baseComponents/myRadio";
import { QuestionCircleOutlined } from "@/utils/antdIcon";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MyFormWrapper, MyItemWrapper } from "@components/common/myFormWrapper";
import { MyRangePicker } from "@components/baseComponents/myRangePicker";
import moment from "moment";
export var LocationAudienceEdit = function (_a) {
    var readonly = _a.readonly;
    var history = useHistory();
    var _b = useState({ periodType: 0, period: 30 }), formData = _b[0], setFormData = _b[1];
    var _c = useState(false), saving = _c[0], setSaving = _c[1];
    var _d = useState(false), nameError = _d[0], setNameError = _d[1];
    var _e = useState({}), rMinMax = _e[0], setRMinMax = _e[1];
    var _f = useState(1), radiusNumber = _f[0], setRadiusNumber = _f[1];
    var _g = useState([]), audienceTypeOptions = _g[0], setAudienceTypeOptions = _g[1];
    useEffect(function () {
        var _a = queryString.parse(location.search), id = _a.id, isCopy = _a.isCopy;
        if (id) {
            getDetail(Number(id))
                .then(function (res) {
                var data = res.data;
                if (!readonly && !data.actionEdit && !isCopy) {
                    history.push("/techHub/audience/locationAudience/view?id=" + id);
                }
                if (isCopy) {
                    delete data.taskId;
                    delete data.segmentId;
                    data.segmentName = data.segmentName + "_copy";
                }
                setFormData(data);
                setRadiusNumber(data.radius);
            })
                .catch(msgError);
        }
        if (!id) {
            var newFormData = merge({}, formData);
            set(newFormData, "radius", 1);
            set(newFormData, "audienceType", 0);
            setFormData(newFormData);
        }
    }, []);
    useEffect(function () {
        if (formData.sourceId) {
            getRMinMax({ sourceId: formData.sourceId })
                .then(function (res) {
                setRMinMax(res.data);
            })
                .catch(msgError);
            getAudienceType(formData.sourceId)
                .then(function (res) {
                var newData = merge({}, formData);
                setAudienceTypeOptions(res.data);
                set(newData, "audienceType", res.data && res.data[0] ? res.data[0].value : 0);
                setFormData(newData);
            })
                .catch(msgError);
        }
    }, [formData.sourceId]);
    var onChange = function (value, pathKey) {
        var newData = merge({}, formData);
        if (pathKey === "radius") {
            setRadiusNumber(value);
        }
        if (pathKey === "address") {
            var list = value.split("\n");
            set(newData, pathKey, list);
            setFormData(newData);
            return;
        }
        if (pathKey === "period") {
            set(newData, "dateRange", null);
        }
        if (pathKey === "sourceId") {
            set(newData, "period", null);
            set(newData, "dateRange", null);
        }
        set(newData, pathKey, value);
        setFormData(newData);
    };
    var saveCanCheck = function () {
        if (!formData.segmentName || formData.segmentName === "" || !formData.advertiserId)
            return false;
        if (!formData.radius || !formData.period)
            return false;
        // if (!(typeof(formData.periodType) === "number")) return false
        if (!(formData.periodType || formData.periodType === 0))
            return false;
        var addressList = filter(formData.address, function (item) { return item !== ""; });
        if (addressList.length === 0)
            return false;
        if (formData.period === "custom" && !formData.dateRange)
            return false;
        return !nameError;
    };
    var save = function () {
        setSaving(true);
        saveApi(formData)
            .then(function (res) {
            msgSuccess(res);
            setSaving(false);
            history.push("/techHub/audience/locationAudience/manage");
        })
            .catch(function (err) {
            setSaving(false);
            if (err.response.data.data && err.response.data.data.errAddressList) {
                var addressList = err.response.data.data.errAddressList;
                Modal.info({
                    width: 600,
                    title: err.response.data.msg,
                    content: <div style={{ maxHeight: "500px", overflow: "auto" }}>
              {map(addressList, function (item) {
                        return (<div>{item}</div>);
                    })}
            </div>,
                });
                return;
            }
            msgError(err);
        });
    };
    var getDisabledDate = function (current) {
        if (current.diff(moment().subtract(30, "days").startOf("day")) < 0)
            return true;
        if (current.diff(moment().startOf("day")) >= 0)
            return true;
        return false;
    };
    var initMarks = function () {
        var _a;
        return _a = {},
            _a[rMinMax.min || 0] = {
                style: {
                    color: "#000",
                },
                label: <span>{rMinMax.min || 0}km</span>,
            },
            _a[rMinMax.max || 5] = {
                style: {
                    color: "#000",
                },
                label: <span>{rMinMax.max || 5}km</span>,
            },
            _a;
    };
    return (<CommonWrapper>
      <MyFormWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_audience_name", "人群名称")}>
            <MyInput style={{ width: FORM_ITEM_WIDTH }} disabled={readonly} value={formData.segmentName} placeholder={l("common_segmentName_placeholder", "请输入人群名称")} onChange={function (v) { return onChange(v.target.value, "segmentName"); }} errorMsg={l("common_task_name_err_msg_tips", "任务名称只能输入字母数字及下划线且长度不超过50")} validateReg={"^[0-9a-zA-Z_]{1,50}$"} errorCallBack={function (flag) { return setNameError(flag); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_advertiser", "Advertiser")}>
            <MySelect disabled={readonly} showSearch={true} style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_select_advertiser", "Select Advertiser")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={formData.advertiserId} onChange={function (v) { return onChange(v, "advertiserId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_data_source", "数据源")}>
            <MySelect disabled={readonly} allowClear={false} style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_select_data_source", "请选择数据源")} url={"/groupme/audex/audience/locationAudience_v2/getSourceOptions"} value={formData.sourceId} onChange={function (v) { return onChange(v, "sourceId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={<>{l("common_period_of_time", "时间段")}
              <Tooltip title={l("audience_custom_audience_location_period_choice", "用于限制人群在选定区域出现的时间范围。")} placement={"right"}><QuestionCircleOutlined /></Tooltip></>}>
            <MyRadio disabled={readonly} url={"/groupme/audex/audience/locationAudience_v2/getPeriodOptions"} params={{ sourceId: formData.sourceId }} value={formData.period} onChange={function (v) { return onChange(v.target.value, "period"); }}/>
            {formData.period === "custom" ? <div>
              <MyRangePicker style={{ marginTop: "4px" }} disabled={[readonly, readonly]} disabledDate={getDisabledDate} value={formData.dateRange} onChange={function (v) { return onChange(v, "dateRange"); }}/>
            </div> : null}
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_periodType_of_time", "周期")}>
            <MyRadio disabled={readonly} url={"/groupme/common/getCommonOptions"} params={{ type: COMMON_OPTIONS_TYPE.AUDIENCE_PERIOD_TYPE }} value={formData.periodType} onChange={function (v) { return onChange(v.target.value, "periodType"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_detail_address", "详细地址")}>
            <MyTextArea disabled={readonly} style={{ height: "80px", width: FORM_ITEM_WIDTH }} value={initTextList(formData.address)} placeholder={l("audience_custom_audience_location_address_placeholder", "输入详细地址，重复字段会被移除。输入多个地址，回车键为分隔符。") + "\n" + l("audience_custom_audience_location_memo_placeholder_2", "上海市黄浦区南京西路188号") + "\n" + l("audience_custom_audience_location_memo_placeholder_3", "北京市西城区王府井大街150号")} onChange={function (v) { return onChange(v.target.value, "address"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_radius", "半径")}>
            <div style={{
        width: "30px",
        lineHeight: "14px",
        position: "relative",
        color: "#1890ff",
        top: 3,
        left: radiusNumber / ((rMinMax.max || 5) - (rMinMax.min || 0)) * 100 + "%",
        transform: "translateX(-12px)",
        margin: "-10px 0 0"
    }}>
              {radiusNumber}km
            </div>
            <Slider style={{ width: FORM_ITEM_WIDTH, marginBottom: "40px" }} defaultValue={1.0} marks={initMarks()} tipFormatter={null} disabled={readonly} min={rMinMax.min || 0.0} max={rMinMax.max || 5.0} step={rMinMax.step || 0.1} value={formData.radius} onChange={function (v) { return onChange(v, "radius"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_audience_type", "人群类型")}>
            <MyRadio disabled={readonly} defaultOptions={audienceTypeOptions} value={formData.audienceType} onChange={function (v) { return onChange(v.target.value, "audienceType"); }} params={{ sourceId: formData.sourceId }}/>
          </MyFormItem>
        </MyItemWrapper>
      </MyFormWrapper>
      {readonly ? null : <FooterBtnWrapper buttonList={[
        {
            type: "primary",
            text: l("common_save", "Save"),
            onClick: function () { return save(); },
            disabled: !saveCanCheck(),
            loading: saving
        },
    ]}/>}
    </CommonWrapper>);
};
