var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { AADLogin, getOpcoTitle, sessionCheck } from "@pages/account/helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { l } from "@helpers/multiLangaugeHelper";
import { getImgUrl, LOCAL_STORAGE_OPCO_KEY } from "@helpers/const";
import md5 from "md5";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@helpers/msalConfig";
import { MySpin } from "@components/baseComponents/mySpin";
import { getRedirectUrlParams } from "@/utils/unit";
import { IconFont } from "@/utils/antdIcon";
import { WechatQrcodeEnter } from "@pages/account/components/wechatQrcodeEnter";
export var EnterTimesheet = function () {
    var $msalInstance = new PublicClientApplication(msalConfig);
    var history = useHistory();
    var opco = "ras";
    var directPath = queryString.parse(location.search).directPath;
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), QRVisible = _b[0], setQRVisible = _b[1];
    var _c = useState(), sloganCn = _c[0], setSloganCn = _c[1];
    var _d = useState(), sloganEn = _d[0], setSloganEn = _d[1];
    useEffect(function () {
        window.opcoTitle = "timesheet";
        document.title = "timesheet";
        setLoading(false);
        getOpcoTitle(opco)
            .then(function (res) {
            window.opcoTitle = res.data.opcoTitle || res.data.opco;
            document.title = res.data.opcoTitle || res.data.opco;
            setSloganEn(res.data.sloganEn || l("timesheet_enter_desc_en", "Painting your professional journey, capturing every moment of hard work, joy, milestones, and memories. Effort shines, growth thrives."));
            setSloganCn(res.data.sloganCn || l("timesheet_enter_desc_cn", "时光织锦，织就工作之旅的斑斓画卷。在这里，每一秒都是独特的织线，串联起汗水与欢笑，成就与梦想，让每一次努力都熠熠生辉，每一步成长都清晰可见。"));
            if (res.data.icoUrl) {
                var ico = document.getElementById("ico");
                var icoUrl = "/objects/groupm/img/favicon/" + res.data.icoUrl + ".ico";
                ico.href = icoUrl;
            }
        });
        sessionCheck({ opco: opco })
            .then(function (res) {
            location.href = res.data.url ? res.data.url : "/techHub/workplace/index";
        });
    }, []);
    var replaceStr = function (str, index, char) {
        var nowTime = moment().format("YYYY-MM-DD");
        var strAry = str.split("");
        strAry[index] = char;
        var resloveString = strAry.join("");
        return resloveString + "_" + nowTime;
    };
    var ssoLogin = function () {
        $msalInstance
            .loginPopup({ scopes: [] })
            .then(function (res) {
            var index = moment().get("isoWeekday") - 1;
            var account = res.account.username.toLowerCase();
            var charCodeAscii = account.charCodeAt(index);
            var transformUpperCode = /[a-z0-9]/.test(account[index]) ? String.fromCharCode(charCodeAscii - 32) : account[index];
            var result = replaceStr(account, index, transformUpperCode);
            var data = { username: account, code: md5(result), opco: opco, aad: res };
            AADLogin(data).then(function (res) {
                msgSuccess(res);
                localStorage.setItem(LOCAL_STORAGE_OPCO_KEY, opco);
                var url = res.data.url || "/techHub/workplace/index";
                if (directPath && directPath !== "") {
                    url = getRedirectUrlParams(true);
                }
                location.href = url;
            }).catch(function (err) {
                if (err.response.data.data && err.response.data.data.opcoCode) {
                    msgError(err);
                    history.push("/enter/" + err.response.data.data.opcoCode);
                    return;
                }
                msgError(err);
            });
        })
            .catch(function () {
            msgError(l("enter_account_add_err", "AAD登录失败，可能存在微软账号同步延迟"));
            $msalInstance.logoutPopup()
                .catch(function (error) {
                msgError(error);
            });
        });
    };
    var openQREnter = function () {
        setQRVisible(true);
    };
    return (<MySpin spinning={loading}>
    {loading ? null : <Wrapper>
      <DescWrapper>
      </DescWrapper>
      <EnterWrapper>
        <EnterContentWrapper>
          <img alt={""} src={getImgUrl("logo/timesheet.png")}/>
          <SingleSignOn type={"primary"} onClick={ssoLogin}>
            {l("account_enter_inner_groupm", "Sign in with GroupM")}
          </SingleSignOn>
          <div className={"txt"} style={{ marginBottom: "24px" }}>
            {sloganCn}
          </div>
          <div className={"txt"}>
            {sloganEn}
          </div>
        </EnterContentWrapper>
      </EnterWrapper>
      <WechatQrBtn onClick={openQREnter}><IconFont type={"icon-erweima"}/></WechatQrBtn>
      <div className={"relativeLink"}>
        <div>{l("common_relative_link", "友情链接")}</div>
        <a target={"_blank"} href={"https://chinaess.insidemedia.net/HRLeave/Home.aspx"}>Leave Management</a>
        <a target={"_blank"} href={"https://chinaess.insidemedia.net/Expense/"}>Expense Management</a>
        <a target={"_blank"} href={"https://acontroller.os.choreograph.cn"}>AController</a>
      </div>
    </Wrapper>}
    <WechatQrcodeEnter visible={QRVisible} setVisible={setQRVisible} opco={opco} directPath={directPath}/>
  </MySpin>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  min-width: 1280px;\n  min-height: 670px;\n  background: ", ";\n  background-size: cover;\n  .relativeLink {\n    position: absolute;\n    bottom: 8px;\n    left: 50%;\n    transform: translateX(-50%);\n    padding: 8px 4px;\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    background: rgba(130, 123, 225, 0.15);\n    font-size: 12px;\n    color: #4f536c;\n    > div {\n      margin: 0 14px;\n    }\n    > a {\n      padding: 0 10px;\n      border-left: 1px solid #fff;\n    }\n  }\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  min-width: 1280px;\n  min-height: 670px;\n  background: ", ";\n  background-size: cover;\n  .relativeLink {\n    position: absolute;\n    bottom: 8px;\n    left: 50%;\n    transform: translateX(-50%);\n    padding: 8px 4px;\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    background: rgba(130, 123, 225, 0.15);\n    font-size: 12px;\n    color: #4f536c;\n    > div {\n      margin: 0 14px;\n    }\n    > a {\n      padding: 0 10px;\n      border-left: 1px solid #fff;\n    }\n  }\n"])), "url(" + getImgUrl("timesheet_bg.png") + ") no-repeat center");
var EnterWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 670px;\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 670px;\n"])));
var DescWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  margin-right: 120px;\n  width: 500px;\n  font-size: 18px;\n  color: #31373D;\n"], ["\n  position: relative;\n  margin-right: 120px;\n  width: 500px;\n  font-size: 18px;\n  color: #31373D;\n"])));
var EnterContentWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: auto;\n    display: block;\n    width: 380px;\n  }\n  .txt {\n    font-size: 20px;\n  }\n"], ["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: auto;\n    display: block;\n    width: 380px;\n  }\n  .txt {\n    font-size: 20px;\n  }\n"])));
var SingleSignOn = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 60px;\n  background-color: #0454B8;\n  font-size: 24px;\n  color: #fff;\n"], ["\n  margin-bottom: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 60px;\n  background-color: #0454B8;\n  font-size: 24px;\n  color: #fff;\n"])));
var WechatQrBtn = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  cursor: pointer;\n  font-size: 24px;\n"], ["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  cursor: pointer;\n  font-size: 24px;\n"])));
var QrWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: fixed;\n  display: ", ";\n  overflow: hidden;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background: #fff;\n  box-shadow: 0 0 6px #aaa;\n  border-radius: 8px;\n"], ["\n  position: fixed;\n  display: ", ";\n  overflow: hidden;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background: #fff;\n  box-shadow: 0 0 6px #aaa;\n  border-radius: 8px;\n"])), function (props) { return props.visible ? "block" : "none"; });
var CloseBtn = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  right: 12px;\n  top: 8px;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  right: 12px;\n  top: 8px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
