var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Popover, Tooltip } from "antd";
import { MyTable } from "@components/myTable/myTable";
import { merge } from "lodash";
import { l } from "@helpers/multiLangaugeHelper";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { STATUS_OPTIONS_TYPE } from "@/common/const";
import { getActionApplyObject, getAudienceApplyObject } from "@/common/commonAction";
import { useHistory } from "react-router-dom";
import { deleteApi, getList } from "./helpers/async";
import { columns } from "./helpers/initData";
import styled from "styled-components";
import numeral from "numeral";
import { TableWrapper } from "@/components/common/tableWrapper";
import { MyTableSearch } from "@/components/baseComponents/myTableSearch";
import { getPageDomPermission } from "@components/routerInterceptor/helpers/async";
import { checkPIPLEnabled } from "@helpers/async";
export var OTVAudienceManage = function () {
    var history = useHistory();
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), page = _c[0], setPage = _c[1];
    var _d = useState({}), conditions = _d[0], setConditions = _d[1];
    var _e = useState({}), permission = _e[0], setPermission = _e[1];
    var _f = useState(), PIPLDisabledBtn = _f[0], setPIPLDisabledBtn = _f[1];
    useEffect(function () {
        getPageDomPermission(location.pathname)
            .then(function (res) {
            setPermission(res.data || {});
        });
        checkPIPLEnabled()
            .then(function (res) {
            setPIPLDisabledBtn(res.data.btnDisabled);
        });
        onSearch(conditions, page);
    }, []);
    var onSearch = function (conditions, page) {
        var data = __assign({ conditions: conditions }, page);
        setConditions(conditions);
        setLoading(true);
        getList(data)
            .then(function (res) {
            setData(res.data.list);
            setPage(merge({}, page, { total: res.data.total }));
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var create = function () {
        history.push("/techHub/audience/OTVAudience/edit");
    };
    var del = function (record) {
        deleteApi(record)
            .then(function (res) {
            onSearch(conditions, page);
            msgSuccess(res);
        })
            .catch(msgError);
    };
    var audienceSizePopover = function (record) {
        var title = (<div>
        <div style={{ display: "inline-block", marginRight: "16px", width: "140px" }}>
          <PopoverTitle>{l("common_custom_audience_mobileDevice", "移动设备")}</PopoverTitle>
          <PopoverNum>{numeral(record.mobileDevice).format("0,0")}</PopoverNum>
        </div>
        <div style={{ display: "inline-block" }}>
          <PopoverTitle>{l("common_custom_audience_ott", "OTT")}</PopoverTitle>
          <PopoverNum>{numeral(record.OTT).format("0,0")}</PopoverNum>
        </div>
      </div>);
        var content = (<div>
        <div>
          <div style={{ display: "inline-block", marginRight: "16px", width: "140px" }}>
            <span>{l("common_custom_audience_oaid", "OAID")}</span>
            <PopoverNum>{numeral(record.OAID).format("0,0")}</PopoverNum>
          </div>
          <div style={{ display: "inline-block" }}>
            <span>{l("common_custom_audience_mac", "MAC")}</span>
            <PopoverNum>{numeral(record.MAC).format("0,0")}</PopoverNum>
          </div>
        </div>
        <div>
          <span style={{ marginRight: "5px" }}>{l("common_custom_audience_imei", "IMEI")}</span>
          <PopoverNum>{numeral(record.IMEI).format("0,0")}</PopoverNum>
        </div>
        <div>
          <span style={{ marginRight: "3px" }}>{l("common_custom_audience_idfa", "IDFA")}</span>
          <PopoverNum>{numeral(record.IDFA).format("0,0")}</PopoverNum>
        </div>
      </div>);
        return (<Popover content={content} title={title} trigger="hover" placement="right">
        {numeral(record.audienceSize).format("0,0")}
      </Popover>);
    };
    var applyObject = __assign(__assign(__assign({ delItem: del, copyItem: function (record) { return history.push("/techHub/audience/OTVAudience/edit?id=" + record.taskId + "&isCopy=true"); } }, getActionApplyObject(STATUS_OPTIONS_TYPE.AUDIENCE_OTV_AUDIENCE, "taskId", function () { return onSearch(conditions, page); })), getAudienceApplyObject()), { audienceSize: audienceSizePopover });
    var tableProps = {
        data: data,
        columns: columns(),
        page: page,
        loading: loading,
        onChange: function (page) { return onSearch(conditions, page); },
        applyObject: applyObject
    };
    return (<TableWrapper>
      <MyTableSearch placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onSearch({ keyword: value }, merge({}, page, { current: 1 })); }} suffix={<>
          {permission.hideCreate ? null : <Tooltip title={l("function_unavailable_by_pipl", "Sorry,impact by PIPL, this function currently unavailable")} placement={"bottom"} visible={PIPLDisabledBtn ? undefined : false}>
            <Button type="primary" onClick={create} disabled={permission.disableCreate || PIPLDisabledBtn}>
              {l("common_create_custom_audience", "新建人群")}
            </Button>
          </Tooltip>}</>}/>
      <MyTable {...tableProps}/>
    </TableWrapper>);
};
var PopoverNum = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: 6px;\n"], ["\n  margin-left: 6px;\n"])));
var PopoverTitle = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 600;\n"], ["\n  font-weight: 600;\n"])));
var templateObject_1, templateObject_2;
