import * as React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Enter } from "@pages/account/enter";
import { ErrorPage } from "@pages/defaultPage/404";
import { UpdatePassword } from "@pages/account/updatePassword";
import { RouterInterceptor } from "@components/routerInterceptor/interceptor";
import { ForbiddenPage } from "@pages/defaultPage/403";
import { ForgetPassword } from "@pages/account/forgetPassword";
import { SSOPage } from "@pages/account/sso";
import { Register } from "@pages/account/register";
import { Migrate } from "@pages/defaultPage/migrate";
import { EnterOpco } from "@pages/account/enter_opco";
import { EnterIndex } from "@pages/account";
import { ForgetPasswordOpco } from "@pages/account/forgetPassword_opco";
import { UpdatePasswordOpco } from "@pages/account/updatePassword_opco";
import { PlatformDashboard } from "@pages/tv/platformDashboard/platformDashboard";
import { AsyncExportLink } from "@components/asyncExport/asyncExportLink";
import { CommonApiLink } from "@components/commonApiiLink/commonApiLink";
import { MenuSelector } from "@components/contentWrapper/menuSelector";
import { Logout } from "@pages/account/logout";
import { ExternalEnter } from "@components/commonApiiLink/externalEnter";
import { EnterAController } from "@pages/account/enter_aController";
import { EnterDemo } from "@pages/account/enter_demo";
import { DemoWelcome } from "@pages/welcome/demoWelcome";
import { Introduce } from "@pages/welcome/introduce";
import { DemoWrapper } from "@components/contentWrapper/demoWrapper";
import { NewTips } from "@/pages/account/newTips";
import { EnterDemoCaasDemo } from "@pages/account/enter_demoCaasDemo";
import { TechHubRoute } from "@router/techhubRoute";
import { EnterTimesheet } from "@pages/account/enter_timesheet";
import { WechatRedirect } from "@pages/account/wechatRedirect";
import { EnvErr } from "@pages/defaultPage/envErr";
var Routers = function () {
    window.skin = { mainColor: "#5E00B5" };
    if (location.hostname.indexOf("mplatform") > -1) {
        return <NewTips />;
    }
    return (<Router>
      <Switch>
        <Route path="/techHub" component={function () { return <RouterInterceptor>
          <MenuSelector />
        </RouterInterceptor>; }}/>
        <Route path="/page" component={function () { return <RouterInterceptor>
          <TechHubRoute prefix={"/page"}/>
        </RouterInterceptor>; }}/>
        <Route path="/caas" component={function () { return <RouterInterceptor>
          <DemoWrapper>
            <Route path="/caas/welcome/index" component={DemoWelcome}/>
            <Route path="/caas/introduce/:name" component={Introduce}/>
          </DemoWrapper>
        </RouterInterceptor>; }}/>
        <Route path="/" exact component={EnterIndex}/>
        <Route path="/reEnter" exact component={EnterIndex}/>
        <Route path="/enter" exact component={Enter}/>
        <Route path="/enter/groupm" exact component={Enter}/>
        <Route path="/logout" exact component={Logout}/>
        <Route path="/enter/acontroller" exact component={EnterAController}/>
        <Route path="/enter/acontroller_admin" exact component={EnterAController}/>
        <Route path="/enter/acontroller-admin" exact component={EnterAController}/>
        <Route path="/enter/ras" exact component={EnterTimesheet}/>
        <Route path="/enter/:opco" component={EnterOpco}/>
        <Route path="/caasLogin" exact component={EnterDemo}/>
        <Route path="/caasLogin/:opco" component={EnterDemo}/>
        <Route path="/caaslogin_caasDemo/:opco" component={EnterDemoCaasDemo}/>
        <Route path="/caaslogin_caasDemo" component={EnterDemoCaasDemo}/>
        <Route path="/ssoEnter/:key" component={SSOPage}/>
        <Route path="/register" exact component={Register}/>
        <Route path="/register/:opco" component={Register}/>
        <Route path="/updatePassword" exact component={UpdatePassword}/>
        <Route path="/updatePassword/groupm" exact component={UpdatePassword}/>
        <Route path="/updatePassword/:opco" component={UpdatePasswordOpco}/>
        <Route path="/forgetPassword" exact component={ForgetPassword}/>
        <Route path="/forgetPassword/groupm" exact component={ForgetPassword}/>
        <Route path="/forgetPassword/:opco" component={ForgetPasswordOpco}/>
        <Route path="/404" component={ErrorPage}/>
        <Route path="/403" component={ForbiddenPage}/>
        <Route path="/envErr/:code" component={EnvErr}/>
        <Route path="/migrate" component={Migrate}/>
        <Route path="/platformDashboard/overview/:uid" component={PlatformDashboard}/>
        <Route path="/platformDashboard/overviewForDetail/:uid" component={PlatformDashboard}/>
        <Route path="/asyncExport/link" component={AsyncExportLink}/>
        <Route path="/commonApi/link" component={CommonApiLink}/>
        <Route path="/externalEnter" component={ExternalEnter}/>
        <Route path="/wechatRedirect" component={WechatRedirect}/>
        <Redirect to="/404"/>
      </Switch>
    </Router>);
};
export default Routers;
