var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { post } from "@helpers/axiosHelper";
export var getDetail = function (taskId) {
    return post("/groupme/audex/audience/standardAudience_v2/getDetail", { taskId: taskId });
};
export var getSiteCustomVariableValueOptions = function (data) {
    return post("/groupme/audex/audience/standardAudience/site/getCustomVariableValueOptions", data);
};
export var getShanHaiJinOptions = function (data) {
    return post("/groupme/audex/audience/standardAudience/shanhaijin/getSHJOptions", data);
};
export var getCampaignTrackingList = function (data) {
    return post("/groupme/audex/audience/standardAudience/campaign/getTrackingList", data);
};
export var getConditionList = function (data, isTrackingAudience) {
    return post("/groupme/audex/audience/standardAudience_v2/getConditionsList", __assign(__assign({}, data), { isTrackingAudience: isTrackingAudience }));
};
export var saveApi = function (data, isTrackingAudience) {
    return post("/groupme/audex/audience/standardAudience_v2/save", __assign(__assign({}, data), { isTrackingAudience: isTrackingAudience }));
};
export var getDefaultTagOptions = function (data) {
    return post("/groupme/audex/audience/standardAudience_v2/getDefaultTagOptions", data);
};
export var getAudienceCoverageSize = function (data) {
    return post("/groupme/audex/audience/standardAudience_v2/getAudienceCoverageSize", data);
};
export var getResourceOptions = function () {
    return post("/groupme/audex/audience/standardAudience_v2/getResourceOptions");
};
export var getDataSourceDetail = function (data) {
    return post("/groupme/audex/audience/standardAudience_v2/getDataSourceDetail", data);
};
