var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { post } from "@helpers/axiosHelper";
import { ACTION_CODE_ENUM } from "@/common/const";
export var getList = function (data) {
    return post("/groupme/common/workplace/getTaskList", data);
};
export var getList2 = function (data) {
    return post("/groupme/common/workplace/getAudienceList", data);
};
export var getInit = function () {
    return post("/groupme/common/workplace/init");
};
export var likeTaskApi = function (data) {
    return post("/groupme/common/workplace/likeTaskOrNot", data);
};
export var likeAudienceApi = function (data) {
    return post("/groupme/common/workplace/likeAudienceOrNot", data);
};
export var getAudienceDetail = function (segmentId) {
    return post("/groupme/common/workplace/getAudienceDetail", { segmentId: segmentId });
};
export var deleteApi = function (data) {
    return post("/groupme/common/updateStatus", __assign(__assign({}, data), { id: data.taskId, type: data.taskType, actionCode: ACTION_CODE_ENUM.CANCEL }));
};
