import { l } from "@helpers/multiLangaugeHelper";
export var columns = function () { return [
    {
        key: "linkName",
        title: l("tracking_advertising_tracking_listener_name", "监测名称")
    },
    {
        key: "mediaName",
        title: l("tracking_advertising_tracking_media", "媒体")
    },
    {
        key: "portName",
        title: l("tracking_advertising_tracking_activation_port", "投放端口")
    },
    {
        key: "platformName",
        title: l("tracking_audience_activation_platform", "投放平台")
    },
    {
        key: "impressionCount",
        title: l("common_impression", "曝光")
    },
    {
        key: "clickCount",
        title: l("common_click", "点击")
    },
]; };
