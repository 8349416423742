var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
import { getImgUrl } from "@helpers/const";
export var NewTips = function () {
    var _a = useState("https://www.os.choreograph.cn/enter"), url = _a[0], setUrl = _a[1];
    var _b = useState(3), time = _b[0], setTime = _b[1];
    useEffect(function () {
        var env = location.hostname.split(".")[0];
        setUrl("https://" + env + ".os.choreograph.cn" + location.pathname + location.search);
    }, []);
    useEffect(function () {
        setTimeout(function () {
            setTime(time - 1);
            if (time - 1 <= 0) {
                window.location.href = url;
            }
        }, 1000);
    }, [time]);
    return (<Wrapper>
    <img style={{ height: "50%" }} src={getImgUrl("system_update.gif")}/>
    <div style={{ textAlign: "center", fontSize: "18px" }}>
      {l("system_update_tips_desc", "系统已全面更新,请通过最新域名访问")}:
      <div><a style={{ fontSize: "16px" }} href={url}>{url}</a></div>
      {time > 0 ? time : 0}s {l("system_update_tips_desc_1", "后将会自动跳转")}
    </div>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n"])));
var templateObject_1;
