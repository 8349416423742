var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getImgUrl } from "@helpers/const";
import { l } from "@helpers/multiLangaugeHelper";
import { msgError } from "@helpers/axiosHelper";
import { emailUsInfoApi } from "@pages/defaultPage/helpers/async";
export var EmailUsPage = function () {
    var _a = useState({}), emailInfo = _a[0], setEmailInfo = _a[1];
    useEffect(function () {
        emailUsInfoApi(location.pathname)
            .then(function (res) {
            setEmailInfo(res.data);
        })
            .catch(msgError);
    }, [location.pathname]);
    return <Wrapper>
    <img src={getImgUrl("emailUs.png")} alt={""}/>
    <TipsText>
      <p>{l("defaultPage_email_us_text", "Email us for")} {emailInfo.name}</p>
      <p>Email:<a href="mailto:techdata.mplatformcn@groupm.com" target="_blank">techdata.mplatformcn@groupm.com</a></p>
    </TipsText>
  </Wrapper>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  > img {\n    -webkit-user-drag: none;\n  }\n"], ["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  > img {\n    -webkit-user-drag: none;\n  }\n"])));
var TipsText = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  a {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"], ["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  a {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"])));
var templateObject_1, templateObject_2;
