import { post } from "@helpers/axiosHelper";
export var getDetail = function (taskId) {
    return post("/groupme/audex/audience/OTVAudience/getDetail", { taskId: taskId });
};
export var saveApi = function (data) {
    return post("/groupme/audex/audience/OTVAudience/save", data);
};
export var getOTVChannelOptions = function () {
    return post("/groupme/audex/audience/OTVAudience/getChannelOptions");
};
