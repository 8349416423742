var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, Tooltip } from "antd";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { useHistory, useParams } from "react-router-dom";
import { l } from "@helpers/multiLangaugeHelper";
import { getImgUrl } from "@helpers/const";
import { getOpcoTitle, updatePsd } from "@pages/account/helpers/async";
import { validateNewPassword, validateUsername } from "@pages/account/helpers/validator";
import { LockOutlined, QuestionCircleOutlined, UserOutlined } from "@/utils/antdIcon";
import queryString from "query-string";
import moment from "moment";
export var UpdatePasswordOpco = function () {
    var history = useHistory();
    var opco = useParams().opco;
    var hasUsername = queryString.parse(location.search).username;
    var _a = useState(hasUsername), username = _a[0], setUsername = _a[1];
    var _b = useState(), oldPassword = _b[0], setOldPassword = _b[1];
    var _c = useState(""), password = _c[0], setPassword = _c[1];
    var _d = useState(""), confirmPassword = _d[0], setConfirmPassword = _d[1];
    var code = queryString.parse(location.search).code;
    useEffect(function () {
        getOpcoTitle(opco)
            .then(function (res) {
            window.opcoTitle = res.data.opcoTitle || res.data.opco;
            document.title = res.data.opcoTitle || res.data.opco;
        });
    }, []);
    var submitUpdate = function () {
        var data = { username: username, oldPassword: oldPassword, password: password, code: code, opco: opco, languageCode: window.LANG };
        if (errorPasswordMsg) {
            return;
        }
        if (!(password && password !== "")
            || (!code && !(oldPassword && oldPassword !== "" && username && username !== ""))) {
            msgError(code ? l("update_password_please_input_new_password", "请输入新密码") :
                l("update_password_please_input_code_name_pwd", "请输入用户名/新老密码"));
            return;
        }
        if (!(confirmPassword && confirmPassword !== "")) {
            msgError(l("update_password_please_input_pwd", "请输入确认密码"));
            return;
        }
        if (password !== confirmPassword) {
            return;
        }
        var LOCAL_STORAGE_KEY = "techHub-login-localStorage";
        var defaultLocalStorage = {
            lastTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            times: 0,
        };
        var loginLocalStorage = localStorage.getItem("techHub-login-localStorage");
        if (loginLocalStorage && loginLocalStorage !== "") {
            loginLocalStorage = JSON.parse(loginLocalStorage);
            if (moment(loginLocalStorage.lastTime).valueOf() - moment().subtract(30, "minutes").valueOf() < 0) {
                localStorage.removeItem(LOCAL_STORAGE_KEY);
                loginLocalStorage = defaultLocalStorage;
            }
            if (loginLocalStorage && loginLocalStorage.times > 10) {
                msgError(l("update_password_err_30min", "账号密码输入错误过多，请30分钟后再试"));
                return;
            }
        }
        else {
            loginLocalStorage = defaultLocalStorage;
        }
        updatePsd(data)
            .then(function (res) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            msgSuccess(res);
            history.push(username ? "/enter/" + opco + "?username=" + username : "/enter/" + opco);
        })
            .catch(function (err) {
            loginLocalStorage.times += 1;
            loginLocalStorage.lastTime = moment().format("YYYY-MM-DD HH:mm:ss");
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(loginLocalStorage));
            loginLocalStorage.times > 10 ? msgError(l("update_password_err_30min", "账号密码输入错误过多，请30分钟后再试")) : msgError(err);
        });
    };
    var errorPasswordMsg = validateNewPassword(password, oldPassword);
    var errorUsernameMsg = validateUsername(username);
    return <Wrapper>
    <EnterWrapper>
      <EnterContentWrapper>
        <Title>{hasUsername ? l("update_password_change_password", "Change password") :
        l("update_password_reset_password", "Reset password")}</Title>
        {code ?
        <div>{l("update_password_subtitle", "We have verified your information, please fill in a new password")}</div> :
        <InputWrapper>
            <EnterInput hasError={errorUsernameMsg} placeholder={l("update_password_username_placeholder", "用户名")} disabled={hasUsername} prefix={<UserOutlined />} suffix={<Tooltip title={<>
                {l("update_password_username_tips", "用户名应该是一个邮箱地址")}
              </>}>
                <TipsIcon />
              </Tooltip>} value={username} onChange={function (v) { return setUsername(v.target.value); }}/>
            {errorUsernameMsg ? <InputError>{errorUsernameMsg}</InputError> : null}
          </InputWrapper>}
        {code ? null : <InputWrapper>
          <EnterInput type="password" placeholder={l("update_password_oldPassword_placeholder", "老密码")} prefix={<LockOutlined />} onChange={function (v) { return setOldPassword(v.target.value); }}/>
        </InputWrapper>}
        <InputWrapper>
          <EnterInput hasError={errorPasswordMsg} type="password" placeholder={l("update_password_newPassword_placeholder", "新密码")} prefix={<LockOutlined />} onChange={function (v) { return setPassword(v.target.value); }} suffix={<Tooltip title={<div>
              {l("update_password_password_tips", "The password must be no less than eight characters and must contain uppercase and lowercase as well as special characters")}
            </div>}>
              <TipsIcon />
            </Tooltip>}/>
          {errorPasswordMsg ? <InputError>{errorPasswordMsg}</InputError> : null}
        </InputWrapper>
        <InputWrapper>
          <EnterInput hasError={password && confirmPassword && password !== confirmPassword} type="password" placeholder={l("update_password_confirm_Password_placeholder", "确认密码")} prefix={<LockOutlined />} onChange={function (v) { return setConfirmPassword(v.target.value); }}/>
          {password && confirmPassword && password !== confirmPassword ?
        <InputError>{l("update_password_same_password", "密码不一致")}</InputError> : null}
        </InputWrapper>
        <EnterBtn type="primary" onClick={submitUpdate}>{l("common_confirm", "确认")}</EnterBtn>
      </EnterContentWrapper>
    </EnterWrapper>
  </Wrapper>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  background: url(", ") no-repeat center;\n  background-size: cover;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  background: url(", ") no-repeat center;\n  background-size: cover;\n"])), getImgUrl("enterOpco_bg.png"));
var EnterWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n"], ["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n"])));
var EnterContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 180px;\n  }\n"], ["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 180px;\n  }\n"])));
var EnterInput = styled(Input)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100% !important;\n  height: 42px !important;\n  border-color:  ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"], ["\n  width: 100% !important;\n  height: 42px !important;\n  border-color:  ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"])), function (props) { return props.hasError ? "#f20 !important" : "#D2D2D2 !important"; });
var InputWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  margin-top: 26px;\n  text-align: center;\n  font-size: 22px;\n"], ["\n  position: relative;\n  margin-top: 26px;\n  text-align: center;\n  font-size: 22px;\n"])));
var InputError = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color:#f20;\n"], ["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color:#f20;\n"])));
var EnterBtn = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 28px;\n  display: block;\n  color: #fff;\n  width: 100% !important;\n  height: 48px;\n  background: #1890FF;\n  font-size: 16px;\n"], ["\n  margin-top: 28px;\n  display: block;\n  color: #fff;\n  width: 100% !important;\n  height: 48px;\n  background: #1890FF;\n  font-size: 16px;\n"])));
var Title = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  font-size: 26px;\n  text-align: center;\n"], ["\n  margin-bottom: 10px;\n  font-size: 26px;\n  text-align: center;\n"])));
var TipsIcon = styled(QuestionCircleOutlined)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #aaa;\n"], ["\n  color: #aaa;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
