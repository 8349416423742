import * as React from "react";
import * as ReactDOM from "react-dom";
import AppRouter from "@router/routers";
import { Provider } from "mobx-react";
import { stores } from "@/store/store";
import { getLanguageMap } from "@helpers/async";
import { languageKeyList } from "./multiLanguageKeyList";
import { LANGUAGE_CODE, register } from "@helpers/multiLangaugeHelper";
import "./style.css";
var languageCode;
var sessionLanguageCode = localStorage.getItem("languageCode");
languageCode = sessionLanguageCode ? sessionLanguageCode : (navigator && navigator.language.toLowerCase().indexOf("cn") > -1 ? LANGUAGE_CODE.CN : LANGUAGE_CODE.EN);
localStorage.setItem("languageCode", languageCode);
var hostOpco;
var env;
var envList = ["dev", "itg", "uat", "pre", "demo", "www"];
hostOpco = location.host.split(".")[0];
if (envList.includes(hostOpco)) {
    env = hostOpco;
    hostOpco = null;
}
else {
    if (hostOpco.indexOf("-") > -1) {
        var opcoSplitList = hostOpco.split("-");
        if (envList.includes(opcoSplitList[opcoSplitList.length - 1])) {
            env = opcoSplitList[opcoSplitList.length - 1];
            opcoSplitList.length = opcoSplitList.length - 1;
            hostOpco = "";
            opcoSplitList.forEach(function (item, idx) {
                hostOpco += (idx === 0 ? "" : "-") + item;
            });
        }
    }
}
window.env = env || "www";
window.opco = hostOpco;
getLanguageMap(languageKeyList, languageCode)
    .then(function (res) {
    register(res.data.languageValueMap, res.data.languageCode, function () {
        ReactDOM.render(<Provider {...stores}>
          <AppRouter />
        </Provider>, document.getElementById("app"));
    });
})
    .catch(function () {
    register({}, LANGUAGE_CODE.CN, function () {
        ReactDOM.render(<Provider {...stores}>
          <AppRouter />
        </Provider>, document.getElementById("app"));
    });
});
