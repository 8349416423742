import { l } from "@/helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
export var columns = function () { return [
    {
        key: "appName",
        title: l("audience_app_audience_app_name", "APP名")
    },
    {
        key: "audienceSize",
        title: l("common_audience_size", "人群规模"),
        render: [
            {
                key: "audienceSize",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                    format: "0,0"
                }
            }
        ]
    },
    {
        key: "category",
        title: l("audience_app_audience_category", "所属分类")
    },
    {
        key: "pkgName",
        title: l("audience_app_audience_app_pkg", "包名")
    },
]; };
