import { map } from "lodash";
export var IS_LOCAL = location.host === "localhost:4000";
export var IMAGE_BASE_URL = "https://dev.os.choreograph.cn/objects/groupm/img/images";
export var STATIC_BASE_URL = "https://dev.os.choreograph.cn/objects/groupm/static";
export var SOCIAL_BASE_URL = "https://itg.os.choreograph.cn/api/social";
export var MOCKER_SERVER_DATA_URL = "https://dev.os.choreograph.cn/api/groupme/osUtility/mock";
export var MOCKER_SERVER_URL = "https://www.os.choreograph.cn/apiPlatform";
export var RELATIVE_IMAGE_BASE_URL = "/objects/groupm/img/images";
export var ENV_URL = {
    development: "/api",
    production: "/api",
    mock: MOCKER_SERVER_DATA_URL,
};
export var LOCAL_STORAGE_KEY = "techHub-login-localStorage";
export var LOCAL_STORAGE_OPCO_KEY = "techHub-login-opco";
export var getImgUrl = function (url) {
    return IMAGE_BASE_URL + "/" + url;
};
export var getRelativeImgUrl = function (url) {
    return RELATIVE_IMAGE_BASE_URL + "/" + url;
};
export var getStaticUrl = function (url) {
    return STATIC_BASE_URL + "/" + url;
};
export var getItemLayout = function (labelCol, wrapperCol) {
    if (labelCol === void 0) { labelCol = 6; }
    if (wrapperCol === void 0) { wrapperCol = 12; }
    return {
        labelCol: { span: labelCol },
        wrapperCol: { span: wrapperCol }
    };
};
export var filterOption = function (inputValue, item) {
    if (!inputValue || inputValue === "")
        return true;
    if (inputValue.indexOf(" ") > -1) {
        var valueList = inputValue.split(" ");
        var result_1 = true;
        map(valueList, function (value) {
            if (item.label.toLowerCase().indexOf(value.toLowerCase()) <= -1) {
                result_1 = false;
            }
        });
        return result_1;
    }
    else {
        return item.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    }
};
export var initTextList = function (list) {
    var result = "";
    if (list && list.length > 0) {
        map(list, function (item, idx) {
            result += idx !== 0 ? "\n" + item : item;
        });
    }
    return result;
};
