var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { getImgUrl } from "@helpers/const";
export var Updating = function (_a) {
    var _b = _a.data, data = _b === void 0 ? {} : _b;
    return (<Wrapper>
    <img alt={"系统维护中"} src={getImgUrl("updating.png")}/>
    <p>{data.enDesc}</p>
    <p>{data.cnDesc}</p>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n  width: 100%;\n  >img {\n    display: block;\n    margin-bottom: 60px;\n    width: 500px;\n  }\n  >p {\n    margin-bottom: 10px;\n    text-align: center;\n    color: #555;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n  width: 100%;\n  >img {\n    display: block;\n    margin-bottom: 60px;\n    width: 500px;\n  }\n  >p {\n    margin-bottom: 10px;\n    text-align: center;\n    color: #555;\n  }\n"])));
var templateObject_1;
