var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { getImgUrl } from "@helpers/const";
import { l } from "@helpers/multiLangaugeHelper";
export var ForbiddenPage = function () {
    return <Wrapper>
    <img src={getImgUrl("403.png")} alt={""}/>
    <TipsText>
      <p>{l("common_403_text", "您目前没有权限，请联系管理员获取权限。")}</p>
      <p>Email:<a href="mailto:techdata.mplatformcn@groupm.com" target="_blank">techdata.mplatformcn@groupm.com</a></p>
    </TipsText>
  </Wrapper>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  > img {\n    -webkit-user-drag: none;\n  }\n"], ["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  > img {\n    -webkit-user-drag: none;\n  }\n"])));
var TipsText = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  a {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"], ["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  a {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"])));
var templateObject_1, templateObject_2;
