import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
export var columns = function () { return [
    {
        key: "advertiser",
        title: l("common_advertiser", "广告主")
    },
    {
        key: "lastUpdate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
            },
        ]
    },
    {
        key: "operation",
        title: l("common_operation", "action"),
        render: [
            {
                key: "viewItem",
                text: l("common_detail", "详情"),
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                options: {
                    path: "/techHub/audience/audienceTranslate/detail",
                    params: {
                        id: "taskId"
                    }
                },
            }
        ]
    }
]; };
export var list = [
    {
        taskId: 1,
        advertiser: "Demo1",
        lastUpdateName: "techdata.mplatformcn@groupm.com",
        lastUpdateDate: "2022-12-12",
    },
    {
        taskId: 2,
        advertiser: "Demo2",
        lastUpdateName: "techdata.mplatformcn@groupm.com",
        lastUpdateDate: "2022-12-02",
    },
    {
        taskId: 3,
        advertiser: "Demo3",
        lastUpdateName: "techdata.mplatformcn@groupm.com",
        lastUpdateDate: "2022-11-23",
    }
];
export var audienceData = [
    {
        audience: "Fitness",
        shj: {
            desc: "(25-29 ~ Age range OR 40-44 ~ Age range) and ( Do exercises ~ Physical fitness activities participated in P1M OR  Football ~ Physical fitness activities participated in P1M OR  Run fast ~ Physical fitness activities participated in P1M)",
            tags: [
                "Age 25-29",
                "Age 40-44",
                "Do exercises ~ Physical fitness activities participated in P1M ",
                "Football ~ Physical fitness activities participated in P1M",
                "Run fast ~ Physical fitness activities participated in P1M"
            ]
        },
        td: {
            tags: [
                "Age range - 25-29",
                "Age range - 40-44",
                "Frequently Visited Place \\Sports Area",
                "POI\\Leisure\\Football Field",
                "POI\\Leisure\\Outdoor Fitness Area",
                "POI\\Leisure\\Gymnasium"
            ]
        },
        options: ["Apps Interest\\Health care-Execrise and Fitness", "Purchase Preference\\Sports and Health"]
    },
    {
        audience: "Highend Jewelery",
        shj: {
            desc: "(25-29 ~ Age range OR 30-34 ~ Age range OR 35-39 ~ Age range OR 40-44 ~ Age range)  and （First-tier cities ~ Tier2）and (15,000-29,999 yuan ~ High-end watch-Price ranges purchased in P3Y OR  15,000-29,999 yuan ~ High-end watch-Price ranges purchased in P3Y)",
            tags: [
                "Age 25-44",
                "First-tier cities",
                "15,000-29,999 yuan ~ High-end watch-Price ranges purchased in P3Y ",
            ]
        },
        td: {
            tags: [
                "Age range - 25-29",
                "Age range - 30-34",
                "Age range - 35-39",
                "Age range - 40-44",
                "Cities\\ Shanghai",
                "Cities\\ Beijing",
                "Cities\\ Guangzhou",
                "Purchase Preference\\Watch and Jewelery",
                "Purchase Preference\\Consumer Orientation - Luxury Brand",
            ]
        },
        options: ["Frequently Visited Place \\Sports Area\\Golf Driving Range",
            "Frequently Visited Place \\POI\\Vacation Sanatorium",
            "Frequently Visited Place \\Hotel\\Five star hotels",
            "Purchase Preference\\Luxury Clothing",
            "Purchase Preference\\Craft Collection"]
    },
    { audience: "Pet Lovers" },
    { audience: "Beauty" },
    { audience: "Baby" },
];
