export var LANGUAGE_CODE = {
    CN: "cn",
    EN: "en"
};
export function register(languageKeyMap, languageCode, cb) {
    window.LANGUAGE_MAP = languageKeyMap || {};
    window.LANG = languageCode || LANGUAGE_CODE.CN;
    if (cb) {
        cb();
    }
}
export function l(key, defaultValue) {
    if (!window.LANGUAGE_MAP) {
        return defaultValue;
    }
    return window.LANGUAGE_MAP[key] || defaultValue;
}
