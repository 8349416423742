import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ProfilingManage } from "@pages/profiling/profile/manage/manage";
import { ProfilingEdit } from "@pages/profiling/profile/edit/edit";
import { ProfilingView } from "@pages/profiling/profile/edit/view";
import { ProfilingDashboard } from "@pages/profiling/profile/manage/dashboard";
import { MProfilingAdminManage } from "@pages/profiling/adminManage/manage";
import { StandardAudienceManage } from "@pages/customAudience/standardAudience/manage/manage";
import { StandardAudienceEdit } from "@pages/customAudience/standardAudience/edit/edit";
import { StandardAudienceView } from "@pages/customAudience/standardAudience/edit/view";
import { OTVAudienceManage } from "@pages/customAudience/otvAudience/manage/manage";
import { OTVAudienceEdit } from "@pages/customAudience/otvAudience/edit/edit";
import { OTVAudienceView } from "@pages/customAudience/otvAudience/edit/view";
import { LocationAudienceManage } from "@pages/customAudience/locationAudience/manage/manage";
import { LocationAudienceEdit } from "@pages/customAudience/locationAudience/edit/edit";
import { LocationAudienceView } from "@pages/customAudience/locationAudience/edit/view";
import { Manage } from "@pages/customAudience/customUpload/manage";
import { Edit } from "@pages/customAudience/customUpload/edit";
import { DmpSourceAudienceManage } from "@pages/customAudience/dmpSourceAudience/manage";
import { DmpSourceAudienceEdit } from "@pages/customAudience/dmpSourceAudience/edit";
import { WorkPlaceAudienceDetail } from "@pages/workplace/audienceDetail";
import { MainDashboard } from "@pages/workplace";
import { DoohPlanningDetail } from "@pages/doohPlaninng/detail";
import { DoohPlanningManage } from "@pages/doohPlaninng/manage";
import { DoohPlanningEdit } from "@pages/doohPlaninng/edit";
import { DoohPlanningView } from "@pages/doohPlaninng/view";
import { CreativeManage } from "@pages/creativeInsight/manage";
import { CreativeDetail } from "@pages/creativeInsight/detail";
import { BuildingPage } from "@pages/defaultPage/building";
import { OutLinkPage } from "@pages/defaultPage/outLink";
import { EmailUsPage } from "@pages/defaultPage/emailUs";
import { ForbiddenPage } from "@pages/defaultPage/403";
import { ModuleSplit } from "@pages/moduleSplit/moduleSplit";
import { AppAudienceManage } from "@pages/customAudience/appAudience/manage/manage";
import { AppAudienceEdit } from "@pages/customAudience/appAudience/edit/edit";
import { AppAudienceView } from "@pages/customAudience/appAudience/edit/view";
import { AudienceTranslateManage } from "@pages/customAudience/audienceTranslate/manage";
import { AudienceTranslateEdit } from "@pages/customAudience/audienceTranslate/edit";
import { AudienceExplorerManage } from "@pages/customAudience/audienceExplorer/manage";
import { AudienceTranslateDetail } from "@pages/customAudience/audienceTranslate/detail";
import { CaasPage } from "@pages/welcome/caasPage";
import { Introduce } from "@pages/welcome/introduce";
import { TrackingAudienceManage } from "@pages/customAudience/trackingAudience/manage/manage";
import { TrackingAudienceEdit } from "@pages/customAudience/trackingAudience/edit/edit";
import { TranslatorAudienceManage } from "@pages/customAudience/translatorAudience/manage/manage";
import { TrackingAudienceEditAudience } from "@pages/customAudience/trackingAudience/editAudience/editAudience";
export var TechHubRoute = function (_a) {
    var _b = _a.prefix, prefix = _b === void 0 ? "/techHub" : _b;
    return (<Switch>
    <Route path={prefix + "/profiling/:profileType/manage"} component={ProfilingManage}/>
    <Route path={prefix + "/profiling/:profileType/edit"} component={ProfilingEdit}/>
    <Route path={prefix + "/profiling/:profileType/view"} component={ProfilingView}/>
    <Route path={prefix + "/profiling/:profileType/dashboard/:type"} component={ProfilingDashboard}/>
    <Route path={prefix + "/profiling/mprofile/adminManage"} component={MProfilingAdminManage}/>
    <Route path={prefix + "/audience/standardAudience/manage"} component={StandardAudienceManage}/>
    <Route path={prefix + "/audience/standardAudience/edit"} component={StandardAudienceEdit}/>
    <Route path={prefix + "/audience/standardAudience/view"} component={StandardAudienceView}/>
    <Route path={prefix + "/audience/OTVAudience/manage"} component={OTVAudienceManage}/>
    <Route path={prefix + "/audience/OTVAudience/edit"} component={OTVAudienceEdit}/>
    <Route path={prefix + "/audience/OTVAudience/view"} component={OTVAudienceView}/>
    <Route path={prefix + "/audience/locationAudience/manage"} component={LocationAudienceManage}/>
    <Route path={prefix + "/audience/locationAudience/edit"} component={LocationAudienceEdit}/>
    <Route path={prefix + "/audience/locationAudience/view"} component={LocationAudienceView}/>
    <Route path={prefix + "/audience/translatorAudience/manage"} component={TranslatorAudienceManage}/>
    <Route path={prefix + "/audience/translatorAudience/edit"} component={StandardAudienceEdit}/>
    <Route path={prefix + "/audience/translatorAudience/view"} component={StandardAudienceView}/>
    <Route path={prefix + "/audience/customUpload/upload"} component={Manage}/>
    <Route path={prefix + "/audience/customUpload/manage"} component={Manage}/>
    <Route path={prefix + "/audience/customUpload/submit"} component={Edit}/>
    <Route path={prefix + "/audience/dmpSourceAudience/manage"} component={DmpSourceAudienceManage}/>
    <Route path={prefix + "/audience/dmpSourceAudience/edit"} component={DmpSourceAudienceEdit}/>
    <Route path={prefix + "/audience/appAudience/manage"} component={AppAudienceManage}/>
    <Route path={prefix + "/audience/appAudience/edit"} component={AppAudienceEdit}/>
    <Route path={prefix + "/audience/appAudience/view"} component={AppAudienceView}/>
    <Route path={prefix + "/audience/trackingAudience/manage"} component={TrackingAudienceManage}/>
    <Route path={prefix + "/audience/trackingAudience/edit"} component={TrackingAudienceEdit}/>
    <Route path={prefix + "/audience/trackingAudience/editAudience"} component={TrackingAudienceEditAudience}/>
    <Route path={prefix + "/audience/audienceTranslate/manage"} component={AudienceTranslateManage}/>
    <Route path={prefix + "/audience/audienceTranslate/create"} component={AudienceTranslateEdit}/>
    <Route path={prefix + "/audience/audienceTranslate/detail"} component={AudienceTranslateDetail}/>
    <Route path={prefix + "/audience/audienceExplorer/manage"} component={AudienceExplorerManage}/>
    <Route path={prefix + "/workplace/audienceDetail"} component={WorkPlaceAudienceDetail}/>
    <Route path={prefix + "/workplace/index"} component={MainDashboard}/>
    <Route path={prefix + "/doohplanning/detail"} component={DoohPlanningDetail}/>
    <Route path={prefix + "/doohplanning/manage"} component={DoohPlanningManage}/>
    <Route path={prefix + "/doohplanning/edit"} component={DoohPlanningEdit}/>
    <Route path={prefix + "/doohplanning/view"} component={DoohPlanningView}/>
    <Route path={prefix + "/creative/manage"} component={CreativeManage}/>
    <Route path={prefix + "/creative/detail"} component={CreativeDetail}/>
    <Route path={prefix + "/building"} component={BuildingPage}/>
    <Route path={prefix + "/building/:type"} component={BuildingPage}/>
    <Route path={prefix + "/outLink"} component={OutLinkPage}/>
    <Route path={prefix + "/outLink/:type"} component={OutLinkPage}/>
    <Route path={prefix + "/emailUs"} component={EmailUsPage}/>
    <Route path={prefix + "/emailUs/:type"} component={EmailUsPage}/>
    <Route path={prefix + "/403"} component={ForbiddenPage}/>
    <Route path={prefix + "/caas/page/1"} component={CaasPage}/>
    <Route path={prefix + "/introduce/:name"} component={Introduce}/>
    <Route path={prefix + "/admin/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/copilot/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/a-controller/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/smartPlanning/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/webos/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/insights/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/custom/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/report/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/audex/:type"} component={ModuleSplit}/>
    <Route path={prefix + "/timesheet/:type"} component={ModuleSplit}/>
    <Redirect to="/404"/>
  </Switch>);
};
