import { l } from "@helpers/multiLangaugeHelper";
import { MAIN_COLOR, WARING_COLOR } from "@/common/styled";
export var CONDITION_ENUM;
(function (CONDITION_ENUM) {
    CONDITION_ENUM["FREQUENT_PLACED"] = "location";
    CONDITION_ENUM["LOCATION"] = "residence";
    CONDITION_ENUM["OTV"] = "otvBehavior";
    CONDITION_ENUM["TRAVEL"] = "travelBehavior";
    CONDITION_ENUM["MOBILE_APP"] = "appPreference";
    CONDITION_ENUM["BROWSER"] = "browserBehavior";
    CONDITION_ENUM["SHAN_HAI_JIN"] = "shj";
    CONDITION_ENUM["CUSTOM_AUDIENCE"] = "customAudience";
    CONDITION_ENUM["SELF_AUDIENCE"] = "advertiserSelfSegment";
    CONDITION_ENUM["CAMPAIGN"] = "campaign";
    CONDITION_ENUM["SITE"] = "site";
    CONDITION_ENUM["INDUSTRY_AUDIENCE"] = "industryAudience";
    CONDITION_ENUM["RECOMMEND_AUDIENCE"] = "recommendAudience";
    CONDITION_ENUM["DEFAULT"] = "default";
})(CONDITION_ENUM || (CONDITION_ENUM = {}));
export var getConditionTypeList = function () {
    var _a;
    var result = (_a = {},
        _a[CONDITION_ENUM.FREQUENT_PLACED] = {
            text: l("common_arrive_site", "常去场所"),
            icon: "icon-ca_place",
            color: MAIN_COLOR
        },
        _a[CONDITION_ENUM.LOCATION] = {
            text: l("common_location", "常住地"),
            icon: "icon-ca_location",
            color: MAIN_COLOR
        },
        _a[CONDITION_ENUM.OTV] = {
            text: l("common_otv_behavior", "观影偏好"),
            icon: "icon-ca_otv",
            color: MAIN_COLOR
        },
        _a[CONDITION_ENUM.TRAVEL] = {
            text: l("common_travel_behavior", "商旅偏好"),
            icon: "icon-ca_travel",
            color: MAIN_COLOR
        },
        _a[CONDITION_ENUM.MOBILE_APP] = {
            text: l("common_mobile_app_behavior", "APP使用偏好"),
            icon: "icon-ca_app",
            color: MAIN_COLOR
        },
        _a[CONDITION_ENUM.BROWSER] = {
            text: l("common_browser_behavior", "网上浏览行为"),
            icon: "icon-ca_net",
            color: MAIN_COLOR
        },
        _a[CONDITION_ENUM.SHAN_HAI_JIN] = {
            text: l("common_shan_hai_ji", "山海今"),
            // icon: "icon-ca_shanhaijin",
            img: "shj_ca.png",
            color: "#a5dd77"
        },
        _a[CONDITION_ENUM.CUSTOM_AUDIENCE] = {
            text: l("custom_audience_edit_has_audience", "已有人群"),
            icon: "icon-ca_custom_audience",
            color: "#9788fc"
        },
        _a[CONDITION_ENUM.SELF_AUDIENCE] = {
            text: l("custom_audience_edit_self_custom_audience", "客户自有人群"),
            icon: "icon-ca_self_audience",
            color: WARING_COLOR
        },
        _a[CONDITION_ENUM.CAMPAIGN] = {
            text: l("custom_audience_launch_monitoring_data", "客户投放监测数据"),
            icon: "icon-ca_campaign",
            color: WARING_COLOR
        },
        _a[CONDITION_ENUM.SITE] = {
            text: l("custom_audience_browser_watch_data", "客户官网监测数据"),
            icon: "icon-ca_site",
            color: WARING_COLOR
        },
        _a[CONDITION_ENUM.INDUSTRY_AUDIENCE] = {
            text: l("custom_audience_edit_industry_audience", "行业人群"),
            icon: "icon-hangyetuijianrenqun1",
            color: MAIN_COLOR
        },
        _a[CONDITION_ENUM.RECOMMEND_AUDIENCE] = {
            text: l("custom_audience_edit_recommend_audience", "推荐人群"),
            icon: "icon-hangyetuijianrenqun1",
            color: MAIN_COLOR
        },
        _a);
    return result;
};
export var CALC_TYPE;
(function (CALC_TYPE) {
    CALC_TYPE["AND"] = "and";
    CALC_TYPE["EXCLUDE"] = "exclude";
    CALC_TYPE["OR"] = "or";
})(CALC_TYPE || (CALC_TYPE = {}));
export var getCalcText = function () {
    var _a;
    return _a = {},
        _a[CALC_TYPE.AND] = l("custom_audience_calc_and", "且"),
        _a[CALC_TYPE.EXCLUDE] = l("custom_audience_calc_exclude", "排除"),
        _a[CALC_TYPE.OR] = l("custom_audience_calc_or", "并"),
        _a;
};
export var campaignColumns = function () { return [
    {
        key: "listenerId",
        title: l("tracking_advertising_tracking_listenerId", "监测编号")
    },
    {
        key: "mediaProduct",
        title: l("tracking_advertising_tracking_media_product", "媒体产品")
    },
    {
        key: "media",
        title: l("tracking_advertising_tracking_media", "媒体")
    },
    {
        key: "customString",
        title: l("tracking_advertising_tracking_custom_string", "自定义字段")
    },
    {
        key: "city",
        title: l("common_city", "城市")
    },
    {
        key: "creativeName",
        title: l("tracking_advertising_tracking_creative_name", "创意名称")
    },
]; };
