var _a;
import { l } from "@helpers/multiLangaugeHelper";
export var PROFILE_TYPE;
(function (PROFILE_TYPE) {
    PROFILE_TYPE["M_PROFILE"] = "mprofile";
    PROFILE_TYPE["MH_PROFILE"] = "mhprofile";
    PROFILE_TYPE["S_PROFILE"] = "sprofile";
})(PROFILE_TYPE || (PROFILE_TYPE = {}));
export var DEFAULT_PORTRAIT_VALUE = (_a = {},
    _a[PROFILE_TYPE.M_PROFILE] = 1100,
    _a[PROFILE_TYPE.MH_PROFILE] = 100000,
    _a);
export var PROFILE_OBJ = function () {
    var _a;
    return _a = {},
        _a[PROFILE_TYPE.M_PROFILE] = {
            title: l("profiling_profile_title_m", "[m]profile"),
            desc: l("profiling_profile_title_text_m", "Holistic household consumer insights for Custom Audiences (Powered by Kuyun)"),
            breadListText: l("menu_m_profile", "[m]profile List"),
            tabPaneText: l("profiling_profile_segment_list", "Segment List"),
            edit_title: l("profiling_profile_m_add", "新建人群画像"),
        },
        _a[PROFILE_TYPE.MH_PROFILE] = {
            title: l("profiling_profile_title_mh", "[mh]profile"),
            desc: l("profiling_profile_title_text_mh", "Holistic household consumer insights for Custom Audiences (Powered by Kuyun)"),
            breadListText: l("menu_mh_profile", "[mh]profile List"),
            tabPaneText: l("profiling_mh_profile_segment_list", "Segment List"),
            edit_title: l("profiling_profile_mh_add", "新建家庭画像"),
        },
        _a[PROFILE_TYPE.S_PROFILE] = {
            title: l("profiling_profile_title_s", "[s]profile"),
            desc: l("profiling_profile_title_text_s", "Holistic household consumer insights for Custom Audiences (Powered by Kuyun)"),
            breadListText: l("menu_s_profile", "[s]profile List"),
            tabPaneText: l("profiling_s_profile_segment_list", "Segment List"),
            edit_title: l("profiling_profile_s_add", "新建社交画像"),
        },
        _a;
};
