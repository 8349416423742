var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect } from "react";
import { sessionCheck } from "@pages/account/helpers/async";
import { useHistory } from "react-router-dom";
import { MySpin } from "@components/baseComponents/mySpin";
import styled from "styled-components";
import { LOCAL_STORAGE_OPCO_KEY } from "@helpers/const";
import queryString from "query-string";
import { getRedirectUrlParams } from "@/utils/unit";
export var EnterIndex = function () {
    var history = useHistory();
    var urlOpco = queryString.parse(location.search).opco;
    useEffect(function () {
        var opco = window.opco || urlOpco || localStorage.getItem(LOCAL_STORAGE_OPCO_KEY);
        var directPath = queryString.parse(location.search).directPath;
        sessionCheck({ opco: opco })
            .then(function (res) {
            location.href = res.data.url ? res.data.url : "/techHub/workplace/index";
        })
            .catch(function () {
            var url = opco && opco !== "undefined" && opco !== "groupm" ? "/enter/" + opco : "/enter";
            if (opco === "caas") {
                url = "/caasLogin";
            }
            if (directPath) {
                url = url + getRedirectUrlParams();
            }
            history.push(url);
        });
    }, []);
    return (<MySpin spinning={true}>
    <Block />
  </MySpin>);
};
var Block = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n"], ["\n  height: 100vh;\n"])));
var templateObject_1;
