var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getImgUrl } from "@helpers/const";
import { l } from "@helpers/multiLangaugeHelper";
import { useParams } from "react-router-dom";
import { getIntroduce } from "@pages/welcome/helpers/async";
import { msgError } from "@helpers/axiosHelper";
import { Divider } from "antd";
export var Introduce = function () {
    var _a;
    var ico = document.getElementById("ico");
    var icoUrl = "/objects/groupm/img/favicon/groupm.ico";
    ico.href = icoUrl;
    var skin = window.skin;
    var _b = useState({}), data = _b[0], setData = _b[1];
    var name = useParams().name;
    useEffect(function () {
        getIntroduce(name)
            .then(function (res) {
            setData(res.data);
            res.data.link.map(function (item) {
                var urlLastIdx = item.url.lastIndexOf("/");
                var urlKey = item.url.substring(0, urlLastIdx);
                sessionStorage.setItem("opco-link-" + urlKey, item.opco);
            });
        })
            .catch(msgError);
    }, [name]);
    var jumpLink = function (item) {
        window.open(item.url);
    };
    return (<Wrapper>
    {data ? <Content>
      <Title>{data.logo ? <img src={getImgUrl(data.logo)}/> : data.title}</Title>
      <ContentWrapper>
        <Txt dangerouslySetInnerHTML={{ __html: data.desc }}/>
        <div>{data.img ? <img alt="" src={getImgUrl(data.img)}/> : null}</div>
      </ContentWrapper>
    </Content> : null}
    {window.user && window.user.opco !== "groupm" && window.user.opco.indexOf("caas") === -1 ? null :
        <Divider style={{ margin: 0 }}/>}
    {window.user && window.user.opco !== "groupm" && window.user.opco.indexOf("caas") === -1 ? null : <LinkWrapper>
      {((_a = data === null || data === void 0 ? void 0 : data.link) === null || _a === void 0 ? void 0 : _a.length) > 0 ? <p>{l("introduce_click_to_link", "Click to experience the product")}</p> : null}
      {data && data.link && data.link.length > 0 ? data.link.map(function (item) { return item.hide ? null : (<Btn color={skin.mainColor} onClick={function () { return jumpLink(item); }}>
          {item.name}
        </Btn>); }) : null}
    </LinkWrapper>}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 26px;\n  position: relative;\n  padding: 36px;\n  background: #fff;\n"], ["\n  margin: 26px;\n  position: relative;\n  padding: 36px;\n  background: #fff;\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 32px;\n"], ["\n  padding: 32px;\n"])));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 18px;\n  font-size: 32px;\n  min-height: 48px;\n  > img {\n    height: 24px;\n  }\n"], ["\n  margin-bottom: 18px;\n  font-size: 32px;\n  min-height: 48px;\n  > img {\n    height: 24px;\n  }\n"])));
var ContentWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  >div {\n    width: 50%;\n    >img {\n      width: 100%;\n      min-height: 316px;\n    }\n  }\n  >div:nth-child(1){\n    margin-right: 100px;\n  }\n"], ["\n  display: flex;\n  >div {\n    width: 50%;\n    >img {\n      width: 100%;\n      min-height: 316px;\n    }\n  }\n  >div:nth-child(1){\n    margin-right: 100px;\n  }\n"])));
var Txt = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #31373D;\n  font-size: 16px;\n"], ["\n  color: #31373D;\n  font-size: 16px;\n"])));
var LinkWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 24px 60px;\n  > p {\n    font-size: 16px;\n    color: #31373D;\n  }\n"], ["\n  padding: 24px 60px;\n  > p {\n    font-size: 16px;\n    color: #31373D;\n  }\n"])));
var Btn = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0 12px 12px 0;\n  line-height: 1.5715;\n  position: relative;\n  display: inline-block;\n  white-space: nowrap;\n  text-align: center;\n  background: #fff;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  touch-action: manipulation;\n  height: 32px;\n  padding: 4px 15px;\n  color: ", ";\n  border:1px solid ", ";\n  border-radius: 2px;\n  &:hover {\n    background: ", ";\n    color: #fff;\n  }\n"], ["\n  margin: 0 12px 12px 0;\n  line-height: 1.5715;\n  position: relative;\n  display: inline-block;\n  white-space: nowrap;\n  text-align: center;\n  background: #fff;\n  cursor: pointer;\n  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  touch-action: manipulation;\n  height: 32px;\n  padding: 4px 15px;\n  color: ", ";\n  border:1px solid ", ";\n  border-radius: 2px;\n  &:hover {\n    background: ", ";\n    color: #fff;\n  }\n"])), function (props) { return props.color; }, function (props) { return props.color; }, function (props) { return props.color; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
