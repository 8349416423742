var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { acontrollerLogin, getOpcoTitle, sessionCheck } from "@pages/account/helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { l } from "@helpers/multiLangaugeHelper";
import { getImgUrl, LOCAL_STORAGE_OPCO_KEY } from "@helpers/const";
import md5 from "md5";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@helpers/msalConfig";
import { MySpin } from "@components/baseComponents/mySpin";
import { getRedirectUrlParams } from "@/utils/unit";
export var EnterAController = function () {
    var $msalInstance = new PublicClientApplication(msalConfig);
    var history = useHistory();
    var opco = location.pathname.indexOf("acontroller_admin") > -1 || location.pathname.indexOf("acontroller-admin") > -1 ? "acontroller_admin" : "acontroller";
    var directPath = queryString.parse(location.search).directPath;
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    useEffect(function () {
        window.opcoTitle = "acontroller";
        document.title = "acontroller";
        setLoading(false);
        getOpcoTitle(opco)
            .then(function (res) {
            window.opcoTitle = res.data.opcoTitle || res.data.opco;
            document.title = res.data.opcoTitle || res.data.opco;
            if (res.data.icoUrl) {
                var ico = document.getElementById("ico");
                var icoUrl = "/objects/groupm/img/favicon/" + res.data.icoUrl + ".ico";
                ico.href = icoUrl;
            }
        });
        sessionCheck({ opco: opco })
            .then(function (res) {
            location.href = res.data.url ? res.data.url : "/techHub/workplace/index";
        });
    }, []);
    var replaceStr = function (str, index, char) {
        var nowTime = moment().format("YYYY-MM-DD");
        var strAry = str.split("");
        strAry[index] = char;
        var resloveString = strAry.join("");
        return resloveString + "_" + nowTime;
    };
    var ssoLogin = function () {
        $msalInstance
            .loginPopup({ scopes: [] })
            .then(function (res) {
            var index = moment().get("isoWeekday") - 1;
            var account = res.account.username.toLowerCase();
            var charCodeAscii = account.charCodeAt(index);
            var transformUpperCode = /[a-z0-9]/.test(account[index]) ? String.fromCharCode(charCodeAscii - 32) : account[index];
            var result = replaceStr(account, index, transformUpperCode);
            var data = { username: account, code: md5(result), opco: opco, aad: res };
            acontrollerLogin(data).then(function (res) {
                msgSuccess(res);
                localStorage.setItem(LOCAL_STORAGE_OPCO_KEY, opco);
                var url = res.data.url || "/techHub/workplace/index";
                if (directPath && directPath !== "") {
                    url = getRedirectUrlParams(true);
                }
                location.href = url;
            }).catch(function (err) {
                if (err.response.data.data && err.response.data.data.opcoCode) {
                    msgError(err);
                    history.push("/enter/" + err.response.data.data.opcoCode);
                    return;
                }
                msgError(err);
            });
        })
            .catch(function () {
            msgError(l("enter_account_add_err", "AAD登录失败，可能存在微软账号同步延迟"));
            $msalInstance.logoutPopup()
                .catch(function (error) {
                msgError(error);
            });
        });
    };
    return <MySpin spinning={loading}>
    {loading ? null : <Wrapper>
      <DescWrapper>
        <div style={{ marginBottom: "24px" }}>
          {l("acontroller_enter_desc_cn", "GroupM AController系统是一个专门管理外部平台账号资产的系统，本系统让公司的账号从申请到使用得以规范的运作，同时给公司的合规化审计提供便利。")}
        </div>
        <div>
          {l("acontroller_enter_desc_en", "GroupM AController system is a system dedicated to managing account assets of external platforms. This system allows the company to standardize the operation of accounts from application to use, and also facilitates the compliance audit of the company.")}
        </div>
        <div className={"doc"}>
          <a href={"https://dev.os.choreograph.cn/objects/groupm/static/others/acontroller_txt.docx"} download>
            {l("acontroller_enter_doc", "用户手册/User Manual")}
          </a>
        </div>
      </DescWrapper>
      <EnterWrapper>
        
        
        
        
        
        
        
        
        
        
        <EnterContentWrapper>
          <img alt={""} src={getImgUrl("/wp_acontroller.png")}/>
          <SingleSignOn type={"primary"} onClick={ssoLogin}>
            {l("account_enter_inner_groupm", "Sign in with GroupM")}
          </SingleSignOn>
        </EnterContentWrapper>
      </EnterWrapper>
      <div className={"relativeLink"}>
        <div>{l("common_relative_link", "友情链接")}</div>
        <a target={"_blank"} href={"https://chinaess.insidemedia.net/HRLeave/Home.aspx"}>Leave Management</a>
        <a target={"_blank"} href={"https://chinaess.insidemedia.net/Expense/"}>Expense Management</a>
        <a target={"_blank"} href={"https://ras.os.choreograph.cn"}>R.A.S</a>
      </div>
    </Wrapper>}
  </MySpin>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  min-width: 1280px;\n  min-height: 670px;\n  background: ", ";\n  background-size: cover;\n  .relativeLink {\n    position: absolute;\n    bottom: 8px;\n    left: 50%;\n    transform: translateX(-50%);\n    padding: 8px 4px;\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    background: rgba(130, 123, 225, 0.15);\n    font-size: 12px;\n    color: #4f536c;\n    > div {\n      margin: 0 14px;\n    }\n    > a {\n      padding: 0 10px;\n      border-left: 1px solid #fff;\n    }\n  }\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  min-width: 1280px;\n  min-height: 670px;\n  background: ", ";\n  background-size: cover;\n  .relativeLink {\n    position: absolute;\n    bottom: 8px;\n    left: 50%;\n    transform: translateX(-50%);\n    padding: 8px 4px;\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    background: rgba(130, 123, 225, 0.15);\n    font-size: 12px;\n    color: #4f536c;\n    > div {\n      margin: 0 14px;\n    }\n    > a {\n      padding: 0 10px;\n      border-left: 1px solid #fff;\n    }\n  }\n"])), "url(" + getImgUrl("acontroller_bg.png") + ") no-repeat center");
var EnterWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 472px;\n  background: #fff;\n  border-radius: 2px;\n  border: 1px solid #B3C3EE;\n"], ["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 472px;\n  background: #fff;\n  border-radius: 2px;\n  border: 1px solid #B3C3EE;\n"])));
var DescWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  margin-right: 120px;\n  width: 500px;\n  font-size: 18px;\n  color: #31373D;\n  .doc {\n    position: absolute;\n    bottom: -80px;\n    font-size: 14px;\n  }\n"], ["\n  position: relative;\n  margin-right: 120px;\n  width: 500px;\n  font-size: 18px;\n  color: #31373D;\n  .doc {\n    position: absolute;\n    bottom: -80px;\n    font-size: 14px;\n  }\n"])));
var EnterContentWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 200px;\n  }\n"], ["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 200px;\n  }\n"
    // const LanguageBtn = styled.div`
    //   position: absolute;
    //   top: 30px;
    //   right: 32px;
    //   color: #fff;
    // `
])));
// const LanguageBtn = styled.div`
//   position: absolute;
//   top: 30px;
//   right: 32px;
//   color: #fff;
// `
var SingleSignOn = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 60px;\n  background-color: #0454B8;\n  font-size: 24px;\n  color: #fff;\n"], ["\n  margin-top: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 60px;\n  background-color: #0454B8;\n  font-size: 24px;\n  color: #fff;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
