var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
import { AUDIENCE_APPLY_OBJECT } from "@/common/commonAction";
export var columns = function (showOperation) {
    var result = [
        {
            key: "segmentName",
            title: l("common_audience_name", "人群名称"),
            fixed: "left",
            render: [
                {
                    key: "segmentName",
                    style: { fontSize: "15px" },
                },
                {
                    key: "segmentId",
                    label: l("common_id", "ID") + ": ",
                    labelStyle: { fontSize: "12px", color: "#95aac9" },
                    style: { color: "#95aac9" },
                },
            ]
        },
        {
            key: "advertiserName",
            title: l("common_advertiser", "广告主"),
        },
        {
            key: "audienceSize",
            align: "left",
            title: l("common_audience_size", "人群规模"),
            render: [
                {
                    key: "audienceSize",
                    type: TABLE_RENDER_TYPE_ENUM.APPLY,
                }
            ]
        },
        {
            key: "dataSource",
            title: l("common_audience_source", "人群来源"),
        },
        {
            key: "statusName",
            title: l("common_status", "状态"),
            render: [
                {
                    key: "statusName",
                    type: TABLE_RENDER_TYPE_ENUM.STATUS
                }
            ]
        },
        {
            key: "lastUpdate",
            title: l("common_last_update", "最后修改"),
            render: [
                {
                    key: "lastUpdateName",
                },
                {
                    key: "lastUpdateDate",
                },
            ]
        },
    ];
    if (showOperation) {
        result.push({
            key: "operation",
            title: l("common_operation", "action"),
            fixed: "right",
            render: [
                {
                    key: "more",
                    type: TABLE_RENDER_TYPE_ENUM.MORE,
                    render: __spreadArrays(AUDIENCE_APPLY_OBJECT())
                },
            ]
        });
    }
    else {
        result.push({
            key: "operation",
            title: l("common_operation", "action"),
            fixed: "right",
            render: [
                {
                    key: "delItem",
                    text: l("common_del", "删除"),
                    type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                    hide: function (record) { return !record.actionCancel; },
                    options: {
                        text: l("common_del_confirm", "确认删除") + "?"
                    }
                },
            ]
        });
    }
    return result;
};
