import { post } from "@helpers/axiosHelper";
export var getDetail = function (taskId) {
    return post("/groupme/audex/audience/locationAudience_v2/getDetail", { taskId: taskId });
};
export var saveApi = function (data) {
    return post("/groupme/audex/audience/locationAudience_v2/save", data);
};
export var getAudienceOptions = function (data) {
    return post("/groupme/audex/audience/locationAudience_v2/getAudienceOptions", data);
};
export var getRMinMax = function (data) {
    return post("/groupme/audex/audience/locationAudience_v2/getRMinMax", data);
};
export var getAudienceType = function (sourceId) {
    return post("/groupme/audex/audience/locationAudience_v2/getAudienceOptions", { sourceId: sourceId });
};
