import { post } from "@helpers/axiosHelper";
export var getList = function (data) {
    return post("/groupme/audex/profiling/adminManage/getList", data);
};
export var submitApi = function (data) {
    return post("/groupme/audex/profiling/adminManage/submit", data);
};
export var completeApi = function (data) {
    return post("/groupme/audex/profiling/adminManage/forceComplete", data);
};
