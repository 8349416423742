var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { l } from "@helpers/multiLangaugeHelper";
import { CommonWrapper } from "@components/common/commonWrapper";
import FormItem from "antd/es/form/FormItem";
import { getItemLayout } from "@helpers/const";
import { MyTable } from "@components/myTable/myTable";
import { detailAudienceColumns } from "./helpers/initData";
import { STATUS_ID_ENUM, STATUS_OPTIONS_TYPE } from "@/common/const";
import { useHistory } from "react-router-dom";
import { map } from "lodash";
import { msgError } from "@helpers/axiosHelper";
import { getAudienceDetail } from "@pages/workplace/helpers/async";
import queryString from "query-string";
import { BatchSyncExportByUrl, EXPORT_URL_TYPE_LIST } from "@components/batchExportByUrl/batchSyncExportByUrl";
import { BatchSyncExport, EXPORT_TYPE_LIST } from "@components/batchExport/batchSyncExport";
import { MySpin } from "@components/baseComponents/mySpin";
var itemLayout = getItemLayout(6, 16);
export var WorkPlaceAudienceDetail = function () {
    var _a;
    var history = useHistory();
    var _b = useState({}), data = _b[0], setData = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var id = Number(queryString.parse(location.search).id);
    var urlMap = (_a = {},
        _a[STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE] = function (id) { return "/techHub/profiling/mprofile/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE] = function (id) { return "/techHub/profiling/mhprofile/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE] = function (id) { return "/techHub/profiling/sprofile/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.MODELING_CLUSTER] = function (id) { return "/techHub/modeling/clustering/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING] = function (id) { return "/techHub/modeling/customModeling/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE] = function (id) { return "/techHub/modeling/lookalike/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.ACTIVATION] = function (id) { return "/techhub/audex/activation/activateManage/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.AUDIENCE_OTV_AUDIENCE] = function (id) { return "/techHub/audience/OTVdAudience/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.AUDIENCE_LOCATION_AUDIENCE] = function (id) { return "/techHub/audience/LocationAudience/view?id=" + id; },
        _a[STATUS_OPTIONS_TYPE.AUDIENCE_STANDARD_AUDIENCE] = function (id) { return "/techHub/audience/standardAudience/view?id=" + id; },
        _a);
    useEffect(function () {
        setLoading(true);
        getAudienceDetail(id)
            .then(function (res) {
            setData(res.data);
            setLoading(false);
        })
            .catch(msgError);
    }, [id]);
    var toTaskDetail = function (record) {
        history.push(urlMap[record.taskType](record.taskId));
    };
    var renderAudience = function (record) {
        if ((record.taskType === STATUS_OPTIONS_TYPE.MODELING_CLUSTER ||
            record.taskType === STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE ||
            record.taskType === STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING ||
            record.taskType === STATUS_OPTIONS_TYPE.AUDIENCE_STANDARD_AUDIENCE) && record.resultList) {
            return map(record.resultList, function (item) { return (<div style={{ marginRight: "10px" }} onClick={function () { return history.push("/techHub/workplace/audienceDetail?id=" + item.segmentId); }}>
          {item.segmentName}
        </div>); });
        }
        // if (record.taskType === STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE ||
        //   record.taskType === STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE ||
        //   record.taskType === STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE
        // ) {
        // const urlMap: any = {
        //   [STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE]: (id: number) => `/techHub/profiling/mprofile/dashboard/segment?id=${id}`,
        //   [STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE]: (id: number) => `/techHub/profiling/mhprofile/dashboard/segment?id=${id}`,
        //   [STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE]: (id: number) => `/techHub/profiling/sprofile/dashboard/segment?id=${id}`,
        // }
        //   return <a
        //     style={{marginRight: "10px"}}
        //     onClick={() => history.push(urlMap[record.taskType](record.taskId))}
        //   >
        //     {l("common_operation_detail", "详情")}
        //   </a>
        // }
        return null;
    };
    var renderDownload = function (record) {
        var _a, _b;
        if (record.taskType === STATUS_OPTIONS_TYPE.MODELING_CLUSTER ||
            record.taskType === STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING ||
            record.taskType === STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE ||
            record.taskType === STATUS_OPTIONS_TYPE.ACTIVATION) {
            var downloadType = (_a = {},
                _a[STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE] = EXPORT_URL_TYPE_LIST.MODELING_LOOKALIKE,
                _a[STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING] = EXPORT_URL_TYPE_LIST.MODELING_CUSTOM_MODELING,
                _a[STATUS_OPTIONS_TYPE.ACTIVATION] = EXPORT_URL_TYPE_LIST.ACTIVATION_MANAGE_EXPORT,
                _a);
            if (record.taskType === STATUS_OPTIONS_TYPE.ACTIVATION && !record.actionDownload)
                return null;
            if (record.taskType === STATUS_OPTIONS_TYPE.ACTIVATION ||
                record.taskType === STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING ||
                record.taskType === STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE) {
                return (<BatchSyncExportByUrl style={{ marginRight: "10px" }} url={downloadType[record.taskType]} btnType={"href"} disabled={record.statusId !== STATUS_ID_ENUM.COMPLETED} params={record}/>);
            }
            return (<BatchSyncExportByUrl style={{ marginRight: "10px" }} type={downloadType[record.taskType]} btnType={"href"} disabled={record.statusId !== STATUS_ID_ENUM.COMPLETED} params={record}/>);
        }
        if (record.taskType === STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE ||
            record.taskType === STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE ||
            record.taskType === STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE) {
            var profileType = (_b = {},
                _b[STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE] = "mprofile",
                _b[STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE] = "mhprofile",
                _b[STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE] = "sprofile",
                _b);
            return (<BatchSyncExport style={{ marginRight: "10px" }} url={EXPORT_TYPE_LIST.PROFILING_SEGMENT_EXPORT} btnType={"href"} disabled={record.statusId !== STATUS_ID_ENUM.COMPLETED} params={__assign(__assign({}, record), { profileType: profileType[record.taskType] })}/>);
        }
        return null;
    };
    var applyObject = {
        taskName: toTaskDetail,
        audience: renderAudience,
        downloadItem: renderDownload
    };
    var tableProps = {
        data: data && data.list ? data.list : [],
        columns: detailAudienceColumns(),
        page: true,
        loading: false,
        applyObject: applyObject
    };
    var renderSource = function () {
        var _a;
        var urlMap = (_a = {},
            _a[STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE] = "/techHub/modeling/lookalike/view",
            _a[STATUS_OPTIONS_TYPE.MODELING_CLUSTER] = "/techHub/modeling/clustering/view",
            _a[STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING] = "/techHub/modeling/customModeling/view",
            _a[STATUS_OPTIONS_TYPE.AUDIENCE_OTV_AUDIENCE] = "/techHub/audience/OTVdAudience/view",
            _a[STATUS_OPTIONS_TYPE.AUDIENCE_LOCATION_AUDIENCE] = "/techHub/audience/LocationAudience/view",
            _a[STATUS_OPTIONS_TYPE.AUDIENCE_STANDARD_AUDIENCE] = "/techHub/audience/standardAudience/view",
            _a);
        var toSourcePage = function () {
            history.push(urlMap[data.sourceType] + "?id=" + data.sourceId);
        };
        switch (data.sourceType) {
            case STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE:
            case STATUS_OPTIONS_TYPE.MODELING_CLUSTER:
            case STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING:
                return <a onClick={toSourcePage}>{data.sourceName}</a>;
            default:
                return data.sourceName;
        }
    };
    return (<MySpin spinning={loading}>
      <CommonWrapper>
        <FormItem required {...itemLayout} label={l("common_audience_id", "人群Id")}>
          {data.segmentId}
        </FormItem>
        <FormItem required {...itemLayout} label={l("common_audience_name", "人群名称")}>
          {data.segmentName}
        </FormItem>
        <FormItem required {...itemLayout} label={l("common_advertiser", "广告主")}>
          {data.advertiserName}
        </FormItem>
        <FormItem required {...itemLayout} label={l("common_create_time", "创建时间")}>
          {data.createTime}
        </FormItem>
        <FormItem required {...itemLayout} label={l("common_audience_size", "人群规模")}>
          {data.audienceSize}
        </FormItem>
        <FormItem required {...itemLayout} label={l("common_audience_source", "人群来源")}>
          {renderSource()}
        </FormItem>
      </CommonWrapper>
      <CommonWrapper>
        <MyTable {...tableProps}/>
      </CommonWrapper>
    </MySpin>);
};
