import { post } from "@helpers/axiosHelper";
export var getDashboardMenu = function (data) {
    return post("/groupme/audex/profiling/profile/dashboard/getNavList", data);
};
export var getDashboardChartList = function (data) {
    return post("/groupme/audex/profiling/profile/dashboard/getChartList", data);
};
export var getComparisonList = function (data) {
    return post("/groupme/audex/profiling/profile/getComparisonList", data);
};
export var getSegmentList = function (data) {
    return post("/groupme/audex/profiling/profile/getSegmentList", data);
};
export var createComparison = function (data) {
    return post("/groupme/audex/profiling/profile/createComparison", data);
};
export var getComparisonDetailList = function (comparisonId) {
    return post("/groupme/audex/profiling/profile/getComparisonDetail", { comparisonId: comparisonId });
};
export var getColorListApi = function (type) {
    return post("/groupme/common/getCommonOptions", { type: type + ".tag_group_color" });
};
