import { l } from "@/helpers/multiLangaugeHelper";
export var getTopBasicInfo = function () {
    return {
        basic: [{
                key: "basicTitle",
                label: l("creative_basic_title", "标题"),
            },
            {
                key: "launchPlatform",
                label: l("creative_launch_platform", "投放平台"),
            },
            {
                key: "launchIndustry",
                label: l("creative_launch_industry", "投放行业"),
            },
            {
                key: "launchDate",
                label: l("creative_launch_date", "投放时间"),
            }],
        analysis: [{
                key: "videoType",
                label: l("creative_video_type", "视频类型"),
            },
            {
                key: "attribute",
                label: l("creative_actor_attribute", "演员属性"),
            },
            {
                key: "sceneryType",
                label: l("creative_scenery_type", "场景类型"),
            },
            {
                key: "source",
                label: l("creative_video_source", "视频来源"),
            },
            {
                key: "musicType",
                label: l("creative_music_type", "音乐类型"),
            },
            {
                key: "captions",
                label: l("creative_video_captions", "视频字幕"),
            },
            {
                key: "content",
                label: l("creative_video_content", "视频内容"),
                more: true
            }]
    };
};
export var getTopBasicApi = function (id) {
    if (id === 666627) {
        return {
            basicTitle: "一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！",
            launchPlatform: "巨量引擎",
            launchIndustry: "美妆",
            launchDate: "2021.11.15-2121.12.15",
            videoType: "直播片段",
            attribute: "素人",
            sceneryType: "内景",
            source: "群邑自制",
            musicType: "配音",
            captions: "视频下方",
            content: ["玻尿酸", "洗面奶,乳液,水乳,精华,眼霜", "主播"],
            url: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/1.mp4"
        };
    }
    if (id === 666608) {
        return {
            basicTitle: "现在连卸妆膏都加B5泛醇和玻尿酸了？轻松卸妆的同时等于皮肤spa #AHCB5卸妆膏 #dbeauty心动日 #超A实力随心游",
            launchPlatform: "巨量引擎",
            launchIndustry: "美食博主",
            launchDate: "2021.112.15-2122.12.15",
            videoType: "口播",
            attribute: "素人",
            sceneryType: "内景",
            source: "群邑自制",
            musicType: "歌曲",
            captions: "视频下方",
            content: ["面膜,卸妆,假发,美瞳", "玻尿酸", "油脂,闭口,毛孔粗大,皮肤问题,痘痘,毛孔", "网红"],
            url: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
        };
    }
    return {
        basicTitle: "一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！",
        launchPlatform: "巨量引擎",
        launchIndustry: "美妆",
        launchDate: "2021.11.15-2121.12.15",
        videoType: "直播片段",
        attribute: "素人",
        sceneryType: "内景",
        source: "群邑自制",
        musicType: "配音",
        captions: "视频下方",
        content: ["玻尿酸", "洗面奶,乳液,水乳,精华,眼霜", "主播"],
        url: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/1.mp4"
    };
};
export var getDataOverView = function () { return [
    {
        key: "like",
        label: l("creative_like_title", "点赞量"),
        icon: "icon-dianzan",
    },
    {
        key: "comment",
        label: l("creative_comment_title", "评论量"),
        icon: "icon-pinglun",
    },
    {
        key: "forward",
        label: l("creative_forward_title", "转发量"),
        icon: "icon-zhuanfaliang",
    },
    {
        key: "click",
        label: l("creative_click_title", "点击率"),
        icon: "icon-dianji",
    },
    {
        key: "clickRate",
        label: l("creative_click_rate_title", "点击转化率"),
        icon: "icon-dianjizhuanhuashuai",
    },
    {
        key: "exposureRate",
        label: l("creative_exposure_rate_title", "曝光转化率"),
        icon: "icon-puguangzhuanhuashuai",
    },
]; };
export var getDataOverViewApi = function (id) {
    if (id === 666627) {
        return {
            like: 1789,
            comment: 26,
            forward: 26,
            click: "5.2%",
            clickRate: "0%",
            exposureRate: "0%",
        };
    }
    if (id === 666608) {
        return {
            like: 798,
            comment: 158,
            forward: 27,
            click: "3.68%",
            clickRate: "0%",
            exposureRate: "0%",
        };
    }
    return {
        like: 1789,
        comment: 26,
        forward: 26,
        click: "5.2%",
        clickRate: "0%",
        exposureRate: "0%",
    };
};
export var getChartsData = function (category) {
    if (category === "click") {
        return [{ time: "2s", value: 0.22 },
            { time: "3s", value: 0.28 },
            { time: "4s", value: 0.36 },
            { time: "5s", value: 0.48 },
            { time: "6s", value: 0.46 },
            { time: "7s", value: 0.39 },
            { time: "8s", value: 0.35 },
            { time: "9s", value: 0.32 },
            { time: "10s", value: 0.28 },
            { time: "11s", value: 0.26 },
            { time: "12s", value: 0.25 },
            { time: "13s", value: 0.24 },
            { time: "14s", value: 0.24 },
            { time: "15s", value: 0.24 },
            { time: "16s", value: 0.24 },
            { time: "17s", value: 0.24 },
            { time: "18s", value: 0.24 },
            { time: "19s", value: 0.24 },
            { time: "20s", value: 0.24 },
            { time: "21s", value: 0.24 },
            { time: "22s", value: 0.24 },
            { time: "23s", value: 0.28 }];
    }
    if (category === "leave") {
        return [{ time: "2s", value: 0.22 },
            { time: "3s", value: 0.28 },
            { time: "4s", value: 0.36 },
            { time: "5s", value: 0.48 },
            { time: "6s", value: 0.46 },
            { time: "7s", value: 0.39 },
            { time: "8s", value: 0.35 },
            { time: "9s", value: 0.32 },
            { time: "10s", value: 0.28 },
            { time: "11s", value: 0.26 },
            { time: "12s", value: 0.25 },
            { time: "13s", value: 0.24 },
            { time: "14s", value: 0.24 },
            { time: "15s", value: 0.24 },
            { time: "16s", value: 0.24 },
            { time: "17s", value: 0.45 },
            { time: "18s", value: 0.24 },
            { time: "19s", value: 0.24 },
            { time: "20s", value: 0.33 },
            { time: "21s", value: 0.24 },
            { time: "22s", value: 0.24 },
            { time: "23s", value: 0.28 }];
    }
    if (category === "like") {
        return [{ time: "2s", value: 0.22 },
            { time: "3s", value: 0.28 },
            { time: "4s", value: 0.36 },
            { time: "5s", value: 0.48 },
            { time: "6s", value: 0.46 },
            { time: "7s", value: 0.28 },
            { time: "8s", value: 0.35 },
            { time: "9s", value: 0.32 },
            { time: "10s", value: 0.28 },
            { time: "11s", value: 0.26 },
            { time: "12s", value: 0.25 },
            { time: "13s", value: 0.24 },
            { time: "14s", value: 0.24 },
            { time: "15s", value: 0.24 },
            { time: "16s", value: 0.24 },
            { time: "17s", value: 0.24 },
            { time: "18s", value: 0.64 },
            { time: "19s", value: 0.24 },
            { time: "20s", value: 0.24 },
            { time: "21s", value: 0.24 },
            { time: "22s", value: 0.24 },
            { time: "23s", value: 0.28 }];
    }
    if (category === "comment") {
        return [{ time: "2s", value: 0.22 },
            { time: "3s", value: 0.28 },
            { time: "4s", value: 0.36 },
            { time: "5s", value: 0.48 },
            { time: "6s", value: 0.22 },
            { time: "7s", value: 0.39 },
            { time: "8s", value: 0.35 },
            { time: "9s", value: 0.32 },
            { time: "10s", value: 0.28 },
            { time: "11s", value: 0.8 },
            { time: "12s", value: 0.25 },
            { time: "13s", value: 0.24 },
            { time: "14s", value: 0.24 },
            { time: "15s", value: 0.24 },
            { time: "16s", value: 0.24 },
            { time: "17s", value: 0.24 },
            { time: "18s", value: 0.24 },
            { time: "19s", value: 0.24 },
            { time: "20s", value: 0.66 },
            { time: "21s", value: 0.24 },
            { time: "22s", value: 0.24 },
            { time: "23s", value: 0.28 }];
    }
    if (category === "forward") {
        return [{ time: "2s", value: 0.22 },
            { time: "3s", value: 0.28 },
            { time: "4s", value: 0.36 },
            { time: "5s", value: 0.48 },
            { time: "6s", value: 0.22 },
            { time: "7s", value: 0.39 },
            { time: "8s", value: 0.35 },
            { time: "9s", value: 0.32 },
            { time: "10s", value: 0.28 },
            { time: "11s", value: 0.26 },
            { time: "12s", value: 0.25 },
            { time: "13s", value: 0.24 },
            { time: "14s", value: 0.24 },
            { time: "15s", value: 0.24 },
            { time: "16s", value: 0.24 },
            { time: "17s", value: 0.24 },
            { time: "18s", value: 0.24 },
            { time: "19s", value: 0.24 },
            { time: "20s", value: 0.24 },
            { time: "21s", value: 0.24 },
            { time: "22s", value: 0.9 },
            { time: "23s", value: 0.28 }];
    }
    return [{ time: "2s", value: 0.22 },
        { time: "3s", value: 0.67 },
        { time: "4s", value: 0.36 },
        { time: "5s", value: 0.48 },
        { time: "6s", value: 0.46 },
        { time: "7s", value: 0.39 },
        { time: "8s", value: 0.35 },
        { time: "9s", value: 0.32 },
        { time: "10s", value: 0.28 },
        { time: "11s", value: 0.26 },
        { time: "12s", value: 0.25 },
        { time: "13s", value: 0.24 },
        { time: "14s", value: 0.24 },
        { time: "15s", value: 0.24 },
        { time: "16s", value: 0.24 },
        { time: "17s", value: 0.24 },
        { time: "18s", value: 0.24 },
        { time: "19s", value: 0.24 },
        { time: "20s", value: 0.24 },
        { time: "21s", value: 0.24 },
        { time: "22s", value: 0.24 },
        { time: "23s", value: 0.43 }];
};
