var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { getImgUrl } from "@helpers/const";
import styled from "styled-components";
export var Migrate = function () {
    return (<Wrapper>
    <img src={getImgUrl("migrate.png")}/>
    <TipsText>
      <p>Go to <a onClick={function () { return window.open(process.env.NODE_ENV === "production" ? "https://www.os.choreograph.cn" : "https://uat.os.choreograph.cn/"); }}>GroupM Engine</a> for further operation.
      </p>
    </TipsText>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  > img {\n    -webkit-user-drag: none;\n  }\n"], ["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  > img {\n    -webkit-user-drag: none;\n  }\n"])));
var TipsText = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  span {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"], ["\n  margin-top: 28px;\n  text-align: center;\n  color: #040404;\n  >p {\n    margin-bottom: 4px;\n  }\n  span {\n    margin-left: 6px;\n    color: #1890FF;\n  }\n"])));
var templateObject_1, templateObject_2;
