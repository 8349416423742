var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
import { ACTION_COLUMNS, AUDIENCE_APPLY_OBJECT } from "@/common/commonAction";
export var columns = function () { return [
    {
        key: "segmentName",
        fixed: "left",
        title: l("common_audience_name", "人群名称"),
        render: [
            {
                key: "segmentName",
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                options: {
                    path: "/techHub/audience/OTVAudience/view",
                    params: {
                        id: "taskId"
                    }
                },
                style: { fontSize: "15px" },
            },
            {
                key: "taskId",
                label: l("common_task_code", "任务Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
            {
                key: "segmentId",
                label: l("common_audience_id", "人群Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "advertiserName",
        title: l("common_advertiser", "广告主"),
    },
    {
        key: "audienceSize",
        align: "right",
        title: l("common_audience_size", "人群规模"),
        render: [
            {
                key: "audienceSize",
                type: TABLE_RENDER_TYPE_ENUM.APPLY,
            }
        ],
    },
    {
        key: "statusName",
        title: l("common_status", "状态"),
        render: [
            {
                key: "statusName",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "lastUpdate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
            },
        ]
    },
    {
        key: "operation",
        title: l("common_operation", "action"),
        fixed: "right",
        render: [
            {
                key: "editItem",
                text: l("common_edit", "edit"),
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                options: {
                    path: "/techHub/audience/OTVAudience/edit",
                    params: {
                        id: "taskId"
                    }
                },
                hide: function (record) { return !record.actionEdit; }
            },
            {
                key: "copyItem",
                text: l("common_copy", "复制"),
                type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                options: {
                    text: l("common_copy_confirm", "确认复制") + "?"
                }
            },
            {
                key: "delItem",
                text: l("common_del", "删除"),
                type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                hide: function (record) { return !record.actionCancel; },
                options: {
                    text: l("common_del_confirm", "确认删除") + "?"
                }
            },
            {
                key: "more",
                type: TABLE_RENDER_TYPE_ENUM.MORE,
                render: __spreadArrays(AUDIENCE_APPLY_OBJECT(), ACTION_COLUMNS())
            },
        ]
    },
]; };
