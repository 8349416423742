var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { getRedirectUrlParams } from "@/utils/unit";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { Button, message } from "antd";
import { l } from "@/helpers/multiLangaugeHelper";
import { MyModal } from "@/components/baseComponents/myModal";
import { MyInput } from "@components/baseComponents/myInput";
import styled from "styled-components";
import { MAIN_COLOR } from "@/common/styled";
import { checkEmailCode, getWxEmailCode, wxLogin } from "./helpers/async";
import { getImgUrl } from "@helpers/const";
var timmer;
var waitTime = 0;
export var WechatRedirect = function () {
    var _a = queryString.parse(location.search), code = _a.code, opco = _a.opco, redirect = _a.redirect, env = _a.env, user = _a.user, yzm = _a.yzm;
    var _b = useState(), email = _b[0], setEmail = _b[1];
    var _c = useState(), YCode = _c[0], setYCode = _c[1];
    var _d = useState(0), showTime = _d[0], setShowTime = _d[1];
    var _e = useState(false), QRCodeVisible = _e[0], setQRCodeVisible = _e[1];
    var _f = useState(false), emailLinkVisible = _f[0], setEmailLinkVisible = _f[1];
    var _g = useState(), err = _g[0], setErr = _g[1];
    useEffect(function () {
        var host = location.host;
        var hostList = host.split(".");
        if (env && (hostList[0] !== env)) {
            var host_1 = location.host;
            var hostList_1 = host_1.split(".");
            hostList_1.splice(0, 1, env);
            var newUrl = location.protocol + "//" + hostList_1.join(".") + location.pathname;
            var url = newUrl + ("?opco=" + opco + "&directPath=" + (redirect ? redirect : "") + "&code=" + code);
            location.href = url;
            return;
        }
        if (location.search && code && code !== "" && location.host.indexOf("localhost") === -1) {
            wxLogin({ openCode: code, opco: opco }, { user: user, yzm: yzm })
                .then(function (res) {
                var url = res.data.url || "/techHub/workplace/index";
                if (redirect && redirect !== "") {
                    url = getRedirectUrlParams(true);
                }
                location.href = url;
            })
                .catch(function (err) {
                setErr(err);
                msgError(err);
            });
        }
    }, []);
    var checkEmailDisabled = function () {
        return !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email));
    };
    var sendEmail = function () {
        getWxEmailCode(email)
            .then(function (res) {
            msgSuccess(res);
            waitTime = 60;
            setShowTime(waitTime);
            timmer = setInterval(function () {
                waitTime--;
                setShowTime(waitTime);
                if (waitTime < 0) {
                    clearInterval(timmer);
                    timmer = null;
                }
            }, 1000);
        })
            .catch(function (err) {
            message.error(err.response.data.msg);
        });
    };
    var checkEmail = function () {
        checkEmailCode({ email: email, yzm: YCode })
            .then(function (res) {
            setQRCodeVisible(true);
            msgSuccess(res);
        })
            .catch(function (err) {
            message.error(err.response.data.msg);
        });
    };
    var emailDisabled = checkEmailDisabled();
    var wechatCodeUrl = function (opco, directPath) {
        var sessionLanguageCode = localStorage.getItem("languageCode");
        var APPID = "wxddc71065def1cbb5";
        var ENV = location.host.split(".")[0];
        var OPCO = opco;
        var href = "https://www.os.choreograph.cn/objects/groupm/static/css/wechatQrcode.css";
        var REDIRECT_URI = encodeURIComponent("https://dev.os.choreograph.cn/wechatRedirect?env=" + ENV + "&opco=" + OPCO + "&user=" + email + "&yzm=" + YCode + "&redirect=" + (directPath ? directPath : ""));
        var result = "https://open.weixin.qq.com/connect/qrconnect?appid=" + APPID + "&redirect_uri=" + REDIRECT_URI + "&response_type=code&scope=snsapi_login&lang=" + sessionLanguageCode + "&href=" + href + "#wechat_redirect";
        return result;
    };
    if (!err && location.host.indexOf("localhost") === -1) {
        return null;
    }
    return (<Wrapper>
    <div>
      <div className={"title"}>{l("wechat_link_tips_title", "你可以尝试以下方法来解决:")}</div>
      <img style={{ width: "258px" }} src={getImgUrl("wechat/qrCode.jpg")}/>
      <div>
        <div className={"tips"}>
          {l("wechat_link_tips_1", "1、关注公众号[群邑技术与数据]，登录 Timesheet，系统会自动将微信和你的账号绑定。")}
        </div>
        <div className={"tips"}>
          {l("wechat_link_tips_2", "2、电脑登录 Timesheet，点击右上角按钮，将微信和你的账号绑定。")}
        </div>
        <div className={"tips"}>
          {l("wechat_link_tips_3", "3、如果公司Okta有问题，导致你无法登录系统，可以点下面的链接来绑定。")}
        </div>
        <div className={"tips"}>
          <a className={"btn"} onClick={function () { return setEmailLinkVisible(true); }}>{l("wechat_link_tips_4", "点我绑定")}</a>
        </div>
      </div>
    </div>
    <MyModal title={l("wechat_email_link", "微信-邮箱绑定")} width={480} visible={emailLinkVisible} footer={null} onCancel={function () { return setEmailLinkVisible(false); }}>
      {!QRCodeVisible ? <>
          <LineFormItem>
            <span>{l("wechat_email_link_email", "邮箱")}</span>
            <MyInput style={{ width: "320px" }} placeholder={l("wechat_email_link_email_placeholder", "请输入邮箱")} addonAfter={showTime > 0 ?
        <span style={{ color: "#999" }}>{showTime}s</span> :
        <SendBtn disabled={emailDisabled} onClick={emailDisabled ? null : sendEmail}>
                  {l("wechat_email_link_check_email", "验证邮箱")}
                </SendBtn>} value={email} onChange={function (e) { return setEmail(e.target.value); }}/>
          </LineFormItem>
          <LineFormItem>
            <span>{l("wechat_email_link_yzm", "验证码")}</span>
            <MyInput style={{ width: "120px" }} placeholder={l("wechat_email_link_yzm_placeholder", "请输入验证码")} value={YCode} onChange={function (e) { return setYCode(e.target.value); }}/>
          </LineFormItem>
          <div style={{ marginTop: "24px", textAlign: "center" }}>
            <Button type={"primary"} disabled={!email || !YCode} onClick={checkEmail}>
              {l("wechat_email_link_yzm_confirm", "确定")}
            </Button>
          </div>
        </> :
        <QRCodeWrapper>
          <iframe width={"100%"} height={"100%"} frameBorder={0} src={wechatCodeUrl(opco, redirect)}/>
        </QRCodeWrapper>}
    </MyModal>
  </Wrapper>);
};
var LineFormItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  > span {\n    margin-right: 8px;\n    display: inline-block;\n    width: 80px;\n    text-align: right;\n  }\n"], ["\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  > span {\n    margin-right: 8px;\n    display: inline-block;\n    width: 80px;\n    text-align: right;\n  }\n"])));
var QRCodeWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 340px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 340px;\n"])));
var SendBtn = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: ", "\n  color: ", "\n"], ["\n  cursor: ", "\n  color: ", "\n"])), function (props) { return props.disabled ? "not-allowed" : "pointer"; }, function (props) { return props.disabled ? "#aaa" : MAIN_COLOR; });
var Wrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  > div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 50%;\n  }\n  .title {\n    font-size: 20px;\n    font-weight: bold;\n  }\n  .tips {\n    font-size: 16px;\n    .btn {\n      text-indent: 20px;\n      font-size: 14px;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  > div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 50%;\n  }\n  .title {\n    font-size: 20px;\n    font-weight: bold;\n  }\n  .tips {\n    font-size: 16px;\n    .btn {\n      text-indent: 20px;\n      font-size: 14px;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
