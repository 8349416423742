var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Rate } from "antd";
import { merge, set } from "lodash";
import { l } from "@helpers/multiLangaugeHelper";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { getItemLayout } from "@helpers/const";
import { MySelect } from "@components/baseComponents/mySelect";
import { clearAddress, clearCompetingAddress, clearMedia, delAddressApi, delCompetingAddressApi, delMediaApi, getAddressGroupList, getCompetingAddressGroupList, getDetail, getMediaGroupList, saveAddressGroupApi, saveApi } from "./helpers/async";
import queryString from "query-string";
import { FooterBtnWrapper } from "@components/common/footerBtnWrapper";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { MyInput } from "@components/baseComponents/myInput";
import { MAIN_COLOR } from "@/common/styled";
import { ADDRESS_GROUP_ENUM, addressGroupColumns, mediaGroupColumns } from "@pages/doohPlaninng/helpers/initData";
import { MyTable } from "@components/myTable/myTable";
import { MySpin } from "@components/baseComponents/mySpin";
import { MyTextArea } from "@components/baseComponents/myTextArea";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import { TableWrapper } from "@components/common/tableWrapper";
import { MyTableSearch } from "@components/baseComponents/myTableSearch";
import numeral from "numeral";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MyFormWrapper, MyItemWrapper } from "@/components/common/myFormWrapper";
import { CommonTitle } from "@components/common/commonTitle";
import { MyModal } from "@components/baseComponents/myModal";
var FormItem = Form.Item;
var itemLayout = getItemLayout(4, 16);
export var DoohPlanningEdit = function (_a) {
    var readonly = _a.readonly;
    var id = queryString.parse(location.search).id;
    var history = useHistory();
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState({
        scenarioWeight: 6,
        adTypeWeight: 6,
        distanceWeight: 6,
        audienceScaleWeight: 6,
        taCoverageWeight: 6
    }), formData = _c[0], setFormData = _c[1];
    var _d = useState(false), saving = _d[0], setSaving = _d[1];
    var _e = useState(false), nameError = _e[0], setNameError = _e[1];
    var _f = useState([]), addressGroup = _f[0], setAddressGroup = _f[1];
    var _g = useState(false), addressGroupLoading = _g[0], setAddressGroupLoading = _g[1];
    var _h = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), addressGroupPage = _h[0], setAddressGroupPage = _h[1];
    var _j = useState({}), addressGroupConditions = _j[0], setAddressGroupConditions = _j[1];
    var _k = useState([]), competingAddressGroup = _k[0], setCompetingAddressGroup = _k[1];
    var _l = useState(false), competingAddressGroupLoading = _l[0], setCompetingAddressGroupLoading = _l[1];
    var _m = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), competingAddressGroupPage = _m[0], setCompetingAddressGroupPage = _m[1];
    var _o = useState({}), competingAddressGroupConditions = _o[0], setCompetingAddressGroupConditions = _o[1];
    var _p = useState([]), mediaGroup = _p[0], setMediaGroup = _p[1];
    var _q = useState(false), mediaGroupLoading = _q[0], setMediaGroupLoading = _q[1];
    var _r = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), mediaGroupPage = _r[0], setMediaGroupPage = _r[1];
    var _s = useState({}), mediaGroupConditions = _s[0], setMediaGroupConditions = _s[1];
    var _t = useState(false), addressModalVisible = _t[0], setAddressModalVisible = _t[1];
    var _u = useState(), addressModalType = _u[0], setAddressModalType = _u[1];
    var _v = useState(), addressModalData = _v[0], setAddressModalData = _v[1];
    var _w = useState(""), addressUrl = _w[0], setAddressUrl = _w[1];
    useEffect(function () {
        getDetail(Number(id), readonly)
            .then(function (res) {
            var data = res.data;
            setFormData(id ? data : merge(data, formData));
            setLoading(false);
        })
            .catch(msgError);
    }, []);
    useEffect(function () {
        if (formData.uid && formData.taskId) {
            onAddressGroupSearch(addressGroupConditions, addressGroupPage);
            onCompetingAddressGroupSearch(competingAddressGroupConditions, competingAddressGroupPage);
            onMediaGroupSearch(mediaGroupConditions, mediaGroupPage);
        }
    }, [formData.uid]);
    var onFormChange = function (value, pathKey) {
        var newData = merge({}, formData);
        set(newData, pathKey, value);
        if (pathKey === "advertiserId") {
            if (addressGroup.length > 0 || competingAddressGroup.length > 0 || mediaGroup.length > 0) {
                Modal.confirm({
                    title: l("common_warn_tips", "温馨提示"),
                    content: l("dooh_planning_change_city_tips", "修改城市将清空下列所有地址包，是否继续？"),
                    onOk: function () {
                        setLoading(true);
                        clearAddress({ uid: formData.uid })
                            .then(function () {
                            return clearCompetingAddress({ uid: formData.uid });
                        })
                            .then(function () {
                            return clearMedia({ uid: formData.uid });
                        })
                            .then(function () {
                            setAddressGroup([]);
                            setAddressGroupPage({ total: null, pageSize: 10, current: 1 });
                            setCompetingAddressGroup([]);
                            setCompetingAddressGroupPage({ total: null, pageSize: 10, current: 1 });
                            setMediaGroup([]);
                            setMediaGroupPage({ total: null, pageSize: 10, current: 1 });
                            set(newData, "productIdList", null);
                            set(newData, "segmentId", null);
                            setLoading(false);
                            setFormData(newData);
                        })
                            .catch(msgError);
                    }
                });
                return;
            }
            set(newData, "productIdList", null);
            set(newData, "segmentId", null);
        }
        if ((pathKey === "cityIdList" || pathKey === "scenarioIdList") && (addressGroup.length > 0 || competingAddressGroup.length > 0 || mediaGroup.length > 0)) {
            Modal.confirm({
                title: l("common_warn_tips", "温馨提示"),
                content: l("dooh_planning_change_city_tips", "修改城市将清空下列所有地址包，是否继续？"),
                onOk: function () {
                    setLoading(true);
                    clearAddress({ uid: formData.uid })
                        .then(function () {
                        return clearCompetingAddress({ uid: formData.uid });
                    })
                        .then(function () {
                        return clearMedia({ uid: formData.uid });
                    })
                        .then(function () {
                        setAddressGroup([]);
                        setAddressGroupPage({ total: null, pageSize: 10, current: 1 });
                        setCompetingAddressGroup([]);
                        setCompetingAddressGroupPage({ total: null, pageSize: 10, current: 1 });
                        setMediaGroup([]);
                        setMediaGroupPage({ total: null, pageSize: 10, current: 1 });
                        setFormData(newData);
                        setLoading(false);
                    })
                        .catch(msgError);
                }
            });
            return;
        }
        setFormData(newData);
    };
    var onAddressGroupSearch = function (conditions, page) {
        var data = __assign({ conditions: __assign(__assign({}, conditions), { uid: formData.uid }) }, page);
        setAddressGroupConditions(conditions);
        setAddressGroupLoading(true);
        getAddressGroupList(data)
            .then(function (res) {
            setAddressGroup(res.data.list);
            setAddressGroupPage(merge({}, page, { total: res.data.total }));
            setAddressGroupLoading(false);
        })
            .catch(function (err) {
            setAddressGroupLoading(false);
            msgError(err);
        });
    };
    var onCompetingAddressGroupSearch = function (conditions, page) {
        var data = __assign({ conditions: __assign(__assign({}, conditions), { uid: formData.uid }) }, page);
        setCompetingAddressGroupConditions(conditions);
        setCompetingAddressGroupLoading(true);
        getCompetingAddressGroupList(data)
            .then(function (res) {
            setCompetingAddressGroup(res.data.list);
            setCompetingAddressGroupPage(merge({}, page, { total: res.data.total }));
            setCompetingAddressGroupLoading(false);
        })
            .catch(function (err) {
            setCompetingAddressGroupLoading(false);
            msgError(err);
        });
    };
    var onMediaGroupSearch = function (conditions, page) {
        var data = __assign({ conditions: __assign(__assign({}, conditions), { uid: formData.uid }) }, page);
        setMediaGroupConditions(conditions);
        setMediaGroupLoading(true);
        getMediaGroupList(data)
            .then(function (res) {
            setMediaGroup(res.data.list);
            setMediaGroupPage(merge({}, page, { total: res.data.total }));
            setMediaGroupLoading(false);
        })
            .catch(function (err) {
            setMediaGroupLoading(false);
            msgError(err);
        });
    };
    var onAddressModalOpen = function (type) {
        switch (type) {
            case ADDRESS_GROUP_ENUM.ADDRESS_GROUP:
            case ADDRESS_GROUP_ENUM.COMPETING_ADDRESS_GROUP:
            default:
                setAddressUrl("/groupme/audex/doohplanning/getAddressGroupOptions");
                break;
            case ADDRESS_GROUP_ENUM.MEDIA_GROUP:
                setAddressUrl("/groupme/audex/doohplanning/getMediaGroupOptions");
                break;
        }
        setAddressModalData(null);
        setAddressModalType(type);
        setAddressModalVisible(true);
    };
    var onAddressModalSave = function () {
        var data = {
            groupIdList: addressModalData,
            uid: formData.uid,
            cityIdList: formData.cityIdList,
            scenarioIdList: formData.scenarioIdList
        };
        saveAddressGroupApi(data, addressModalType)
            .then(function (res) {
            setAddressModalData(null);
            setAddressModalVisible(false);
            switch (addressModalType) {
                case ADDRESS_GROUP_ENUM.ADDRESS_GROUP:
                    onAddressGroupSearch(addressGroupConditions, merge({}, addressGroupPage, { current: 1 }));
                    break;
                case ADDRESS_GROUP_ENUM.COMPETING_ADDRESS_GROUP:
                    onCompetingAddressGroupSearch(competingAddressGroupConditions, merge({}, competingAddressGroupPage, { current: 1 }));
                    break;
                case ADDRESS_GROUP_ENUM.MEDIA_GROUP:
                    onMediaGroupSearch(mediaGroupConditions, merge({}, mediaGroupPage, { current: 1 }));
                    break;
            }
            msgSuccess(res);
        })
            .catch(msgError);
    };
    var onClearAddress = function (type) {
        switch (type) {
            case ADDRESS_GROUP_ENUM.ADDRESS_GROUP:
                clearAddress({ uid: formData.uid, keyword: addressGroupConditions.keyword })
                    .then(function (res) {
                    onAddressGroupSearch(addressGroupConditions, addressGroupPage);
                    msgSuccess(res);
                })
                    .catch(msgError);
                break;
            case ADDRESS_GROUP_ENUM.COMPETING_ADDRESS_GROUP:
                clearCompetingAddress({ uid: formData.uid, keyword: competingAddressGroupConditions.keyword })
                    .then(function (res) {
                    onCompetingAddressGroupSearch(competingAddressGroupConditions, competingAddressGroupPage);
                    msgSuccess(res);
                })
                    .catch(msgError);
                break;
            case ADDRESS_GROUP_ENUM.MEDIA_GROUP:
                clearMedia({ uid: formData.uid, keyword: mediaGroupConditions.keyword })
                    .then(function (res) {
                    onMediaGroupSearch(mediaGroupConditions, mediaGroupPage);
                    msgSuccess(res);
                })
                    .catch(msgError);
                break;
        }
    };
    var save = function () {
        setSaving(true);
        saveApi(formData)
            .then(function (res) {
            msgSuccess(res);
            setSaving(false);
            history.push("/techHub/doohplanning/manage");
        })
            .catch(function (err) {
            setSaving(false);
            msgError(err);
        });
    };
    var checkSaveDisabled = function () {
        var taskName = formData.taskName, advertiserId = formData.advertiserId, segmentId = formData.segmentId, memo = formData.memo, cityIdList = formData.cityIdList, scenarioIdList = formData.scenarioIdList, campaignGoalId = formData.campaignGoalId;
        if (nameError)
            return true;
        if (!(taskName && taskName !== ""))
            return true;
        if (!(memo && memo !== "" && campaignGoalId))
            return true;
        if (!advertiserId || !segmentId)
            return true;
        if (!(cityIdList && cityIdList.length > 0 && scenarioIdList && scenarioIdList.length > 0))
            return true;
        return false;
    };
    var delAddressItem = function (record) {
        delAddressApi(__assign(__assign({}, record), { uid: formData.uid }))
            .then(function (res) {
            onAddressGroupSearch(addressGroupConditions, addressGroupPage);
        })
            .catch(msgError);
    };
    var delCompetingAddressItem = function (record) {
        delCompetingAddressApi(__assign(__assign({}, record), { uid: formData.uid }))
            .then(function (res) {
            onCompetingAddressGroupSearch(competingAddressGroupConditions, competingAddressGroupPage);
        })
            .catch(msgError);
    };
    var delMediaItem = function (record) {
        delMediaApi(__assign(__assign({}, record), { uid: formData.uid }))
            .then(function (res) {
            onMediaGroupSearch(mediaGroupConditions, mediaGroupPage);
        })
            .catch(msgError);
    };
    var applyObject = {
        delAddressItem: delAddressItem,
        delCompetingAddressItem: delCompetingAddressItem,
        delMediaItem: delMediaItem,
    };
    var addressGroupTableProps = {
        data: addressGroup,
        columns: addressGroupColumns(true, readonly),
        page: addressGroupPage,
        loading: addressGroupLoading,
        onChange: function (page) { return onAddressGroupSearch(addressGroupConditions, page); },
        applyObject: applyObject,
    };
    var competingGroupTableProps = {
        data: competingAddressGroup,
        columns: addressGroupColumns(false, readonly),
        page: competingAddressGroupPage,
        loading: competingAddressGroupLoading,
        onChange: function (page) { return onCompetingAddressGroupSearch(competingAddressGroupConditions, page); },
        applyObject: applyObject,
    };
    var mediaGroupTableProps = {
        data: mediaGroup,
        columns: mediaGroupColumns(readonly),
        page: mediaGroupPage,
        loading: mediaGroupLoading,
        onChange: function (page) { return onMediaGroupSearch(mediaGroupConditions, page); },
        applyObject: applyObject,
    };
    var addressSelectDisabled = !(formData.advertiserId &&
        formData.cityIdList && formData.cityIdList.length > 0 &&
        formData.scenarioIdList && formData.scenarioIdList.length > 0);
    return (<MySpin spinning={loading}>
      <CommonWrapper>
        <CommonTitle>{l("common_basic_info", "Basic Information")}</CommonTitle>
        <MyFormWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_task_name", "任务名称")}>
              <MyInput style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_task_name", "任务名称")} disabled={readonly} value={formData.taskName} onChange={function (v) { return onFormChange(v.target.value, "taskName"); }} errorMsg={l("common_task_name_err_msg_tips", "任务名称只能输入字母数字及下划线且长度不超过50")} validateReg={"^[0-9a-zA-Z_]{1,50}$"} errorCallBack={function (flag) { return setNameError(flag); }}/>
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_advertiser", "Advertiser")}>
              <MySelect disabled={readonly} showSearch={true} allowClear={false} style={{ width: "50%", marginRight: "10px" }} placeholder={l("common_select_advertiser", "Select Advertiser")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={formData.advertiserId} onChange={function (v) { return onFormChange(v, "advertiserId"); }}/>
              <MySelect disabled={readonly} showSearch={true} mode={"multiple"} style={{ flex: 1 }} placeholder={l("common_select_product", "Select Product")} url={"/groupme/common/product/getProductOptions"} params={{ advertiserId: formData.advertiserId }} value={formData.productIdList} onChange={function (v) { return onFormChange(v, "productIdList"); }}/>
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_audience", "种子人群")}>
              <MySelect disabled={readonly} style={{ width: FORM_ITEM_WIDTH }} showSearch={true} placeholder={l("common_select_audience", "选择人群")} url={"/groupme/audex/audience/getAudienceOptionsByAdvertiserId_v2"} params={{ advertiserId: formData.advertiserId }} value={formData.segmentId} onChange={function (v) { return onFormChange(v, "segmentId"); }} descFormatter={function (v) { return <DescSelectTips>
                  {l("common_audience_size", "人群规模")}: {numeral(v).format("0,0.00a")}
                </DescSelectTips>; }}/>
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_memo", "备注")}>
              <MyTextArea disabled={readonly} style={{ width: FORM_ITEM_WIDTH }} value={formData.memo} onChange={function (v) { return onFormChange(v.target.value, "memo"); }}/>
            </MyFormItem>
          </MyItemWrapper>
        </MyFormWrapper>
        <CommonTitle>{l("common_detail_info", "Detail information")}</CommonTitle>
        <MyFormWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_city", "City")}>
              <MySelect disabled={readonly} style={{ width: FORM_ITEM_WIDTH }} showSearch={true} mode={"multiple"} placeholder={l("common_city_placeholder", "Select City")} url={"/groupme/common/cpca/getCityOptions"} value={formData.cityIdList} onChange={function (v) { return onFormChange(v, "cityIdList"); }}/>
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("dooh_planning_scenario", "Scenario")}>
              <MySelect disabled={readonly} mode={"multiple"} style={{ width: FORM_ITEM_WIDTH }} showSearch={true} placeholder={l("dooh_planning_scenario", "Scenario")} url={"/groupme/audex/doohplanning/getScenarioOptions"} value={formData.scenarioIdList} onChange={function (v) { return onFormChange(v, "scenarioIdList"); }}/>
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("dooh_planning_campaign_goal", "Campaign Goal")}>
              <MySelect disabled={readonly} style={{ width: FORM_ITEM_WIDTH }} showSearch={true} placeholder={l("dooh_planning_campaign_goal_select_placeholder", "Select Goal")} url={"/groupme/audex/doohplanning/getCampaignGoalOptions"} value={formData.campaignGoalId} onChange={function (v) { return onFormChange(v, "campaignGoalId"); }}/>
            </MyFormItem>
          </MyItemWrapper>
          <MyFormItem style={{ width: "100%", padding: "0 8px" }} required label={l("address_group", "Address Group")}>
            <TableWrapper style={{ margin: 0 }}>
              <MyTableSearch placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onAddressGroupSearch({ keyword: value }, merge({}, addressGroupPage, { current: 1 })); }} suffix={<>
                  {readonly ? null : <Button style={{ marginRight: "10px" }} disabled={addressSelectDisabled} type="primary" onClick={function () { return onAddressModalOpen(ADDRESS_GROUP_ENUM.ADDRESS_GROUP); }}>
                    {l("dooh_planning_select_address_group_address", "选择地址包")}
                  </Button>}
                  {readonly ? null : <Button type="ghost" onClick={function () { return onClearAddress(ADDRESS_GROUP_ENUM.ADDRESS_GROUP); }}>
                    {l("dooh_planning_clear_now_list_data_address", "清空当前数据")}
                  </Button>}
                </>}/>
              <MyTable {...addressGroupTableProps}/>
            </TableWrapper>
          </MyFormItem>
          <MyFormItem style={{ width: "100%", padding: "0 8px" }} label={l("address_group_competing", "Competing Address Group")}>
            <TableWrapper style={{ margin: 0 }}>
              <MyTableSearch placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onCompetingAddressGroupSearch({ keyword: value }, merge({}, competingAddressGroupPage, { current: 1 })); }} suffix={<>
                  {readonly ? null : <Button style={{ marginRight: "10px" }} disabled={addressSelectDisabled} type="primary" onClick={function () { return onAddressModalOpen(ADDRESS_GROUP_ENUM.COMPETING_ADDRESS_GROUP); }}>
                    {l("dooh_planning_select_address_group_competing", "选择地址包")}
                  </Button>}
                  {readonly ? null : <Button type="ghost" onClick={function () { return onClearAddress(ADDRESS_GROUP_ENUM.COMPETING_ADDRESS_GROUP); }}>
                    {l("dooh_planning_clear_now_list_data_competing", "清空当前数据")}
                  </Button>}
                </>}/>
              <MyTable {...competingGroupTableProps}/>
            </TableWrapper>
          </MyFormItem>
          <MyFormItem style={{ width: "100%", padding: "0 8px" }} label={l("dooh_planning_select_media_group", "Media Group")}>
            <TableWrapper style={{ margin: 0 }}>
              <MyTableSearch placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onMediaGroupSearch({ keyword: value }, merge({}, mediaGroupPage, { current: 1 })); }} suffix={<>
                  {readonly ? null : <Button style={{ marginRight: "10px" }} disabled={addressSelectDisabled} type="primary" onClick={function () { return onAddressModalOpen(ADDRESS_GROUP_ENUM.MEDIA_GROUP); }}>
                    {l("dooh_planning_select_address_group_media", "选择线下资源包")}
                  </Button>}
                  {readonly ? null : <Button type="ghost" onClick={function () { return onClearAddress(ADDRESS_GROUP_ENUM.MEDIA_GROUP); }}>
                    {l("dooh_planning_clear_now_list_data_media", "清空当前数据")}
                  </Button>}
                </>}/>
              <MyTable {...mediaGroupTableProps}/>
            </TableWrapper>
          </MyFormItem>
          <MyItemWrapper style={{ width: "100%" }}>
            <MyFormItem required label={l("dooh_planning_index_weight", "Index Weight")}>
              <span>{l("dooh_planning_index_weight_tips", "Please light the following stars (More stars means higher weight of current item)")}</span>
              <IdxWeightWrapper {...itemLayout} label={l("dooh_planning_index_weight_scenario", "Scenario")}>
                <Rate disabled={readonly} allowClear={false} count={10} value={formData.scenarioWeight} onChange={function (v) { return onFormChange(v, "scenarioWeight"); }}/>
              </IdxWeightWrapper>
              <IdxWeightWrapper {...itemLayout} label={l("dooh_planning_index_weight_adType", "Ad Type")}>
                <Rate disabled={readonly} allowClear={false} count={10} value={formData.adTypeWeight} onChange={function (v) { return onFormChange(v, "adTypeWeight"); }}/>
              </IdxWeightWrapper>
              <IdxWeightWrapper {...itemLayout} label={l("dooh_planning_index_weight_distance", "Distance")}>
                <Rate disabled={readonly} allowClear={false} count={10} value={formData.distanceWeight} onChange={function (v) { return onFormChange(v, "distanceWeight"); }}/>
              </IdxWeightWrapper>
              <IdxWeightWrapper {...itemLayout} label={l("dooh_planning_index_weight_audience_scale", "Audience Scale")}>
                <Rate disabled={readonly} allowClear={false} count={10} value={formData.audienceScaleWeight} onChange={function (v) { return onFormChange(v, "audienceScaleWeight"); }}/>
              </IdxWeightWrapper>
              <IdxWeightWrapper {...itemLayout} label={l("dooh_planning_index_weight_ta_coverage", "TA Coverage")}>
                <Rate disabled={readonly} allowClear={false} count={10} value={formData.taCoverageWeight} onChange={function (v) { return onFormChange(v, "taCoverageWeight"); }}/>
              </IdxWeightWrapper>
            </MyFormItem>
          </MyItemWrapper>
        </MyFormWrapper>
        {readonly ? null : <FooterBtnWrapper buttonList={[
        {
            type: "primary",
            text: l("common_save", "Save"),
            onClick: function () { return save(); },
            disabled: checkSaveDisabled(),
            loading: saving
        }
    ]}/>}
      </CommonWrapper>
      <MyModal title={addressModalType === ADDRESS_GROUP_ENUM.MEDIA_GROUP ? l("dooh_planning_select_grouop_title_media", "选择线下资源包") :
        l("dooh_planning_select_grouop_title", "选择地址包")} visible={addressModalVisible} onCancel={function () { return setAddressModalVisible(false); }} onOk={onAddressModalSave} maskClosable={false}>
        <MySelect disabled={readonly} style={{ width: "100%" }} showSearch={true} mode={"multiple"} placeholder={l("dooh_planning_select_grouop_placeholder", "Select Group")} url={addressUrl} params={{
        cityIdList: formData.cityIdList,
        scenarioIdList: formData.scenarioIdList,
        advertiserId: formData.advertiserId
    }} value={addressModalData} onChange={function (v) { return setAddressModalData(v); }}/>
      </MyModal>
    </MySpin>);
};
var Title = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  position: relative;\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n\n  > i {\n    display: inline-block;\n    margin-right: 10px;\n    height: 16px;\n    width: 4px;\n    background-color: ", ";\n  }\n"], ["\n  margin-bottom: 10px;\n  position: relative;\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n\n  > i {\n    display: inline-block;\n    margin-right: 10px;\n    height: 16px;\n    width: 4px;\n    background-color: ", ";\n  }\n"])), MAIN_COLOR);
var IdxWeightWrapper = styled(FormItem)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 0 !important;\n\n  label {\n    color: #A3A3A3 !important;\n    font-size: 12px !important;\n  }\n\n  svg {\n    font-size: 16px !important;\n  }\n"], ["\n  margin-bottom: 0 !important;\n\n  label {\n    color: #A3A3A3 !important;\n    font-size: 12px !important;\n  }\n\n  svg {\n    font-size: 16px !important;\n  }\n"])));
var FlexWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var DescSelectTips = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: #aaa;\n  font-size: 12px;\n"], ["\n  color: #aaa;\n  font-size: 12px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
