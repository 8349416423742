var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
import { ACTION_COLUMNS, AUDIENCE_APPLY_OBJECT } from "@/common/commonAction";
import { STATUS_ID_ENUM } from "@/common/const";
import { TASK_NAME_WIDTH } from "@/common/styled";
export var TASK_TABS = function () { return [
    {
        key: "wait",
        title: l("workplace_wait_task", "待办事项")
    },
    {
        key: "lastCreate",
        title: l("workplace_last_task", "最近任务")
    },
    {
        key: "lastFinish",
        title: l("workplace_last_finish", "最近完成")
    },
    {
        key: "like",
        title: l("workplace_like_task", "关注任务")
    }
]; };
export var AUDIENCE_TABS = function () { return [
    {
        key: "lastCreate",
        title: l("workplace_last_create", "最近创建")
    },
    {
        key: "like",
        title: l("workplace_like_audience", "关注人群")
    },
    {
        key: "wait",
        title: l("workplace_wait_audience", "待用人群")
    },
]; };
export var columns = function () { return [
    {
        key: "taskName",
        title: l("common_task_name", "任务名称"),
        fixed: "left",
        render: [
            {
                key: "taskName",
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
                style: { fontSize: "15px" },
            },
            {
                key: "taskId",
                label: l("common_id", "ID") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "taskTypeName",
        title: l("common_task_type", "任务类型")
    },
    {
        key: "statusName",
        title: l("common_status", "状态"),
        render: [
            {
                key: "statusName",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "lastUpdate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
            },
        ]
    },
    {
        key: "operation",
        title: l("common_operation", "action"),
        fixed: "right",
        render: [
            {
                key: "likeItem",
                text: l("common_like", "关注"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
                hide: function (record) { return record.likeFlag; }
            },
            {
                key: "likeItem",
                text: l("common_cancel_like", "取消关注"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
                hide: function (record) { return !record.likeFlag; }
            },
            {
                key: "detailItem",
                type: TABLE_RENDER_TYPE_ENUM.APPLY,
                hide: function (record) { return !record.actionReport; },
            },
            {
                key: "editItem",
                text: l("common_edit", "edit"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
                hide: function (record) { return !record.actionEdit; }
            },
            {
                key: "addItem",
                text: l("common_add", "添加"),
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                hide: function (record) { return !record.actionAdd; },
                options: {
                    path: "/techHub/tracking/advertisingTracking/edit",
                    params: {
                        id: "taskId"
                    }
                },
            },
            {
                key: "delItem",
                text: l("common_del", "删除"),
                type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                hide: function (record) { return !record.actionCancel; },
                options: {
                    text: l("common_del_confirm", "确认删除") + "?"
                }
            },
            {
                key: "downloadItem",
                type: TABLE_RENDER_TYPE_ENUM.APPLY,
                hide: function (record) { return !record.actionDownload; }
            },
            {
                key: "more",
                type: TABLE_RENDER_TYPE_ENUM.MORE,
                render: __spreadArrays(AUDIENCE_APPLY_OBJECT(), ACTION_COLUMNS())
            },
        ]
    },
]; };
export var columns2 = function () { return [
    {
        key: "segmentName",
        title: l("common_audience_name", "人群名称"),
        fixed: "left",
        render: [
            {
                key: "segmentName",
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                style: { fontSize: "15px" },
                options: {
                    path: "/techHub/workplace/audienceDetail",
                    params: {
                        id: "segmentId"
                    }
                }
            },
            {
                key: "segmentId",
                label: l("common_id", "ID") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "advertiserName",
        title: l("common_advertiser", "广告主"),
    },
    {
        key: "dataSource",
        title: l("common_audience_source", "人群来源"),
    },
    {
        key: "lastUpdate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
            },
        ]
    },
    {
        key: "operation",
        title: l("common_operation", "action"),
        fixed: "right",
        render: [
            {
                key: "likeAudienceItem",
                text: l("common_like", "关注"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
                hide: function (record) { return record.likeFlag; }
            },
            {
                key: "likeAudienceItem",
                text: l("common_cancel_like", "取消关注"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
                hide: function (record) { return !record.likeFlag; }
            },
            {
                key: "more",
                type: TABLE_RENDER_TYPE_ENUM.MORE,
                render: __spreadArrays(AUDIENCE_APPLY_OBJECT(), ACTION_COLUMNS())
            },
        ]
    },
]; };
export var detailAudienceColumns = function () { return [
    {
        key: "lastUpdate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
            },
        ]
    },
    {
        key: "taskTypeName",
        title: l("common_task_type", "任务类型"),
    },
    {
        key: "taskName",
        title: l("common_task_name", "任务名称"),
        width: TASK_NAME_WIDTH,
        render: [
            {
                key: "taskName",
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
            }
        ]
    },
    {
        key: "statusName",
        title: l("common_status", "状态"),
        render: [
            {
                key: "statusName",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "operation",
        title: l("relative_audience", "相关人群"),
        fixed: "right",
        render: [
            {
                key: "audience",
                type: TABLE_RENDER_TYPE_ENUM.APPLY,
                hide: function (record) { return record.statusId !== STATUS_ID_ENUM.COMPLETED; }
            },
        ]
    },
]; };
