var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getImgUrl } from "@helpers/const";
import { MyEcharts } from "@components/echarts/myEcharts";
import { days10ChartOption, OTHER_MAP, slideBarOption } from "./helpers/initData";
import { NumberBoard } from "@pages/tv/platformDashboard/compenents/numberBoard";
import { px } from "@/utils/unit";
import { l } from "@helpers/multiLangaugeHelper";
import { getData, getTimeData } from "./helpers/async";
import numeral from "numeral";
import moment from "moment";
import { MySpin } from "@components/baseComponents/mySpin";
import { Empty } from "antd";
import { useParams } from "react-router-dom";
var TAB_CHANGE_TIME = 10000;
var DEFAULT_API_TIME = 60 * 1000;
var REFRESH_DATA_TIME = DEFAULT_API_TIME * 15;
var CHANGE_DATA_TIME = 1000;
var REFRESH_TIME_DATA_TIME = DEFAULT_API_TIME;
var dataTimer;
var allDataTimer;
var nowImpression;
var nowSpending;
var requestTime = moment().subtract(2, "seconds");
var showTipsFlag;
var Block = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (<BlockWrapper {...props}>
    <BorderIcon top={px(-2)} left={px(-2)} img={getImgUrl("tv/block_board1.png")}/>
    <BorderIcon top={px(-2)} right={px(-1)} img={getImgUrl("tv/block_board2.png")}/>
    <BorderIcon bottom={px(-4)} left={px(-2)} img={getImgUrl("tv/block_board3.png")}/>
    <BorderIcon bottom={px(-4)} right={px(-1)} img={getImgUrl("tv/block_board4.png")}/>
    {children}
  </BlockWrapper>);
};
var TAB_TIME = 0;
var TAB_TIME2 = 0;
export var PlatformDashboard = function () {
    var _a;
    var uid = useParams().uid;
    var _b = useState({}), data = _b[0], setData = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(0), tabIdx = _d[0], setTabIdx = _d[1];
    var _e = useState(0), tabIdx2 = _e[0], setTabIdx2 = _e[1];
    var _f = useState(), impression = _f[0], setImpression = _f[1];
    var _g = useState(), spending = _g[0], setSpending = _g[1];
    var _h = useState(false), showTips = _h[0], setShowTips = _h[1];
    var isForDetail = location.pathname.toLowerCase().indexOf("overviewfordetail") > -1;
    useEffect(function () {
        var ico = document.getElementById("ico");
        var icoUrl = "/objects/groupm/img/favicon/groupm.ico";
        ico.href = icoUrl;
        if (window.screen.width < 600) {
            var url = "/mt" + location.pathname;
            location.href = url;
        }
    }, []);
    useEffect(function () {
        var nowDate = moment().format("HH:mm:ss");
        if (nowDate === "00:00:00") {
            REFRESH_DATA_TIME = DEFAULT_API_TIME;
            showTipsFlag = true;
            setShowTips(true);
            removeTimer();
            setTimeout(function () {
                setData({});
                setImpression(0);
                nowImpression = 0;
                setSpending(0);
                nowSpending = 0;
                getTimeDataFunc();
                getInitData();
            }, 500);
        }
    }, [moment().format("HH:mm:ss")]);
    useEffect(function () {
        resizeBaseFontSize();
        window.addEventListener("resize", resizeWindow, false);
        window.addEventListener("focus", focusWindow, false);
        window.addEventListener("blur", removeTimer, false);
        if (dataTimer) {
            clearInterval(dataTimer);
            dataTimer = null;
        }
        getTimeDataFunc();
        getInitData();
        if (isForDetail) {
            setInterval(function () {
                getTabTime();
            }, TAB_CHANGE_TIME);
        }
    }, []);
    var removeTimer = function () {
        if (dataTimer) {
            clearInterval(dataTimer);
            dataTimer = null;
        }
        if (allDataTimer) {
            clearInterval(allDataTimer);
            allDataTimer = null;
        }
    };
    var resizeBaseFontSize = function () {
        var rootHtml = document.documentElement;
        var deviceWidth = window.innerWidth;
        var MIN_WIDTH = 1280;
        if (deviceWidth < 500) {
            rootHtml.style.fontSize = deviceWidth / MIN_WIDTH * 6 + "px";
        }
        else {
            rootHtml.style.fontSize = deviceWidth / MIN_WIDTH * 10 + "px";
        }
    };
    var focusWindow = function () {
        if (location.pathname.indexOf("/platformDashboard") > -1) {
            removeTimer();
            setTimeout(function () {
                getTimeDataFunc();
                getInitData();
            }, 500);
        }
        else {
            window.removeEventListener("focus", focusWindow);
        }
    };
    var resizeWindow = function () {
        if (location.pathname.indexOf("/platformDashboard") > -1) {
            resizeBaseFontSize();
        }
        else {
            window.removeEventListener("resize", resizeWindow);
        }
    };
    var getInitData = function () {
        getData(uid)
            .then(function (res) {
            setLoading(false);
            setData(res.data || {});
            if (allDataTimer) {
                clearInterval(allDataTimer);
                allDataTimer = null;
            }
            allDataTimer = setInterval(function () {
                getInitData();
            }, REFRESH_DATA_TIME);
        })
            .catch(function (err) {
            if (allDataTimer) {
                clearInterval(allDataTimer);
                allDataTimer = null;
            }
            setTimeout(function () {
                getInitData();
            }, 5000);
        });
    };
    var getTimeDataFunc = function () {
        if (moment().diff(requestTime, "seconds") < 0.5 && !showTipsFlag) {
            location.reload();
        }
        requestTime = moment();
        getTimeData(uid)
            .then(function (res) {
            var _a = res.data || {}, prevImpression = _a.prevImpression, nextImpression = _a.nextImpression, prevSpending = _a.prevSpending, nextSpending = _a.nextSpending, time = _a.time;
            if (prevImpression === 0 && nextImpression === 0 && !showTipsFlag) {
                REFRESH_DATA_TIME = DEFAULT_API_TIME;
                showTipsFlag = true;
                setShowTips(showTipsFlag);
                removeTimer();
                setTimeout(function () {
                    setData({});
                    setImpression(0);
                    nowImpression = 0;
                    setSpending(0);
                    nowSpending = 0;
                    getTimeDataFunc();
                    getInitData();
                }, 500);
                return;
            }
            if (nextImpression && showTipsFlag) {
                REFRESH_DATA_TIME = DEFAULT_API_TIME * 15;
                showTipsFlag = false;
                setShowTips(showTipsFlag);
            }
            var getTime = moment();
            var postTime = moment(time);
            var diffTime = getTime.diff(postTime);
            var diffTimes = REFRESH_TIME_DATA_TIME / CHANGE_DATA_TIME - 1;
            var diffValueImpression = (nextImpression - prevImpression) / REFRESH_TIME_DATA_TIME;
            var diffValueSpending = (nextSpending - prevSpending) / REFRESH_TIME_DATA_TIME;
            var diffAllImpression = diffValueImpression * diffTime;
            var diffAllSpending = diffValueSpending * diffTime;
            nowImpression = nowImpression ? Math.max(nowImpression, prevImpression + diffAllImpression) : (prevImpression + diffAllImpression);
            nowSpending = nowSpending ? Math.max(nowSpending, prevSpending + diffAllSpending) : (prevSpending + diffAllSpending);
            setImpression(nowImpression);
            setSpending(nowSpending);
            var diffImpression = (nextImpression + (diffValueImpression * diffTime) - nowImpression) / diffTimes;
            var diffSpending = (nextSpending + (diffValueSpending * diffTime) - nowSpending) / diffTimes;
            dataTimer = setInterval(function () {
                if (diffTimes === 0) {
                    clearInterval(dataTimer);
                    dataTimer = null;
                    getTimeDataFunc();
                }
                else {
                    if (diffImpression > 0) {
                        nowImpression += diffImpression;
                    }
                    if (diffSpending > 0) {
                        nowSpending += diffSpending;
                    }
                    setImpression(nowImpression);
                    setSpending(nowSpending);
                    diffTimes--;
                }
            }, CHANGE_DATA_TIME);
        })
            .catch(function (err) {
            if (dataTimer) {
                clearInterval(dataTimer);
                dataTimer = null;
            }
            setTimeout(function () {
                getTimeDataFunc();
            }, 5000);
        });
    };
    var getTabTime = function () {
        TAB_TIME = TAB_TIME === 2 ? 0 : TAB_TIME + 1;
        TAB_TIME2 = TAB_TIME2 === 2 ? 0 : TAB_TIME2 + 1;
        setTabIdx(TAB_TIME);
        setTabIdx2(TAB_TIME2);
    };
    var changeTabTime = function (idx, type) {
        if (type === "impression") {
            TAB_TIME = idx;
            setTabIdx(TAB_TIME);
        }
        else {
            TAB_TIME2 = idx;
            setTabIdx2(TAB_TIME2);
        }
    };
    var impressionChartsData = data === null || data === void 0 ? void 0 : data.impressionChartsData;
    var spendingChartsData = data === null || data === void 0 ? void 0 : data.spendingChartsData;
    var renderTrend = function (chartsData, type) {
        var idx = type === "impression" ? tabIdx : tabIdx2;
        return (<>
        <BlockTitle>
          {type === "impression" ?
            l("tv_impression_title_2", "Impression Trend") :
            l("tv_spending_title_2", "Spending Trend")}
          <TabBar>
            <TabItem active={idx === 0} onClick={function () { return changeTabTime(0, type); }}>{l("tv_10_days", "10 Days")}</TabItem>
            {isForDetail ? <TabItem active={idx === 1} onClick={function () { return changeTabTime(1, type); }}>
              {l("tv_agency", "Agency")}
            </TabItem> : null}
            {isForDetail ? <TabItem active={idx === 2} onClick={function () { return changeTabTime(2, type); }}>
              {l("tv_platform", "Platform")}
            </TabItem> : null}
          </TabBar>
        </BlockTitle>
        <ChartsWrapper>
          {idx === 0 && chartsData ?
            <MyEcharts option={days10ChartOption(chartsData.days10 || [], true)}/> : null}
          {idx === 1 && chartsData && chartsData.topAgency ?
            (chartsData.topAgency.length > 0 ? <MyEcharts option={slideBarOption(chartsData.topAgency)}/>
                : <Empty />) : null}
          {idx === 2 && chartsData && chartsData.topPlatform ?
            (chartsData.topPlatform.length > 0 ? <MyEcharts option={slideBarOption(chartsData.topPlatform)}/>
                : <Empty />) : null}
        </ChartsWrapper>
      </>);
    };
    return (<MySpin spinning={loading}>
      {showTips ? <Tips>
        <img alt={""} src={getImgUrl("tv/tv_tips.png")}/>
        {l("tv_dashboard_tips", "媒体平台数据清洗时段（0~1点），暂无数据，请稍后查看！")}
      </Tips> : null}
      <Wrapper>
        <Title>
          <Logo src={getImgUrl("tv/logo.png")} alt={"logo"}/>
          <TitleSpan>{(_a = data === null || data === void 0 ? void 0 : data.title) !== null && _a !== void 0 ? _a : l("tv_groupm_platform_title", "GroupM Performance  Platform Dashboard")}</TitleSpan>
          <Time>{moment().format("YYYY.MM.DD HH:mm")}</Time>
        </Title>
        <Content>
          <ContentWrapper>
            <LeftWrapper>
              <div>
                <NumberBoardTitle>{l("tv_impression_title", "Impression")}</NumberBoardTitle>
                <NumberBoard value={impression}/>
                <YesterdayData>{l("tv_yesterday", "Yesterday")}: {numeral(data.yesterdayImpression).format("0,0") || 0}</YesterdayData>
                <YesterdayData>{l("tv_average_cpm", "Average CPM")}: <span>¥</span>{numeral(data.averageCPM).format("0,0.00") || 0}
                </YesterdayData>
              </div>
              <div>
                <NumberBoardTitle>{l("tv_spending_title", "Spending")}(¥)</NumberBoardTitle>
                <NumberBoard value={spending}/>
                <YesterdayData>{l("tv_yesterday", "yesterday")}: {numeral(data.yesterdaySpending || 0).format("0,0")}</YesterdayData>
                <YesterdayData>{l("tv_average_daily_spending", "Average Daily Spending")}: {numeral(data.averageDailySpending || 0).format("0,0")}</YesterdayData>
                <TipText>{data.tipText || ""}</TipText>
              </div>
            </LeftWrapper>
            <RightWrapper>
              <ImpressionCharts>
                {renderTrend(impressionChartsData, "impression")}
              </ImpressionCharts>
              <SpendingCharts>
                {renderTrend(spendingChartsData, "spending")}
              </SpendingCharts>
            </RightWrapper>
          </ContentWrapper>
          <Other>
            <BlockTitle>{l("tv_other_title", "Others(Compared with yesterday)")}</BlockTitle>
            {data.other ? <OtherContent>
              {OTHER_MAP().map(function (item) { return (<div>
                {item.label}:
                <span>
                {numeral(data.other[item.key]).format("0,0") || 0}
                  {data.other[item.key + "Diff"] === 0 ? <LineIcon>--</LineIcon> :
        <UpDownIcon src={getImgUrl("tv/tv_" + (data.other[item.key + "Diff"] > 0 ? "up" : "down") + ".png")}/>}
                  <DiffSpan>
                  {data.other[item.key + "Diff"] === 0 ? null :
        <span>{data.other[item.key + "Diff"] > 0 ? "+" : ""}{numeral(data.other[item.key + "Diff"]).format("0,0")}</span>}
                  </DiffSpan>
                </span>
              </div>); })}
            </OtherContent> : null}
          </Other>
        </Content>
      </Wrapper>
    </MySpin>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 ", ";\n  position: relative;\n  min-height: 100vh;\n  color: #fff;\n  background: linear-gradient(90deg, #22258a 0%, #0A1063 30%, #0A1063 70%,#22258a 100%);\n"], ["\n  padding: 0 ", ";\n  position: relative;\n  min-height: 100vh;\n  color: #fff;\n  background: linear-gradient(90deg, #22258a 0%, #0A1063 30%, #0A1063 70%,#22258a 100%);\n"])), px(30));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: ", ";\n  background: url(", ") 100% no-repeat;\n  background-size: 100%;\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: ", ";\n  background: url(", ") 100% no-repeat;\n  background-size: 100%;\n"])), px(70), getImgUrl("tv/header.png"));
var TitleSpan = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  font-size: ", ";\n"], ["\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  font-size: ", ";\n"])), px(20));
var Logo = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: ", ";\n"], ["\n  height: ", ";\n"])), px(30));
var Time = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), px(22));
var Content = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: ", ";\n  display: flex;\n  flex-direction: column;\n  min-height: calc(100vh - ", ");\n"], ["\n  margin-top: ", ";\n  display: flex;\n  flex-direction: column;\n  min-height: calc(100vh - ", ");\n"])), px(26), px(112));
var ContentWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  flex: 1;\n"])));
var LeftWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: ", ";\n  > div {\n    padding: 0 ", ";\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n  > div:nth-child(1){\n    margin-bottom: ", ";\n    height: calc(50% - ", ");\n  }\n  > div:nth-child(2){\n    height: 50%;\n  }\n"], ["\n  width: ", ";\n  > div {\n    padding: 0 ", ";\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n  > div:nth-child(1){\n    margin-bottom: ", ";\n    height: calc(50% - ", ");\n  }\n  > div:nth-child(2){\n    height: 50%;\n  }\n"])), px(570), px(16), px(20), px(20));
var NumberBoardTitle = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), px(24));
var RightWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: calc(100% - ", ");\n"], ["\n  width: calc(100% - ", ");\n"])), px(570));
var BlockWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: relative;\n  padding: ", " ", ";\n  border: ", " solid rgba(114, 112, 184, 0.8);\n"], ["\n  position: relative;\n  padding: ", " ", ";\n  border: ", " solid rgba(114, 112, 184, 0.8);\n"])), px(10), px(16), px(1));
var Other = styled(Block)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  height: ", ";\n  margin: ", " 0 0 0;\n"], ["\n  height: ", ";\n  margin: ", " 0 0 0;\n"])), px(100), px(22));
var ImpressionCharts = styled(Block)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  padding-bottom: 0;\n  height: calc(50% - ", ");\n"], ["\n  margin-bottom: ", ";\n  padding-bottom: 0;\n  height: calc(50% - ", ");\n"])), px(20), px(20));
var SpendingCharts = styled(Block)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  padding-bottom: 0;\n  height: 50%;\n"], ["\n  padding-bottom: 0;\n  height: 50%;\n"])));
var BorderIcon = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 2;\n  top: ", ";\n  left: ", ";\n  right: ", ";\n  bottom: ", ";\n  width: ", ";\n  height: ", ";\n  background: url(", ") no-repeat;\n  background-size: 100%;\n"], ["\n  position: absolute;\n  z-index: 2;\n  top: ", ";\n  left: ", ";\n  right: ", ";\n  bottom: ", ";\n  width: ", ";\n  height: ", ";\n  background: url(", ") no-repeat;\n  background-size: 100%;\n"])), function (props) { return props.top; }, function (props) { return props.left; }, function (props) { return props.right; }, function (props) { return props.bottom; }, px(20), px(18), function (props) { return props.img; });
var BlockTitle = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  overflow: hidden;\n  height: ", ";\n  line-height: ", ";\n  font-size: ", ";\n"], ["\n  overflow: hidden;\n  height: ", ";\n  line-height: ", ";\n  font-size: ", ";\n"])), px(28), px(28), px(20));
var ChartsWrapper = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100% - ", ");\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100% - ", ");\n"])), px(28));
var YesterdayData = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), px(18));
var TipText = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-size: ", ";\n  font-style: italic;\n"], ["\n  font-size: ", ";\n  font-style: italic;\n"])), px(12));
var OtherContent = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin-top: ", ";\n  display: flex;\n  align-items: center;\n  > div {\n    display: flex;\n    align-items: center;\n    flex: 1;\n    font-size: ", ";\n    > span {\n      margin-left: ", ";\n      font-size: ", ";\n    }\n  }\n"], ["\n  margin-top: ", ";\n  display: flex;\n  align-items: center;\n  > div {\n    display: flex;\n    align-items: center;\n    flex: 1;\n    font-size: ", ";\n    > span {\n      margin-left: ", ";\n      font-size: ", ";\n    }\n  }\n"])), px(14), px(16), px(10), px(20));
var DiffSpan = styled.span(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  margin-left: ", ";\n  font-size: ", ";\n"], ["\n  margin-left: ", ";\n  font-size: ", ";\n"])), px(4), px(12));
var UpDownIcon = styled.img(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  display: inline-block;\n  margin-left: ", ";\n  height: ", ";\n  width: ", ";\n"], ["\n  display: inline-block;\n  margin-left: ", ";\n  height: ", ";\n  width: ", ";\n"])), px(6), px(16), px(14));
var LineIcon = styled.span(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  margin-left: ", ";\n  color: #f5e35a;\n"], ["\n  margin-left: ", ";\n  color: #f5e35a;\n"])), px(4));
var TabBar = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  float: right;\n  overflow: hidden;\n  display: inline-flex;\n  justify-content: flex-end;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  font-size: ", ";\n"], ["\n  float: right;\n  overflow: hidden;\n  display: inline-flex;\n  justify-content: flex-end;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  font-size: ", ";\n"])), px(350), px(24), px(4), px(12));
var TabItem = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  flex: 1;\n  max-width: 34%;\n  height: 100%;\n  text-align: center;\n  background-color: ", ";\n"], ["\n  flex: 1;\n  max-width: 34%;\n  height: 100%;\n  text-align: center;\n  background-color: ", ";\n"])), function (props) { return props.active ? "#38C0C1" : "rgba(56, 192, 193, 0.34)"; });
var Tips = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 999;\n  padding: 14px 20px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: linear-gradient(90deg, #22258a 0%, #22229D 100%);\n  border: 1px solid #8181D4;\n  box-shadow: 0 0 8px #333;\n  color: #fff;\n  border-radius: 4px;\n  font-size: ", ";\n  > img {\n    margin-right: ", ";\n    width: ", ";\n    height: ", ";\n  }\n"], ["\n  position: absolute;\n  z-index: 999;\n  padding: 14px 20px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: linear-gradient(90deg, #22258a 0%, #22229D 100%);\n  border: 1px solid #8181D4;\n  box-shadow: 0 0 8px #333;\n  color: #fff;\n  border-radius: 4px;\n  font-size: ", ";\n  > img {\n    margin-right: ", ";\n    width: ", ";\n    height: ", ";\n  }\n"])), px(20), px(16), px(22), px(22));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26;
