var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
import { CONTENT_DESC } from "@pages/welcome/helpers/initData";
import { getImgUrl } from "@helpers/const";
export var DemoWelcome = function () {
    var ico = document.getElementById("ico");
    var icoUrl = "/objects/groupm/img/favicon/groupm.ico";
    ico.href = icoUrl;
    return (<Wrapper>
    <div>
      <Title>{l("demo_welcome_title", "Welcome To Choreograph OS！")}</Title>
      <SubTitle>{l("demo_welcome_subTitle", "we are groupm data and service middle platform")}</SubTitle>
      <Content>
        {CONTENT_DESC().map(function (item) { return (<BlockItem>
            <div className={"title"}>
              <Circle color1={item.circleColor[0]} color2={item.circleColor[1]}/>
              {item.title}
            </div>
            <div className={"desc"}>{item.desc}</div>
          </BlockItem>); })}
      </Content>
      <BgImg src={getImgUrl("demo/demo_welcome.png")}/>
    </div>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 16px;\n  padding: 80px;\n  height: calc(100% - 32px);\n  min-height: 760px;\n  background: #fff;\n"], ["\n  margin: 16px;\n  padding: 80px;\n  height: calc(100% - 32px);\n  min-height: 760px;\n  background: #fff;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 12px;\n  font-size: 28px;\n  font-weight: bold;\n"], ["\n  margin-left: 12px;\n  font-size: 28px;\n  font-weight: bold;\n"])));
var SubTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 12px;\n  font-size: 24px;\n  font-weight: lighter;\n"], ["\n  margin-left: 12px;\n  font-size: 24px;\n  font-weight: lighter;\n"])));
var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var BlockItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  z-index: 2;\n  margin-top: 64px;\n  padding: 16px 16px 16px 56px;\n  width: 780px;\n  border: 2px solid #F9F9F9;\n  border-radius: 8px;\n  background: #fff;\n\n  .title {\n    position: relative;\n    font-size: 18px;\n  }\n\n  .desc {\n    font-size: 16px;\n    color: #616161;\n  }\n"], ["\n  position: relative;\n  z-index: 2;\n  margin-top: 64px;\n  padding: 16px 16px 16px 56px;\n  width: 780px;\n  border: 2px solid #F9F9F9;\n  border-radius: 8px;\n  background: #fff;\n\n  .title {\n    position: relative;\n    font-size: 18px;\n  }\n\n  .desc {\n    font-size: 16px;\n    color: #616161;\n  }\n"])));
var Circle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 8px;\n  left: -28px;\n  height: 12px;\n  width: 12px;\n  background: ", ";\n  border-radius: 100%;\n  border: 2px solid ", ";\n"], ["\n  position: absolute;\n  top: 8px;\n  left: -28px;\n  height: 12px;\n  width: 12px;\n  background: ", ";\n  border-radius: 100%;\n  border: 2px solid ", ";\n"])), function (props) { return props.color1; }, function (props) { return props.color2; });
var BgImg = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  width: 24%;\n  top: 330px;\n  right: 90px;\n"], ["\n  position: absolute;\n  width: 24%;\n  top: 330px;\n  right: 90px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
