var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { ChartsRender } from "@pages/profiling/components/chartsRender/chartsRender";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { msgError } from "@helpers/axiosHelper";
import { map, merge, uniqBy } from "lodash";
import { getDashboardChartList, getDashboardMenu } from "@pages/profiling/profile/manage/helpers/async";
import styled from "styled-components";
import { HEADER_HEIGHT } from "@components/contentWrapper/contentWrapper";
import { CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
import { CommonWrapper } from "@components/common/commonWrapper";
import { l } from "@helpers/multiLangaugeHelper";
import { MySpin } from "@components/baseComponents/mySpin";
var TabPane = Tabs.TabPane;
var nowKey;
export var ProfilingDashboard = function () {
    var type = useParams().type;
    var id = Number(queryString.parse(location.search).id);
    var _a = useState([]), nav = _a[0], setNav = _a[1];
    var _b = useState([]), chartList = _b[0], setChartList = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(""), navKey = _d[0], setNavKey = _d[1];
    var _e = useState(), sourceName = _e[0], setSourceName = _e[1];
    var _f = useState({}), navListData = _f[0], setNavListData = _f[1];
    var profileType = useParams().profileType;
    useEffect(function () {
        getDashboardMenu({ id: id, type: type })
            .then(function (res) {
            if (res.data.list) {
                setNav(res.data.list);
                nowKey = null;
                onNavChange("" + res.data.list[0].navId);
            }
            else {
                setLoading(false);
            }
            setSourceName(res.data.sourceName);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    }, []);
    var onNavChange = function (key) {
        if (key === navKey)
            return;
        nowKey = key;
        setNavKey(key);
        if (navListData[key]) {
            setLoading(true);
            setChartList(navListData[key]);
            setLoading(false);
            return;
        }
        setLoading(true);
        getDashboardChartList({ id: id, type: type, navId: Number(key) })
            .then(function (res) {
            if ((!navKey && !nowKey) || nowKey === key) {
                setChartList(res.data);
                setLoading(false);
            }
            changeNavFlag(key, res.data);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var changeNavFlag = function (key, data) {
        var newListData = merge({}, navListData);
        newListData[key] = data;
        setNavListData(newListData);
    };
    var renderComparisonWrapper = function () {
        var typeList = chartList && chartList.length > 0 ? uniqBy(chartList[0].data, "type") : [];
        if (typeList.length === 0) {
            return null;
        }
        return (<ComparisonLegendWrapper>
        {map(typeList, function (item, idx) { return <ComparisonLegendItem>
          <ColorBlock color={CHARTS_DEFAULT_COLOR_LIST[idx]}/>{item.type}
        </ComparisonLegendItem>; })}
      </ComparisonLegendWrapper>);
    };
    return (<>
      <TabWrapper>
        <Tabs activeKey={navKey} onChange={onNavChange}>
          {map(nav, function (item) { return <TabPane key={"" + item.navId} tab={item.title}/>; })}
        </Tabs>
      </TabWrapper>
      <CommonWrapper>
        {type === "segment" ? null : renderComparisonWrapper()}
        <MySpin spinning={loading}>
          <Wrapper>
            <ChartsRender data={chartList}/>
          </Wrapper>
        </MySpin>
        {sourceName ? <SourceTip>{l("profiling_dashboard_source_from_1", "*次数据来源于")} {sourceName}</SourceTip> : null}
      </CommonWrapper>
    </>);
};
var ComparisonLegendWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 10;\n  position: fixed;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  right: 56px;\n  top: ", "px;\n  padding: 10px;\n  background-color: rgba(255,255,255,0.4);\n  box-shadow: 0 0 4px #ddd;\n"], ["\n  z-index: 10;\n  position: fixed;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  right: 56px;\n  top: ", "px;\n  padding: 10px;\n  background-color: rgba(255,255,255,0.4);\n  box-shadow: 0 0 4px #ddd;\n"])), HEADER_HEIGHT + 70);
var ComparisonLegendItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin:0 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  line-height: 18px;\n  height: 18px;\n"], ["\n  margin:0 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  line-height: 18px;\n  height: 18px;\n"])));
var ColorBlock = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 10px;\n  display: inline-block;\n  height: 18px;\n  width: 18px;\n  background-color: ", ";\n"], ["\n  margin-right: 10px;\n  display: inline-block;\n  height: 18px;\n  width: 18px;\n  background-color: ", ";\n"])), function (props) { return props.color; });
var Wrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: hidden;\n"], ["\n  overflow: hidden;\n"])));
var TabWrapper = styled(CommonWrapper)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 0;\n  overflow: hidden;\n  padding: 0 24px;\n"], ["\n  margin-bottom: 0;\n  overflow: hidden;\n  padding: 0 24px;\n"])));
var SourceTip = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: 28px;\n  font-size: 12px;\n  color: #aaa;\n"], ["\n  margin-left: 28px;\n  font-size: 12px;\n  color: #aaa;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
