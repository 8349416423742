import { post } from "@helpers/axiosHelper";
export var getList = function (data) {
    return post("/groupme/audex/audience/appAudience/getAppList", data);
};
export var getDetail = function (taskId) {
    return post("/groupme/audex/audience/appAudience/getDetail", { taskId: taskId });
};
export var saveApi = function (data) {
    return post("/groupme/audex/audience/appAudience/save", data);
};
