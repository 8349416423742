import * as React from "react";
import { useEffect, useState } from "react";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MySpin } from "@components/baseComponents/mySpin";
import { MyFormWrapper, MyItemWrapper } from "@components/common/myFormWrapper";
import { MyFormItem } from "@components/common/myFormItem";
import { l } from "@helpers/multiLangaugeHelper";
import { MyTable } from "@components/myTable/myTable";
import { columns } from "./helpers/initData";
import queryString from "query-string";
import { getDetail } from "./helpers/async";
import { msgError } from "@helpers/axiosHelper";
export var TrackingAudienceEdit = function () {
    var id = queryString.parse(location.search).id;
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState({}), data = _b[0], setData = _b[1];
    useEffect(function () {
        getDetail(Number(id))
            .then(function (res) {
            setData(res.data);
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    }, []);
    var tableProps = {
        data: data.trackingList,
        columns: columns(),
        page: false,
        loading: false,
    };
    return (<MySpin spinning={loading}>
      <CommonWrapper>
        <MyFormWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_audience_name", "人群名称")}>
              {data.segmentName}
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("tracking_audience_relative_campaign", "关联活动")}>
              {data.campaignName}
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_audience_size", "人群规模")}>
              {data.audienceSize}
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("advertising_tracking_dashboard_listener_type", "事件类型")}>
              {data.eventTypeName}
            </MyFormItem>
          </MyItemWrapper>
          <MyItemWrapper>
            <MyFormItem required label={l("common_memo", "备注")}>
              {data.memo}
            </MyFormItem>
          </MyItemWrapper>
        </MyFormWrapper>
        <MyTable {...tableProps}/>
      </CommonWrapper>
    </MySpin>);
};
