var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import MapComponent from "./components/mapComponent";
import { getMapConfig } from "./helpers/async";
import { msgError } from "@helpers/axiosHelper";
import queryString from "query-string";
export var DoohPlanningDetail = function () {
    var id = queryString.parse(location.search).id;
    var _a = useState(null), mapConfig = _a[0], setMapConfig = _a[1];
    useEffect(function () {
        getMapConfig(Number(id))
            .then(function (res) {
            setMapConfig(__assign(__assign({}, res.data), { id: Number(id) }));
        })
            .catch(msgError);
    }, []);
    return (<Wrapper>
    {mapConfig ? <Map config={mapConfig}/> : null}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
var Map = styled(MapComponent)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
var templateObject_1, templateObject_2;
