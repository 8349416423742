var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { l } from "@helpers/multiLangaugeHelper";
import styled from "styled-components";
import { MyTable } from "@components/myTable/myTable";
import { map, merge } from "lodash";
import { msgError, msgInfo, msgSuccess } from "@helpers/axiosHelper";
import { MAIN_COLOR } from "@/common/styled";
import { AUDIENCE_TABS, columns, columns2, TASK_TABS } from "./helpers/initData";
import { deleteApi, getInit, getList, getList2, likeAudienceApi, likeTaskApi } from "./helpers/async";
import { useHistory } from "react-router-dom";
import { ACTION_CODE_ENUM, STATUS_ID_ENUM, STATUS_OPTIONS_TYPE } from "@/common/const";
import { getAudienceApplyObject, getTaskDetailOrEditUrlMap, updateStatus } from "@/common/commonAction";
import { BatchSyncExportByUrl, EXPORT_URL_TYPE_LIST } from "@components/batchExportByUrl/batchSyncExportByUrl";
import { BatchSyncExport, EXPORT_TYPE_LIST } from "@components/batchExport/batchSyncExport";
import { getImgUrl } from "@helpers/const";
import { Badge, Tooltip } from "antd";
import moment from "moment";
import { getPageDomPermission } from "@components/routerInterceptor/helpers/async";
import { TableWrapper } from "@components/common/tableWrapper";
import { MyTableSearch } from "@components/baseComponents/myTableSearch";
export var MainDashboard = function () {
    var taskProgressTabs = TASK_TABS();
    var audienceTabs = AUDIENCE_TABS();
    var history = useHistory();
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState({ type: taskProgressTabs[0].key }), conditions = _c[0], setConditions = _c[1];
    var _d = useState(), fixed = _d[0], setFixed = _d[1];
    var _e = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), page = _e[0], setPage = _e[1];
    var _f = useState([]), data2 = _f[0], setData2 = _f[1];
    var _g = useState(false), loading2 = _g[0], setLoading2 = _g[1];
    var _h = useState({ type: audienceTabs[0].key }), conditions2 = _h[0], setConditions2 = _h[1];
    var _j = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), page2 = _j[0], setPage2 = _j[1];
    var _k = useState(0), taskProgressIdx = _k[0], setTaskProgressIdx = _k[1];
    var _l = useState(0), audienceIdx = _l[0], setAudienceIdx = _l[1];
    var _m = useState({ taskNumber: 0, lastUsedList: [], guideNumber: 0, caseNumber: 0 }), initData = _m[0], setInitData = _m[1];
    var _o = useState(false), hideDocLink = _o[0], setHideDocLink = _o[1];
    useEffect(function () {
        var content = document.getElementById("content");
        if (content) {
            content.onscroll = function () {
                if (content.scrollTop > 284) {
                    setFixed(true);
                }
                else {
                    setFixed(false);
                }
            };
        }
        return function () {
            if (location.pathname !== "/techHub/workplace/index") {
                if (content) {
                    content.onscroll = null;
                }
            }
        };
    }, []);
    useEffect(function () {
        onSearch(conditions, page);
        onSearch2(conditions2, page2);
        getInit()
            .then(function (res) {
            setInitData(res.data);
        })
            .catch(msgError);
    }, []);
    useEffect(function () {
        getPageDomPermission(location.pathname)
            .then(function (res) {
            setHideDocLink(res.data.hideDocLink);
        })
            .catch(msgError);
    }, []);
    var onSearch = function (conditions, page) {
        var data = __assign({ conditions: conditions }, page);
        setConditions(conditions);
        setLoading(true);
        getList(data)
            .then(function (res) {
            setData(res.data.list);
            setPage(merge({}, page, { total: res.data.total }));
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var onSearch2 = function (conditions, page) {
        var data = __assign({ conditions: conditions }, page);
        setConditions2(conditions);
        setLoading2(true);
        getList2(data)
            .then(function (res) {
            setData2(res.data.list);
            setPage2(merge({}, page, { total: res.data.total }));
            setLoading2(false);
        })
            .catch(function (err) {
            setLoading2(false);
            msgError(err);
        });
    };
    var onTaskSearch = function (idx) {
        setTaskProgressIdx(idx);
        onSearch(merge({}, conditions, { type: taskProgressTabs[idx].key }), merge({}, page, { current: 1 }));
    };
    var onAudienceSearch = function (idx) {
        setAudienceIdx(idx);
        onSearch2(merge({}, conditions2, { type: audienceTabs[idx].key }), merge({}, page2, { current: 1 }));
    };
    var likeTask = function (record) {
        likeTaskApi(__assign(__assign({}, record), { likeFlag: !record.likeFlag }))
            .then(function (res) {
            msgSuccess(res);
            onSearch(conditions, page);
        })
            .catch(msgError);
    };
    var likeAudience = function (record) {
        likeAudienceApi(__assign(__assign({}, record), { likeFlag: !record.likeFlag }))
            .then(function (res) {
            msgSuccess(res);
            onSearch(conditions, page);
        })
            .catch(msgError);
    };
    var taskToDetailOrEdit = function (record, type) {
        if (!record.taskType)
            msgInfo("no type");
        var urlMap = getTaskDetailOrEditUrlMap(type, record);
        history.push(urlMap[record.taskType]);
    };
    var renderDownload = function (record) {
        var _a, _b, _c;
        switch (record.taskType) {
            case STATUS_OPTIONS_TYPE.TRACKING_ADVERTISING_TRACKING:
            case STATUS_OPTIONS_TYPE.CORRELATION_ANALYSIS:
            case STATUS_OPTIONS_TYPE.DOOH_PLANNING:
            case STATUS_OPTIONS_TYPE.ACTIVATION:
                var downloadType = (_a = {},
                    _a[STATUS_OPTIONS_TYPE.TRACKING_ADVERTISING_TRACKING] = EXPORT_TYPE_LIST.TRACKING_ADVERTISING_TRACKING_REPORT_EXPORT,
                    _a[STATUS_OPTIONS_TYPE.CORRELATION_ANALYSIS] = EXPORT_TYPE_LIST.CORRELATION_ANALYSIS_EXPORT,
                    _a[STATUS_OPTIONS_TYPE.DOOH_PLANNING] = EXPORT_TYPE_LIST.DOOH_PLANNING_LIST_EXPORT,
                    _a[STATUS_OPTIONS_TYPE.ACTIVATION] = EXPORT_TYPE_LIST.ACTIVATION_ACTIVATE_MANAGE_SECRET_KEY_EXPORT,
                    _a);
                if (record.taskType === STATUS_OPTIONS_TYPE.ACTIVATION && !record.actionDownload)
                    return null;
                if (record.taskType === STATUS_OPTIONS_TYPE.ACTIVATION || record.taskType === STATUS_OPTIONS_TYPE.TRACKING_ADVERTISING_TRACKING || record.taskType === STATUS_OPTIONS_TYPE.DOOH_PLANNING) {
                    return (<BatchSyncExport style={{ marginRight: "10px", float: "left" }} url={downloadType[record.taskType]} btnType={"href"} params={record}/>);
                }
                return (<BatchSyncExport style={{ marginRight: "10px", float: "left" }} type={downloadType[record.taskType]} btnType={"href"} params={record}/>);
            case STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING:
            case STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE:
                // case STATUS_OPTIONS_TYPE.ACTIVATION:
                var downloadByUrlType = (_b = {},
                    _b[STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE] = EXPORT_URL_TYPE_LIST.MODELING_LOOKALIKE,
                    _b[STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING] = EXPORT_URL_TYPE_LIST.MODELING_CUSTOM_MODELING,
                    _b);
                // if (record.taskType === STATUS_OPTIONS_TYPE.ACTIVATION && !record.actionDownload) return null
                return (<BatchSyncExportByUrl style={{ marginRight: "10px", float: "left" }} type={downloadByUrlType[record.taskType]} btnType={"href"} disabled={record.statusId !== STATUS_ID_ENUM.COMPLETED} params={record}/>);
            case STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE:
            case STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE:
            case STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE:
                var profileType = (_c = {},
                    _c[STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE] = "mprofile",
                    _c[STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE] = "mhprofile",
                    _c[STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE] = "sprofile",
                    _c);
                return (<BatchSyncExport style={{ marginRight: "10px", float: "left" }} url={EXPORT_TYPE_LIST.PROFILING_SEGMENT_EXPORT} btnType={"href"} disabled={record.statusId !== STATUS_ID_ENUM.COMPLETED} params={__assign(__assign({}, record), { profileType: profileType[record.taskType] })}/>);
            default:
                return null;
        }
    };
    var getTaskActionApplyObject = function (idKey, callback) {
        return {
            submitItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.SUBMIT, record.taskType, idKey, callback); },
            approveItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.APPROVE, record.taskType, idKey, callback); },
            rejectItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.REJECT, record.taskType, idKey, callback); },
            receiveItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.RECEIVE, record.taskType, idKey, callback); },
            receiveApproveItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.RECEIVE_APPROVE, record.taskType, idKey, callback); },
            uploadResultItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.UPLOAD_RESULT, record.taskType, idKey, callback); },
            finishItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.FINISH, record.taskType, idKey, callback); },
            withdrawItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.WITHDRAW, record.taskType, idKey, callback); },
            rerunItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.RERUN, record.taskType, idKey, callback); },
        };
    };
    var delItem = function (record) {
        deleteApi(record)
            .then(function (res) {
            msgSuccess(res);
            onSearch(conditions, page);
        })
            .catch(msgError);
    };
    var renderDetail = function (record) {
        var _a;
        var urlMap = (_a = {},
            _a[STATUS_OPTIONS_TYPE.MODELING_CLUSTER] = "/techHub/modeling/clustering/dashboard",
            _a[STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE] = "/techHub/profiling/mprofile/dashboard/segment",
            _a[STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE] = "/techHub/profiling/mhprofile/dashboard/segment",
            _a[STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE] = "/techHub/profiling/sprofile/dashboard/segment",
            _a[STATUS_OPTIONS_TYPE.TRACKING_ADVERTISING_TRACKING] = "/techHub/tracking/advertisingTracking/dashboard",
            _a[STATUS_OPTIONS_TYPE.CORRELATION_ANALYSIS] = "/techHub/correlationAnalysis/detail",
            _a[STATUS_OPTIONS_TYPE.DOOH_PLANNING] = "/techHub/doohPlanning/detail",
            _a[STATUS_OPTIONS_TYPE.DTREE] = "/techHub/decisionTree/dashboard",
            _a[STATUS_OPTIONS_TYPE.COPILOT_PRE_CAMPAIGN_TASK] = "/techHub/copilot/preCampaignTask/detail",
            _a);
        return (<a style={{ marginRight: "10px", float: "left" }} onClick={function () { return history.push(urlMap[record.taskType] + "?id=" + record.taskId); }}>
      {l("common_operation_detail", "detail")}
    </a>);
    };
    var applyObject = __assign(__assign({ taskName: function (record) { return taskToDetailOrEdit(record, "view"); }, editItem: function (record) { return taskToDetailOrEdit(record, "edit"); }, likeItem: likeTask, delItem: delItem, detailItem: renderDetail, likeAudienceItem: likeAudience, downloadItem: renderDownload }, getTaskActionApplyObject("taskId", function () { return onSearch(conditions, page); })), getAudienceApplyObject());
    var tableProps = {
        data: data,
        columns: columns(),
        page: page,
        loading: loading,
        onChange: function (page) { return onSearch(conditions, page); },
        applyObject: applyObject,
    };
    var tableProps2 = {
        data: data2,
        columns: columns2(),
        page: page2,
        loading: loading2,
        onChange: function (page) { return onSearch2(conditions2, page); },
        applyObject: applyObject,
    };
    var getTimeText = function () {
        var hour = Number(moment().format("HH"));
        if (hour <= 12 && hour > 6) {
            return l("timeText_morning", "Good Morning");
        }
        if (hour > 12 && hour < 18) {
            return l("timeText_afternoon", "Good Afternoon");
        }
        return l("timeText_night", "Good Night");
    };
    var goReportPage = function (pathKey) {
        history.push("/techHub/report/mediaAnalysisReport/manage?filter=" + pathKey);
    };
    return (<Wrapper>
    <BannerWrapper>
      <Title>
        {getTimeText()}
      </Title>
      <GuideCase>
        {hideDocLink ? null : <Badge count={initData.guideNumber > 99 ? "99+" : initData.guideNumber} size={"small"}>
          <span onClick={function () { return goReportPage("guide"); }}>{l("workplace_task_platformGuide_text", "平台指南")}</span>
        </Badge>}
        {hideDocLink ? null : <Badge count={initData.caseNumber > 99 ? "99+" : initData.caseNumber} size={"small"}>
          <span onClick={function () { return goReportPage("case"); }}>{l("workplace_task_caseGuide_text", "案例分享")}</span>
        </Badge>}
      </GuideCase>
      <img alt={""} src={getImgUrl("workplace_bg.png")}/>
    </BannerWrapper>
    <TitleWrapper>
      <SubTitle>{l("workplace_task_progress", "任务进度")}</SubTitle>
      <TabWrapper idx={taskProgressIdx}>
        {taskProgressTabs.map(function (item, idx) { return (<TabItem key={item.key} onClick={function () { return onTaskSearch(idx); }}>
            {idx === 0 ? <Badge count={initData.taskNumber > 99 ? "99+" : initData.taskNumber} size={"small"}>
              <span>{item.title}</span>
            </Badge> : item.title}
          </TabItem>); })}
      </TabWrapper>
    </TitleWrapper>
    <TableWrapper style={{ marginTop: 0 }}>
      <MyTableSearch GAActionKey={"tasklistsearch"} placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onSearch(merge(conditions, { keyword: value }), merge({}, page, { current: 1 })); }}/>
      <MyTable {...tableProps}/>
    </TableWrapper>
    {initData.hideAsset ? null : <>
      <TitleWrapper>
        <SubTitle>{l("workplace_audience_price", "人群资产")}</SubTitle>
        <TabWrapper idx={audienceIdx}>
          {audienceTabs.map(function (item, idx) { return (<TabItem key={item.key} onClick={function () { return onAudienceSearch(idx); }}>{item.title}</TabItem>); })}
        </TabWrapper>
      </TitleWrapper>
      <TableWrapper style={{ marginTop: 0 }}>
        <MyTableSearch GAActionKey={"segamentlistsearch"} placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onSearch2(merge(conditions2, { keyword: value }), merge({}, page2, { current: 1 })); }}/>
        <MyTable {...tableProps2}/>
      </TableWrapper>
    </>}
    {initData.hideHistory ? null : <>
      <TitleWrapper>
        <SubTitle>{l("workplace_history_url", "历史记录")}</SubTitle>
      </TitleWrapper>
      <TableWrapper style={{ marginTop: 0 }}>
        <HistoryUrlWrapper>{map(initData.lastUsedList, function (item) {
        return <HistoryUrl onClick={function () { return history.push(item.url); }}>
            <Tooltip title={item.name}>
              {item.name}
            </Tooltip>
          </HistoryUrl>;
    })}
        </HistoryUrlWrapper>
      </TableWrapper>
    </>}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n"], ["\n  overflow: hidden;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  left: 56px;\n  top: 40px;\n  font-size: 28px;\n"], ["\n  position: absolute;\n  left: 56px;\n  top: 40px;\n  font-size: 28px;\n"])));
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 20px 36px 0 36px;\n"], ["\n  margin: 20px 36px 0 36px;\n"])));
var SubTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  font-size: 18px;\n"], ["\n  margin-bottom: 10px;\n  font-size: 18px;\n"])));
var TabWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  margin-bottom: 10px;\n  color: #95aac9;\n\n  &:after {\n    position: absolute;\n    content: \"\";\n    bottom: 0;\n    left: ", "px;\n    height: 4px;\n    width: 100px;\n    background: ", ";\n    transition: all .4s;\n  }\n\n  .ant-badge {\n    color: #95aac9;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  margin-bottom: 10px;\n  color: #95aac9;\n\n  &:after {\n    position: absolute;\n    content: \"\";\n    bottom: 0;\n    left: ", "px;\n    height: 4px;\n    width: 100px;\n    background: ", ";\n    transition: all .4s;\n  }\n\n  .ant-badge {\n    color: #95aac9;\n  }\n"])), function (props) { return props.idx * 100 - props.idx; }, MAIN_COLOR);
var TabItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  margin-right: -1px;\n  padding: 8px 0;\n  width: 100px;\n  text-align: center;\n  cursor: pointer;\n\n  > span {\n    padding: 0 10px;\n  }\n\n  &:after {\n    position: absolute;\n    display: none;\n    content: \"\";\n    left: 10px;\n    bottom: 0;\n    height: 2px;\n    width: 80px;\n    background: ", ";\n  }\n\n  &:hover {\n    &:after {\n      display: block;\n    }\n  }\n"], ["\n  position: relative;\n  margin-right: -1px;\n  padding: 8px 0;\n  width: 100px;\n  text-align: center;\n  cursor: pointer;\n\n  > span {\n    padding: 0 10px;\n  }\n\n  &:after {\n    position: absolute;\n    display: none;\n    content: \"\";\n    left: 10px;\n    bottom: 0;\n    height: 2px;\n    width: 80px;\n    background: ", ";\n  }\n\n  &:hover {\n    &:after {\n      display: block;\n    }\n  }\n"])), MAIN_COLOR);
var BannerWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  margin: 20px 16px 0 16px;\n  box-shadow: 2px 2px 10px #ddd;\n\n  > img {\n    width: 100%;\n  }\n"], ["\n  position: relative;\n  margin: 20px 16px 0 16px;\n  box-shadow: 2px 2px 10px #ddd;\n\n  > img {\n    width: 100%;\n  }\n"])));
var GuideCase = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  left: 56px;\n  top: 100px;\n  font-size: 14px;\n\n  > span {\n    margin-right: 20px;\n    color: #1890FF;\n    text-decoration: underline;\n    cursor: pointer;\n  }\n"], ["\n  position: absolute;\n  left: 56px;\n  top: 100px;\n  font-size: 14px;\n\n  > span {\n    margin-right: 20px;\n    color: #1890FF;\n    text-decoration: underline;\n    cursor: pointer;\n  }\n"])));
var ToDoText = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 16px;\n  color: #555;\n"], ["\n  font-size: 16px;\n  color: #555;\n"])));
var HistoryUrl = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  padding-right: 14px;\n  min-width: 25%;\n  max-width: 25%;\n  cursor: pointer;\n  flex: 1;\n"], ["\n  margin-bottom: 10px;\n  padding-right: 14px;\n  min-width: 25%;\n  max-width: 25%;\n  cursor: pointer;\n  flex: 1;\n"])));
var HistoryUrlWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 24px;\n  display: flex;\n  flex-wrap: wrap;\n"], ["\n  padding: 24px;\n  display: flex;\n  flex-wrap: wrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
