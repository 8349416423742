import { post } from "@helpers/axiosHelper";
import { commonDeleteApi } from "@/common/commonAction";
import { STATUS_OPTIONS_TYPE } from "@/common/const";
import { ADDRESS_GROUP_ENUM } from "@pages/doohPlaninng/helpers/initData";
export var getList = function (data) {
    return post("/groupme/audex/doohplanning/getList", data);
};
export var deleteApi = function (data) {
    return commonDeleteApi(data, STATUS_OPTIONS_TYPE.DOOH_PLANNING);
};
export var getDetail = function (taskId, readonly) {
    return post("/groupme/audex/doohplanning/getDetail", { taskId: taskId, readonly: readonly });
};
export var saveAddressGroupApi = function (data, type) {
    switch (type) {
        case ADDRESS_GROUP_ENUM.ADDRESS_GROUP:
            return saveAddressGroup(data);
        case ADDRESS_GROUP_ENUM.COMPETING_ADDRESS_GROUP:
            return saveCompetingAddressGroup(data);
        case ADDRESS_GROUP_ENUM.MEDIA_GROUP:
            return saveMediaGroup(data);
        default:
            return saveAddressGroup(data);
    }
};
var saveAddressGroup = function (data) {
    return post("/groupme/audex/doohplanning/saveAddressGroupByUid", data);
};
var saveCompetingAddressGroup = function (data) {
    return post("/groupme/audex/doohplanning/saveCompetingAddressGroupByUid", data);
};
var saveMediaGroup = function (data) {
    return post("/groupme/audex/doohplanning/saveMediaGroupByUid", data);
};
export var getAddressGroupList = function (data) {
    return post("/groupme/audex/doohplanning/getAddressListByUid", data);
};
export var getCompetingAddressGroupList = function (data) {
    return post("/groupme/audex/doohplanning/getCompetingAddressListByUid", data);
};
export var getMediaGroupList = function (data) {
    return post("/groupme/audex/doohplanning/getMediaListByUid", data);
};
export var delAddressApi = function (data) {
    return post("/groupme/audex/doohplanning/deleteAddress", data);
};
export var delCompetingAddressApi = function (data) {
    return post("/groupme/audex/doohplanning/deleteCompetingAddress", data);
};
export var delMediaApi = function (data) {
    return post("/groupme/audex/doohplanning/deleteMedia", data);
};
export var clearAddress = function (data) {
    return post("/groupme/audex/doohplanning/clearAddress", data);
};
export var clearCompetingAddress = function (data) {
    return post("/groupme/audex/doohplanning/clearCompetingAddress", data);
};
export var clearMedia = function (data) {
    return post("/groupme/audex/doohplanning/clearMedia", data);
};
export var saveApi = function (data) {
    return post("/groupme/audex/doohplanning/save", data);
};
export var getMapConfig = function (id) {
    return post("/groupme/audex/doohplanning/getConfig", { id: id });
};
export var getMapAddress = function (data) {
    return post("/groupme/audex/doohplanning/getAddress", data);
};
export var getPointMarkersData = function (data) {
    return post("/groupme/audex/doohplanning/getPointMarkersData", data);
};
