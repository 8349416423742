var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { l } from "@helpers/multiLangaugeHelper";
import { Checkbox, Divider } from "antd";
import { MyCheckBox } from "@components/baseComponents/myCheckBox";
import { MyRangePicker } from "@components/baseComponents/myRangePicker";
import moment from "moment";
import { MySelect } from "@components/baseComponents/mySelect";
import { COMMON_OPTIONS_TYPE } from "@/common/const";
import { initTextList } from "@helpers/const";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { filter, map, merge, set } from "lodash";
import { FooterBtnWrapper } from "@components/common/footerBtnWrapper";
import { getDetail, getOTVChannelOptions, saveApi } from "./helpers/async";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { MyInput } from "@components/baseComponents/myInput";
import { MyTextArea } from "@components/baseComponents/myTextArea";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import styled from "styled-components";
import { MyInputNumber } from "@/components/baseComponents/myInputNumber";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MyFormWrapper, MyItemWrapper } from "@components/common/myFormWrapper";
var CUSTOM_TYPE = "Custom";
export var OTVAudienceEdit = function (_a) {
    var readonly = _a.readonly;
    var history = useHistory();
    var _b = useState(false), indeterminate = _b[0], setIndeterminate = _b[1];
    var _c = useState([]), channelOptions = _c[0], setChannelOptions = _c[1];
    var _d = useState({}), formData = _d[0], setFormData = _d[1];
    var _e = useState(false), saving = _e[0], setSaving = _e[1];
    var _f = useState(false), nameError = _f[0], setNameError = _f[1];
    useEffect(function () {
        var _a = queryString.parse(location.search), id = _a.id, isCopy = _a.isCopy;
        getOTVChannelOptions()
            .then(function (res) {
            setChannelOptions(res.data);
        })
            .catch(msgError);
        if (id) {
            getDetail(Number(id))
                .then(function (res) {
                var data = res.data;
                if (!readonly && !data.actionEdit && !isCopy) {
                    history.push("/techHub/audience/OTVAudience/view?id=" + id);
                }
                if (isCopy) {
                    delete data.taskId;
                    delete data.segmentId;
                    data.segmentName = null;
                }
                setFormData(data);
            })
                .catch(msgError);
        }
    }, []);
    var getDisabledDate = function (current) {
        return current &&
            (current > moment().endOf("day") ||
                current < moment().add(-1, "y").startOf("month"));
    };
    var onCheck = function (list) {
        var newData = merge({}, formData);
        set(newData, "channelList", list);
        setFormData(newData);
        setIndeterminate(!!list.length && list.length < channelOptions.length);
    };
    var onCheckAllChange = function (e) {
        var newData = merge({}, formData);
        set(newData, "channelList", e.target.checked ? map(channelOptions, function (item) { return item.value; }) : []);
        setFormData(newData);
        setIndeterminate(false);
    };
    var onChange = function (value, pathKey) {
        var newData = merge({}, formData);
        if (pathKey === "keyword") {
            var list = value.split("\n");
            set(newData, pathKey, list);
            setFormData(newData);
            return;
        }
        set(newData, pathKey, value);
        setFormData(newData);
    };
    var saveCanCheck = function () {
        if (!formData.segmentName || formData.segmentName === "" || !formData.advertiserId)
            return false;
        if (formData.channel && formData.channel.length < 1)
            return false;
        if (!formData.dateRange || !formData.dateRange[0] || !formData.frequencyType)
            return false;
        if (formData.frequencyType === CUSTOM_TYPE &&
            !(formData.minFrequency && formData.frequency))
            return false;
        if (!formData.memo || formData.memo === "")
            return false;
        var keywordList = filter(formData.keyword, function (item) { return item !== ""; });
        if (keywordList.length === 0)
            return false;
        return !nameError;
    };
    var save = function () {
        setSaving(true);
        saveApi(formData)
            .then(function (res) {
            msgSuccess(res);
            history.push("/techHub/audience/OTVAudience/manage");
        })
            .catch(function (err) {
            setSaving(false);
            msgError(err);
        });
    };
    return (<CommonWrapper>
      <MyFormWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_audience_name", "人群名称")}>
            <MyInput style={{ width: FORM_ITEM_WIDTH }} disabled={readonly} value={formData.segmentName} placeholder={l("common_segmentName_placeholder", "请输入人群名称")} onChange={function (v) { return onChange(v.target.value, "segmentName"); }} errorMsg={l("common_task_name_err_msg_tips", "任务名称只能输入字母数字及下划线且长度不超过50")} validateReg={"^[0-9a-zA-Z_]{1,50}$"} errorCallBack={function (flag) { return setNameError(flag); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_advertiser", "Advertiser")}>
            <MySelect disabled={readonly} showSearch={true} style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_select_advertiser", "Select Advertiser")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={formData.advertiserId} onChange={function (v) { return onChange(v, "advertiserId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_date_range", "日期范围")}>
            <MyRangePicker style={{ width: FORM_ITEM_WIDTH }} disabled={[readonly, readonly]} disabledDate={getDisabledDate} value={formData.dateRange} onChange={function (v) { return onChange(v, "dateRange"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_channel", "Channel")}>
            <CheckboxAll disabled={readonly} style={{ marginTop: "6px" }} indeterminate={indeterminate} onChange={onCheckAllChange} checked={formData.channelList && formData.channelList.length === channelOptions.length}>
              {l("common_check_all", "Check all")}
            </CheckboxAll>
            <Divider style={{ margin: "8px 0" }}/>
            <MyCheckBox disabled={readonly} value={formData.channelList} url={"/groupme/audex/audience/OTVAudience/getChannelOptions"} onChange={onCheck}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_frequency", "频次")}>
            <MySelect style={{ width: FORM_ITEM_WIDTH }} disabled={readonly} allowClear={false} placeholder={l("common_frequency_select_placeholder", "请选择频次")} url={"/groupme/common/getCommonOptions"} params={{ type: COMMON_OPTIONS_TYPE.AUDIENCE_FREQUENCY_TYPE }} value={formData.frequencyType} onChange={function (v) { return onChange(v, "frequencyType"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          {formData.frequencyType === CUSTOM_TYPE ?
        <MyFormItem required label={l("common_frequency", "频次")}>
              <MyInputNumber disabled={readonly} min={1} style={{ width: "48%" }} value={formData.minFrequency} onChange={function (v) { return onChange(v, "minFrequency"); }}/>
              <span style={{ margin: "0 11px" }}>
            ～
          </span>
              <MyInputNumber disabled={readonly} min={formData.minFrequency || 1} style={{ width: "48%" }} value={formData.maxFrequency} onChange={function (v) { return onChange(v, "maxFrequency"); }}/>
              <MySelect disabled={readonly} allowClear={false} placeholder={l("common_frequency_select_placeholder", "请选择频次")} style={{ width: FORM_ITEM_WIDTH, marginTop: "16px" }} url={"/groupme/common/getCommonOptions"} params={{ type: COMMON_OPTIONS_TYPE.AUDIENCE_FREQUENCY_BASE }} value={formData.frequency} onChange={function (v) { return onChange(v, "frequency"); }}/>
            </MyFormItem> : null}
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_keyword", "关键词")}>
            <MyTextArea disabled={readonly} style={{ height: "80px", width: FORM_ITEM_WIDTH }} value={initTextList(formData.keyword)} placeholder={l("audience_custom_audience_otv_create_keyword_tips", "请输入关键词,重复字段会被移除。输入多个关键词,回车为分隔符。例:") + "\n" + l("audience_custom_audience_otv_create_keyword_tips_1", "李易峰") + "\n" + l("audience_custom_audience_otv_create_keyword_tips_2", "邓伦")} onChange={function (v) { return onChange(v.target.value, "keyword"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_memo", "备注")}>
            <MyTextArea style={{ width: FORM_ITEM_WIDTH }} disabled={readonly} placeholder={l("audience_custom_audience_otv_memo_placeholder", "请输入人群圈选的原因。有任何修改建议或需求，也可以输入。")} value={formData.memo} onChange={function (v) { return onChange(v.target.value, "memo"); }}/>
          </MyFormItem>
        </MyItemWrapper>
      </MyFormWrapper>
      {readonly ? null : <FooterBtnWrapper buttonList={[
        {
            type: "primary",
            text: l("common_save", "Save"),
            onClick: function () { return save(); },
            disabled: !saveCanCheck(),
            loading: saving
        }
    ]}/>}
    </CommonWrapper>);
};
var CheckboxAll = styled(Checkbox)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & .ant-checkbox-wrapper:hover {\n    border: none;\n\n    .ant-checkbox-inner {\n      border-color: #eef3f7 !important;\n      background-color: #eef3f7;\n    }\n  }\n\n  & .ant-checkbox-inner {\n    border: none !important;\n    background-color: #f5f8fa;\n  }\n\n  & .ant-checkbox-inner:after {\n    border-color: #95aac9;\n  }\n\n  & .ant-checkbox-checked::after {\n    border: none;\n  }\n"], ["\n  & .ant-checkbox-wrapper:hover {\n    border: none;\n\n    .ant-checkbox-inner {\n      border-color: #eef3f7 !important;\n      background-color: #eef3f7;\n    }\n  }\n\n  & .ant-checkbox-inner {\n    border: none !important;\n    background-color: #f5f8fa;\n  }\n\n  & .ant-checkbox-inner:after {\n    border-color: #95aac9;\n  }\n\n  & .ant-checkbox-checked::after {\n    border: none;\n  }\n"])));
var templateObject_1;
