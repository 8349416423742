var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useContext, useState } from "react";
import styled from "styled-components";
import { MyInput } from "@components/baseComponents/myInput";
import { l } from "@helpers/multiLangaugeHelper";
import { CommonWrapper } from "@components/common/commonWrapper";
import { CommonTitle } from "@components/common/commonTitle";
import { useHistory } from "react-router-dom";
import { audienceData } from "@pages/customAudience/audienceTranslate/helpers/initData";
import { Tabs, Tag } from "antd";
import { SkinContext } from "@components/contentWrapper/store/storeContext";
export var AudienceTranslateDetail = function () {
    var history = useHistory();
    var skin = useContext(SkinContext).skin;
    var _a = useState(0), selectedIdx = _a[0], setSelectedIdx = _a[1];
    var save = function () {
        history.push("/techHub/audience/audienceExplorer/manage");
    };
    return (<CommonWrapper>
    <CommonTitle>{l("common_advertiser", "Advertiser")}: Demo1</CommonTitle>
    <div style={{ display: "flex" }}>
      <LeftWrapper>
        <MyInput style={{ width: "100%" }} placeholder={l("audience_translate_detail_filter", "Filter ...")}/>
        <CommonTitle>Saved Audience({audienceData.length})</CommonTitle>
        {audienceData.map(function (item, idx) { return (<Audience active={idx === selectedIdx} color={skin.mainColor} onClick={function () { return idx < 2 ? setSelectedIdx(idx) : null; }}>
            {item.audience}
          </Audience>); })}
      </LeftWrapper>
      <RightWrapper>
        <CommonTitle>Shan Hai Jin</CommonTitle>
        {selectedIdx || selectedIdx === 0 ? <>
          <Title>{audienceData[selectedIdx].audience}</Title>
          <Desc>{audienceData[selectedIdx].shj.desc}</Desc>
          <div style={{ marginBottom: "32px" }}>{audienceData[selectedIdx].shj.tags.map(function (item) { return <MTag closable>{item}</MTag>; })}</div>
        </> : null}
        <Tabs defaultActiveKey="1" activeKey="1">
          <Tabs.TabPane tab="Talking Data" key="1">
            {selectedIdx || selectedIdx === 0 ?
        <div style={{ marginTop: "10px" }}>{audienceData[selectedIdx].td.tags.map(function (item) { return <MTag closable>{item}</MTag>; })}</div> : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kuyun" key="2">
          </Tabs.TabPane>
          <Tabs.TabPane tab="Gedeng" key="3">
          </Tabs.TabPane>
          <Tabs.TabPane tab="Yuntu" key="4">
          </Tabs.TabPane>
          <Tabs.TabPane tab="ZhiShu" key="5">
          </Tabs.TabPane>
        </Tabs>
        <Title>Options to Consider</Title>
        {selectedIdx || selectedIdx === 0 ?
        <div>{audienceData[selectedIdx].options.map(function (item) { return <MTag closable>{item}</MTag>; })}</div> : null}
      </RightWrapper>
    </div>
    
    
    
    
    
    
    
    
    
    
    
    
  </CommonWrapper>);
};
var RightWrapper = styled(CommonWrapper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var LeftWrapper = styled(CommonWrapper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 280px;\n"], ["\n  width: 280px;\n"])));
var MTag = styled(Tag)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 12px;\n  margin-bottom: 10px;\n"], ["\n  margin-right: 12px;\n  margin-bottom: 10px;\n"])));
var Audience = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 8px;\n  display: block;\n  cursor: pointer;\n  color: ", ";\n  &:hover{\n    color: ", "\n  }\n"], ["\n  margin-bottom: 8px;\n  display: block;\n  cursor: pointer;\n  color: ", ";\n  &:hover{\n    color: ", "\n  }\n"])), function (props) { return props.active ? props.color : "#777"; }, function (props) { return props.color; });
var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: bold;\n  margin: 14px 0;\n"], ["\n  font-weight: bold;\n  margin: 14px 0;\n"])));
var Desc = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 26px;\n  font-size: 12px;\n  color:#999;\n"], ["\n  margin-bottom: 26px;\n  font-size: 12px;\n  color:#999;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
