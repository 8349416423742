var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { MAIN_COLOR, WARING_COLOR } from "@/common/styled";
import { ConditionList } from "./components/conditionList";
import { CalcContainer } from "./components/calcContainer";
import { StoreContext } from "./store/storeContext";
import { Button, Modal } from "antd";
import { FooterBtnWrapper } from "@components/common/footerBtnWrapper";
import { merge, set } from "lodash";
import { CALC_TYPE } from "./helpers/initData";
import { ShanHaiJinCondition } from "./components/conditions/shanHaiJin";
import queryString from "query-string";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { getConditionList, getDataSourceDetail, getDetail, getResourceOptions, saveApi } from "./helpers/async";
import { BrowserCondition } from "./components/conditions/browser";
import { MobileAppCondition } from "./components/conditions/mobileApp";
import { MySelect } from "@components/baseComponents/mySelect";
import { OTVCondition } from "./components/conditions/otv";
import { useHistory, useLocation } from "react-router-dom";
import { FrequentPlaceCondition } from "./components/conditions/frequentPlace";
import { LocationCondition } from "./components/conditions/location";
import { TravelCondition } from "./components/conditions/travel";
import { CustomAudienceCondition } from "./components/conditions/customAudience";
import { SelfAudienceCondition } from "./components/conditions/selfAudience";
import { SiteCondition } from "./components/conditions/site";
import { CampaignCondition } from "./components/conditions/campaign";
import { IndustryAudienceCondition } from "./components/conditions/industryAudience";
import { RecommendAudienceCondition } from "./components/conditions/recommendAudience";
import { DefaultCondition } from "./components/conditions/default";
import { MyInput } from "@components/baseComponents/myInput";
import { MySpin } from "@components/baseComponents/mySpin";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import { remove } from "@antv/util";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MyFormWrapper, MyItemWrapper } from "@/components/common/myFormWrapper";
export var SOURCE_ID_KU_YUN = 2;
var DPM = 1;
export var StandardAudienceEdit = function (_a) {
    var readonly = _a.readonly, isTrackingAudience = _a.isTrackingAudience;
    var history = useHistory();
    var location = useLocation();
    var _b = queryString.parse(location.search), tagSquare = _b.tagSquare, translator = _b.translator, relativeId = _b.relativeId, isCopy = _b.isCopy;
    var dataSource = queryString.parse(location.search).dataSource ? Number(queryString.parse(location.search).dataSource) : null;
    var advertiserId = queryString.parse(location.search).advertiserId ? Number(queryString.parse(location.search).advertiserId) : null;
    var _c = useState({}), data = _c[0], setData = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState([
        { type: CALC_TYPE.OR, conditions: [] }
    ]), calcConditions = _e[0], setCalcConditions = _e[1];
    var _f = useState({}), modalData = _f[0], setModalData = _f[1];
    var _g = useState({}), modalOption = _g[0], setModalOption = _g[1];
    var _h = useState(false), saving = _h[0], setSaving = _h[1];
    var _j = useState(false), nameError = _j[0], setNameError = _j[1];
    var _k = useState({}), tagData = _k[0], setTagData = _k[1];
    var _l = useState([]), resourceOptions = _l[0], setResourceOptions = _l[1];
    useEffect(function () {
        if ((tagSquare || translator) && isCopy !== "true") {
            setData({ sourceId: dataSource, advertiserId: advertiserId, relativeId: relativeId });
            setLoading(true);
            var modalPostData = {
                selectedRowKeys: JSON.parse(sessionStorage.getItem("tagSquare-modalSelectedTags")),
                dataSource: Number(dataSource)
            };
            getDataSourceDetail(modalPostData)
                .then(function (res) {
                setCalcConditions(res.data ? res.data : [{ type: CALC_TYPE.OR, conditions: [] }]);
                setLoading(false);
            })
                .catch(function (err) {
                setLoading(false);
                msgError(err);
            });
            sessionStorage.removeItem("tagSquare-modalSelectedTags");
        }
    }, []);
    useEffect(function () {
        var _a = queryString.parse(location.search), id = _a.id, isCopy = _a.isCopy;
        getResourceOptions()
            .then(function (res) {
            setResourceOptions(res.data);
            if (id) {
                setLoading(true);
                getDetail(id)
                    .then(function (res) {
                    var data = res.data;
                    if (!readonly && !data.actionEdit && !isCopy) {
                        history.push(translator || location.pathname.indexOf("translator") > -1 ? "/techHub/audience/translatorAudience/manage" : "/techHub/audience/standardAudience/manage");
                    }
                    if (isCopy) {
                        delete data.taskId;
                        delete data.segmentId;
                        data.segmentName = data.segmentName + "_copy";
                    }
                    setData(data);
                    setCalcConditions(res.data ? res.data.calcConditions : [{ type: CALC_TYPE.OR, conditions: [] }]);
                    setLoading(false);
                })
                    .catch(function (err) {
                    setLoading(false);
                    msgError(err);
                });
            }
            else {
                setData({
                    sourceId: dataSource ? dataSource : (res.data && res.data[0] ? res.data[0].value : null),
                    advertiserId: advertiserId, relativeId: relativeId
                });
            }
        });
    }, []);
    useEffect(function () {
        if (isTrackingAudience) {
            getConditionList({ taskId: data.taskId }, isTrackingAudience)
                .then(function (res) {
                setTagData(res.data);
            })
                .catch(msgError);
            return;
        }
        if (data.sourceId) {
            var postData = {
                sourceId: data.sourceId,
                taskId: data.taskId
            };
            getConditionList(postData)
                .then(function (res) {
                setTagData(res.data);
            })
                .catch(msgError);
        }
        else {
            setTagData({});
        }
    }, [data.sourceId]);
    var onFormChange = function (value, pathKey) {
        var newData = merge({}, data);
        if (calcConditions.length === 1 && calcConditions[0].conditions.length === 0) {
            set(newData, pathKey, value);
            setData(newData);
            return;
        }
        if (pathKey === "advertiserId") {
            var hasAudienceDragged = calcConditions.find(function (item) { return item.conditions.find(function (item) { return item.type === "audience"; }); });
            if (hasAudienceDragged) {
                Modal.confirm({
                    content: l("custom_audience_calc_change_advertiserId_tips", "修改广告主将清除已选择的人群组合运算条件，请确认"),
                    onOk: function () {
                        var clearCalcConditions = merge([], calcConditions);
                        for (var _i = 0, clearCalcConditions_1 = clearCalcConditions; _i < clearCalcConditions_1.length; _i++) {
                            var item = clearCalcConditions_1[_i];
                            remove(item.conditions, function (item) { return item.type === "audience"; });
                        }
                        setCalcConditions(clearCalcConditions);
                        set(newData, pathKey, value);
                        setData(newData);
                    },
                });
                return;
            }
        }
        if (pathKey === "sourceId") {
            Modal.confirm({
                content: l("custom_audience_calc_change_sourceId_tips", "如切换数据源，将会清空组合运算框中所有已选择的标签和人群，是否确认切换"),
                onOk: function () {
                    setCalcConditions([
                        { type: CALC_TYPE.OR, conditions: [] }
                    ]);
                    set(newData, pathKey, value);
                    setData(newData);
                },
            });
            return;
        }
        set(newData, pathKey, value);
        setData(newData);
    };
    var addCalc = function (type) {
        var newCalcConditions = merge([], calcConditions);
        newCalcConditions.push({ type: type, conditions: [] });
        setCalcConditions(newCalcConditions);
    };
    var saveModalData = function (modalData, modalOption) {
        var newCalcConditions = merge([], calcConditions);
        if (modalOption.idx || modalOption.idx === 0) {
            newCalcConditions[modalOption.calcIdx].conditions[modalOption.idx].data = modalData;
        }
        else {
            var condition = {
                title: modalOption.title,
                type: modalOption.type,
                tagId: modalOption.tagId,
                params: modalOption.params,
                data: modalData
            };
            if (newCalcConditions[modalOption.calcIdx].conditions) {
                newCalcConditions[modalOption.calcIdx].conditions.push(condition);
            }
            else {
                newCalcConditions[modalOption.calcIdx].conditions = [condition];
            }
        }
        setCalcConditions(newCalcConditions);
    };
    var save = function (isSubmit) {
        var saveData = __assign(__assign({}, data), { calcConditions: calcConditions,
            isSubmit: isSubmit, translator: Number(translator) });
        setSaving(true);
        saveApi(saveData, isTrackingAudience)
            .then(function (res) {
            setSaving(false);
            history.push(isTrackingAudience ? "/techHub/audience/trackingAudience/manage" :
                (translator || location.pathname.indexOf("translator") > -1 ? "/techHub/audience/translatorAudience/manage" : "/techHub/audience/standardAudience/manage"));
            msgSuccess(res);
        })
            .catch(function (err) {
            setSaving(false);
            msgError(err);
        });
    };
    var checkSaveDisabled = function () {
        if (!data.segmentName || !data.advertiserId || !data.sourceId)
            return true;
        if (!calcConditions || calcConditions.length === 0) {
            return true;
        }
        else {
            var result = false;
            for (var _i = 0, calcConditions_1 = calcConditions; _i < calcConditions_1.length; _i++) {
                var item = calcConditions_1[_i];
                result = !item.conditions || item.conditions.length === 0;
                if (result)
                    return result;
            }
        }
        return nameError;
    };
    return (<MySpin spinning={loading}>
      <CommonWrapper>
        <StoreContext.Provider value={{
        data: data, tagData: tagData,
        calcConditions: calcConditions, setCalcConditions: setCalcConditions,
        modalData: modalData, setModalData: setModalData, modalOption: modalOption, setModalOption: setModalOption,
        saveModalData: saveModalData,
        readonly: readonly
    }}>
          <MyFormWrapper>
            <MyItemWrapper>
              <MyFormItem required label={l("common_audience_name", "人群名称")}>
                <MyInput style={{ width: FORM_ITEM_WIDTH }} disabled={readonly} value={data.segmentName} placeholder={l("common_segmentName_placeholder", "请输入人群名称")} onChange={function (v) { return onFormChange(v.target.value, "segmentName"); }} errorMsg={l("common_task_name_err_msg_tips", "任务名称只能输入字母数字及下划线且长度不超过50")} validateReg={"^[0-9a-zA-Z_]{1,50}$"} errorCallBack={function (flag) { return setNameError(flag); }}/>
              </MyFormItem>
            </MyItemWrapper>
            <MyItemWrapper>
              <MyFormItem required label={l("common_advertiser", "Advertiser")}>
                <MySelect disabled={readonly} showSearch={true} style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_select_advertiser", "Select Advertiser")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={data.advertiserId} onChange={function (v) { return onFormChange(v, "advertiserId"); }}/>
              </MyFormItem>
            </MyItemWrapper>
          </MyFormWrapper>
          <DndProvider backend={HTML5Backend}>
            <DndWrapper>
              <LeftWrapper>
                <Title><i />{l("common_condition", "条件")}</Title>
                <div className={"content"}>
                  {isTrackingAudience ? null : <MyFormItem required label={l("common_data_source", "数据源")}>
                    <MySelect disabled={readonly} defaultOptions={resourceOptions} showSearch={true} style={{ width: "100%" }} placeholder={l("common_select_data_source", "选择数据源")} value={data.sourceId} onChange={function (v) { return onFormChange(v, "sourceId"); }}/>
                  </MyFormItem>}
                  <ConditionList isTrackingAudience={isTrackingAudience}/>
                </div>
              </LeftWrapper>
              <RightWrapper>
                <Title><i />{l("common_calc_group", "组合运算")}</Title>
                
                <CalcContainer />
                <CalcBtnWrapper>
                  <div>
                    <Button style={{ marginRight: "20px" }} disabled={calcConditions.length === 5 || readonly} type={"primary"} onClick={function () { return addCalc(CALC_TYPE.AND); }}>
                      {l("custom_audience_calc_btn_and", "+且")}
                    </Button>
                    {data.sourceId === SOURCE_ID_KU_YUN ? null : <Button type={"ghost"} disabled={calcConditions.length === 5 || readonly} style={{
        backgroundColor: calcConditions.length === 5 || readonly ? "#f5f5f5" : WARING_COLOR,
        color: calcConditions.length === 5 || readonly ? "#bdbdbd" : "#fff"
    }} onClick={function () { return addCalc(CALC_TYPE.EXCLUDE); }}>
                      {l("custom_audience_calc_btn_exclude", "+排除")}
                    </Button>}
                  </div>
                </CalcBtnWrapper>
              </RightWrapper>
            </DndWrapper>
          </DndProvider>
          <BrowserCondition readonly={readonly}/>
          <CustomAudienceCondition readonly={readonly}/>
          <FrequentPlaceCondition readonly={readonly}/>
          <LocationCondition readonly={readonly}/>
          <MobileAppCondition readonly={readonly}/>
          <OTVCondition readonly={readonly}/>
          <SelfAudienceCondition readonly={readonly}/>
          <ShanHaiJinCondition readonly={readonly}/>
          <TravelCondition readonly={readonly}/>
          <SiteCondition readonly={readonly}/>
          <CampaignCondition readonly={readonly}/>
          <IndustryAudienceCondition readonly={readonly}/>
          <RecommendAudienceCondition readonly={readonly}/>
          <DefaultCondition readonly={readonly}/>
        </StoreContext.Provider>
        {readonly ? null : <FooterBtnWrapper style={{ marginTop: "24px" }} buttonList={[
        {
            type: "primary",
            text: l("common_save_as_draft", "Save as draft"),
            onClick: function () { return save(); },
            disabled: checkSaveDisabled(),
            loading: saving
        },
        {
            type: "primary",
            text: l("common_save_submit", "Save & Submit"),
            onClick: function () { return save(true); },
            disabled: checkSaveDisabled(),
            loading: saving
        }
    ]}/>}
      </CommonWrapper>
    </MySpin>);
};
var DndWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 16px 0;\n  display: flex;\n  height: 660px;\n"], ["\n  margin: 16px 0;\n  display: flex;\n  height: 660px;\n"])));
var LeftWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 220px;\n  height: 100%;\n  background: #fff;\n  box-shadow: 2px 2px 10px #ddd;\n  border-radius: 6px;\n\n  .content {\n    padding: 10px;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 220px;\n  height: 100%;\n  background: #fff;\n  box-shadow: 2px 2px 10px #ddd;\n  border-radius: 6px;\n\n  .content {\n    padding: 10px;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n  }\n"])));
var RightWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  margin-left: 24px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  flex: 1;\n  background: #fff;\n  box-shadow: 2px 2px 10px #ddd;\n  border-radius: 6px;\n"], ["\n  position: relative;\n  margin-left: 24px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  flex: 1;\n  background: #fff;\n  box-shadow: 2px 2px 10px #ddd;\n  border-radius: 6px;\n"])));
var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 8px 0;\n  height: 40px;\n  border-bottom: 1px solid #ddd;\n\n  > i {\n    margin-right: 8px;\n    margin-top: 2px;\n    float: left;\n    display: inline-block;\n    height: 16px;\n    width: 4px;\n    background: ", ";\n  }\n"], ["\n  padding: 8px 0;\n  height: 40px;\n  border-bottom: 1px solid #ddd;\n\n  > i {\n    margin-right: 8px;\n    margin-top: 2px;\n    float: left;\n    display: inline-block;\n    height: 16px;\n    width: 4px;\n    background: ", ";\n  }\n"])), MAIN_COLOR);
var CalcBtnWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  z-index: 8;\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  border-top: 1px solid #ddd;\n  background: #fff;\n\n  > div {\n    padding: 10px;\n    display: flex;\n    justify-content: center;\n  }\n"], ["\n  z-index: 8;\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  border-top: 1px solid #ddd;\n  background: #fff;\n\n  > div {\n    padding: 10px;\n    display: flex;\n    justify-content: center;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
