var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { post } from "@helpers/axiosHelper";
// 获取mInsights accountId
export var getMInsightsAccountId = function (accountUid) {
    return post("/groupme/lal/getAccountIdByUid", { accountUid: accountUid });
};
// 获取多语言
export var getLanguageMap = function (languageKeyList, languageCode) {
    return post("/groupme/common/language/getLanguageMap", { languageKeyList: languageKeyList, languageCode: languageCode });
};
// 获取多语言
export var updateLanguage = function (languageCode) {
    return post("/groupme/common/language/updateLanguage", { languageCode: languageCode });
};
// 组件枚举类型组件通用请求接口
export var getOptions = function (url, params) {
    return post(url, params);
};
export var updateStatusApi = function (data, type) {
    return post("/groupme/common/updateStatus", __assign(__assign({}, data), { type: type }));
};
export var checkPIPLEnabled = function () {
    return post("/groupme/common/system/checkPIPLEnabled", { url: location.pathname });
};
export var externalEnter = function (data) {
    return post("/groupme/common/account/ssoEnter", data);
};
