import { post } from "@helpers/axiosHelper";
import { commonDeleteApi } from "@/common/commonAction";
import { STATUS_OPTIONS_TYPE } from "@/common/const";
export var getList = function (data) {
    return post("/groupme/audex/audience/customUpload/getList", data);
};
export var uploadSubmit = function (data) {
    return post("/groupme/audex/audience/customUpload/submit", data);
};
export var getFileType = function (data) {
    return post("/groupme/audex/audience/customUpload/getFileType", data);
};
export var deleteApi = function (data) {
    return commonDeleteApi(data, STATUS_OPTIONS_TYPE.ONE_PD_UPLOAD);
};
