import { l } from "@helpers/multiLangaugeHelper";
export var validateUsername = function (username) {
    if (!(username && username !== "")) {
        return null;
    }
    if (!username.match(/^[a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)) {
        return l("enter_err_msg_emailName", "* 请输入正确的邮箱地址");
    }
    return null;
};
export var validateNewPassword = function (password, oldPassword) {
    if (!(password && password !== "")) {
        return null;
    }
    if (oldPassword === password) {
        return l("enter_err_msg_new_old_password", "新密码不能与老密码一致");
    }
    if (password.length < 8) {
        return l("enter_err_msg_new_password_length_larger_than_8", "新密码长度需要大于等于8位");
    }
    if (password.length > 20) {
        return l("enter_err_msg_new_password_length_shorter_than_20", "新密码长度需要小于等于20位");
    }
    if (!password.match(/[a-z]/)) {
        return l("enter_err_msg_new_password_include_1_small_letter", "新密码必须包括至少一位小写字母");
    }
    if (!password.match(/[A-Z]/)) {
        return l("enter_err_msg_new_password_include_1_larger_letter", "新密码必须包括至少一位大写字母");
    }
    if (!password.match(/[0-9]/)) {
        return l("enter_err_msg_new_password_include_number", "新密码必须包括至少一位数字");
    }
    if (!password.match(/[`~!@#$%^&*()+\-/.,?]/)) {
        return l("enter_err_msg_new_password_include_special", "新密码必须包括至少一位特殊字符");
    }
    return null;
};
