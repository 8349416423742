var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { l } from "@helpers/multiLangaugeHelper";
import { useHistory, useParams } from "react-router-dom";
import { Col, Tooltip } from "antd";
import { MySelect } from "@components/baseComponents/mySelect";
import { merge, set } from "lodash";
import { MyCheckBox } from "@components/baseComponents/myCheckBox";
import { FooterBtnWrapper } from "@components/common/footerBtnWrapper";
import { getDimensionOptions, getTaskDetail, saveApi } from "./helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { CommonTips } from "@components/common/commonTips";
import { InfoCircleOutlined } from "@/utils/antdIcon";
import styled from "styled-components";
import queryString from "query-string";
import { MyTextArea } from "@components/baseComponents/myTextArea";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import numeral from "numeral";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MyFormWrapper, MyItemWrapper } from "@components/common/myFormWrapper";
export var ProfilingEdit = function (_a) {
    var readonly = _a.readonly;
    var profileType = useParams().profileType;
    var history = useHistory();
    var id = queryString.parse(location.search).id;
    var _b = useState({}), formData = _b[0], setFormData = _b[1];
    var _c = useState(false), saving = _c[0], setSaving = _c[1];
    var _d = useState([]), portraitDimensionOptions = _d[0], setPortraitDimensionOptions = _d[1];
    var _e = useState([]), industryDimensionOptions = _e[0], setIndustryDimensionOptions = _e[1];
    var _f = useState(false), isSimpleCreate = _f[0], setIsSimpleCreate = _f[1];
    useEffect(function () {
        var advertiserId = Number(queryString.parse(location.search).advertiserId);
        var segmentId = Number(queryString.parse(location.search).segmentId);
        var sourceId = Number(queryString.parse(location.search).sourceId);
        if (id) {
            getTaskDetail(Number(id))
                .then(function (res) {
                var data = res.data;
                if (!readonly && !data.actionEdit) {
                    history.push("/techHub/profiling/" + profileType + "/view?id=" + id);
                }
                setFormData(data);
            })
                .catch(msgError);
        }
        if (advertiserId && segmentId && sourceId) {
            setIsSimpleCreate(true);
            setFormData(merge({}, formData, { advertiserId: advertiserId, segmentId: segmentId, sourceId: sourceId }));
        }
    }, []);
    useEffect(function () {
        if (formData.sourceId) {
            getDimensionOptions({ profileType: profileType, sourceId: formData.sourceId, taskId: Number(id) })
                .then(function (_a) {
                var portrait = _a[0], industry = _a[1];
                var newData = merge({}, formData);
                var value = portrait.map(function (item) { return item.value; });
                setPortraitDimensionOptions(portrait);
                setIndustryDimensionOptions(industry);
                set(newData, "portraitList", value);
                if (!readonly)
                    set(newData, "industryList", []);
                setFormData(newData);
            })
                .catch(msgError);
        }
    }, [formData.sourceId]);
    var onFormChange = function (value, pathKey) {
        var newData = merge({}, formData);
        set(newData, pathKey, value);
        if (pathKey === "advertiserId") {
            set(newData, "segmentId", null);
            // set(newData, "industryList", null)
        }
        if (pathKey === "sourceId") {
            set(newData, "segmentId", null);
        }
        setFormData(newData);
    };
    var save = function () {
        setSaving(true);
        saveApi(__assign(__assign({}, formData), { profileType: profileType }))
            .then(function (res) {
            msgSuccess(res);
            setSaving(false);
            history.push("/techHub/profiling/" + profileType + "/manage");
        })
            .catch(function (err) {
            setSaving(false);
            msgError(err);
        });
    };
    var checkSaveDisabled = function () {
        return !(formData.advertiserId && formData.segmentId && formData.memo && formData.memo !== "");
    };
    return (<CommonWrapper>
      <MyFormWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_advertiser", "Advertiser")}>
            <MySelect showSearch={true} disabled={readonly || isSimpleCreate} style={{ width: FORM_ITEM_WIDTH }} allowClear={false} placeholder={l("common_select_advertiser", "Select Advertiser")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={formData.advertiserId} onChange={function (v) { return onFormChange(v, "advertiserId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_data_source", "数据源")}>
            <MySelect style={{ width: FORM_ITEM_WIDTH }} disabled={readonly || isSimpleCreate} showSearch={true} allowClear={false} placeholder={l("common_select_data_source", "请选择数据源")} url={"/groupme/audex/profiling/profile/getDataSourceOptions"} value={formData.sourceId} onChange={function (v) { return onFormChange(v, "sourceId"); }} params={{ profileType: profileType }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_audience", "种子人群")}>
            <MySelect style={{ width: FORM_ITEM_WIDTH }} disabled={readonly || isSimpleCreate} showSearch={true} allowClear={false} placeholder={l("common_select_audience", "选择人群")} url={"/groupme/audex/audience/getAudienceOptionsByAdvertiserId_v2"} params={{ advertiserId: formData.advertiserId, sourceId: formData.sourceId }} value={formData.segmentId} onChange={function (v) { return onFormChange(v, "segmentId"); }} descFormatter={function (v) { return <DescSelectTips>
                {l("common_audience_size", "人群规模")}: {numeral(v).format("0,0.00a")}
              </DescSelectTips>; }}/>
          </MyFormItem>
        </MyItemWrapper>
        {formData.sourceId ?
        <MyItemWrapper>
            <MyFormItem label={l("profiling_portrait_dimension", "画像维度")}>
              <MyCheckBox disabled={readonly} defaultOptions={portraitDimensionOptions} value={formData.portraitList} onChange={function (v) { return onFormChange(v, "portraitList"); }}/>
            </MyFormItem></MyItemWrapper> : null}
        {formData.sourceId && industryDimensionOptions.length > 0 ? <MyItemWrapper>
          <MyFormItem label={<>
              {l("profiling_industry_dimension", "行业维度")}
              <Tooltip title={l("profiling_industry_dimension_tips", "行业维度为增值服务，如需使用请先线下沟通。")}>
                <IconWrapper />
              </Tooltip>
            </>}>
            <MyCheckBox disabled={readonly} defaultOptions={industryDimensionOptions} value={formData.industryList} onChange={function (v) { return onFormChange(v, "industryList"); }}/>
          </MyFormItem></MyItemWrapper> : null}
        <MyItemWrapper>
          <MyFormItem required label={l("common_memo", "备注")}>
            <MyTextArea disabled={readonly} style={{ height: "100px", width: FORM_ITEM_WIDTH }} value={formData.memo} placeholder={l("common_memo_placeholder_input_reason", "请输入原因")} onChange={function (v) { return onFormChange(v.target.value, "memo"); }}/>
          </MyFormItem>
        </MyItemWrapper>
      </MyFormWrapper>
      <Col>
        <CommonTips>{l("profiling_profile_edit_tips", "预计完成时间为3个工作日")}</CommonTips>
      </Col>
      {readonly ? null : <FooterBtnWrapper buttonList={[
        {
            type: "primary",
            text: l("common_save", "Save"),
            onClick: function () { return save(); },
            disabled: checkSaveDisabled(),
            loading: saving
        }
    ]}/>}
    </CommonWrapper>);
};
var IconWrapper = styled(InfoCircleOutlined)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: -14px;\n  font-size: 12px;\n"], ["\n  margin-top: -14px;\n  font-size: 12px;\n"])));
var DescSelectTips = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #aaa;\n  font-size: 12px;\n"], ["\n  color: #aaa;\n  font-size: 12px;\n"])));
var templateObject_1, templateObject_2;
