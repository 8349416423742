var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import { SegmentList } from "@pages/profiling/profile/manage/components/segmentList";
import { ComparisonList } from "@pages/profiling/profile/manage/components/comparisonList";
import { l } from "@helpers/multiLangaugeHelper";
import { useParams } from "react-router-dom";
import { PROFILE_OBJ } from "@pages/profiling/profile/helpers/const";
var TabPane = Tabs.TabPane;
export var ProfilingManage = function () {
    var profileType = useParams().profileType;
    return (<>
      <TabWrapper defaultActiveKey="1">
        <TabPane tab={PROFILE_OBJ()[profileType].tabPaneText} key="1">
          <SegmentList />
        </TabPane>
        <TabPane tab={l("profiling_profile_comparison_list", "Comparison List")} key="2">
          <ComparisonList />
        </TabPane>
      </TabWrapper>
    </>);
};
var TabWrapper = styled(Tabs)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > .ant-tabs-nav {\n    margin: 0;\n }\n & .ant-tabs-nav-wrap {\n  padding-left: 32px;\n  background: #fff;\n }\n"], ["\n  > .ant-tabs-nav {\n    margin: 0;\n }\n & .ant-tabs-nav-wrap {\n  padding-left: 32px;\n  background: #fff;\n }\n"])));
var templateObject_1;
