var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { MyTable } from "@components/myTable/myTable";
import { columns } from "./helpers/initData";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
import { MySelect } from "@components/baseComponents/mySelect";
import { Button, Form } from "antd";
import { getList, submitApi, completeApi } from "./helpers/async";
import { filter, map, merge, set } from "lodash";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { BatchUpload, UPLOAD_TYPE_LIST } from "@components/batchUpload/batchUpload";
import { getItemLayout } from "@helpers/const";
import { COMMON_OPTIONS_TYPE } from "@/common/const";
import { MyDatePicker } from "@components/baseComponents/myDatePicker";
import { TableWrapper } from "@/components/common/tableWrapper";
import { MyTableSearch } from "@/components/baseComponents/myTableSearch";
import { MyModal } from "@components/baseComponents/myModal";
var FormItem = Form.Item;
var DEFAULT_TYPE = 1;
export var MProfilingAdminManage = function () {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState({
        type: DEFAULT_TYPE
    }), conditions = _b[0], setConditions = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState({
        pageSize: 10,
        current: 1,
    }), page = _d[0], setPage = _d[1];
    var _e = useState([]), sourceList = _e[0], setSourceList = _e[1];
    var _f = useState(false), uploadModalVisible = _f[0], setUploadModalVisible = _f[1];
    var _g = useState({}), uploadModalData = _g[0], setUploadModalData = _g[1];
    var _h = useState([]), submitFlag = _h[0], setSubmitFlag = _h[1];
    useEffect(function () {
        onSearch(conditions, page);
    }, []);
    var onConditionsChange = function (v, pathKey, noSearch) {
        var newConditions = merge({}, conditions);
        set(newConditions, pathKey, v);
        setConditions(newConditions);
        if (!noSearch) {
            onSearch(newConditions, merge({}, page, { current: 1 }));
        }
    };
    var onSearch = function (conditions, page) {
        var data = __assign({ conditions: conditions }, page);
        setConditions(conditions);
        setLoading(true);
        getList(data)
            .then(function (res) {
            setData(res.data.list);
            setPage(merge({}, page, { total: res.data.total }));
            setSourceList(res.data.sourceList);
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var openUploadModal = function (record) {
        setUploadModalData(record);
        setUploadModalVisible(true);
    };
    var forceComplete = function (record) {
        completeApi(record)
            .then(function (res) {
            onSearch(conditions, page);
            msgSuccess(res);
        })
            .catch(msgError);
    };
    var onUploadResponse = function (key) {
        var newSubmitFlag = merge([], submitFlag);
        if (newSubmitFlag.includes(key))
            return;
        newSubmitFlag.push(key);
        setSubmitFlag(newSubmitFlag);
    };
    var renderUploadItem = function () {
        return map(sourceList, function (item) {
            if (item.needUpload) {
                return <UploadItemWrapper label={item.title} {...getItemLayout(7)}>
          <BatchUpload accept={".xls,.xlsx"} maxCount={1} url={UPLOAD_TYPE_LIST.MPROFILE_ADMIN} params={{ uploadType: item.key, taskId: uploadModalData.taskId }} onResponse={function () { return onUploadResponse(item.key); }}/>
        </UploadItemWrapper>;
            }
            return null;
        });
    };
    var submit = function () {
        submitApi(uploadModalData)
            .then(function (res) {
            msgSuccess(res);
            setSubmitFlag([]);
            setUploadModalVisible(false);
        })
            .catch(msgError);
    };
    var checkSaveDisabled = function () {
        var needUploadLength = filter(sourceList, function (item) { return item.needUpload; }).length;
        if (submitFlag.length !== needUploadLength)
            return true;
        return false;
    };
    var initColumns = function (columns) {
        var newColumns = merge([], columns);
        newColumns.splice.apply(newColumns, __spreadArrays([newColumns.length - 2, 0], sourceList));
        return newColumns;
    };
    var applyObject = {
        upload: openUploadModal,
        complete: forceComplete
    };
    var tableProps = {
        data: data,
        columns: initColumns(columns()),
        page: page,
        loading: loading,
        applyObject: applyObject,
        onChange: function (page) { return onSearch(conditions, page); },
    };
    return (<>
      <TableWrapper>
        <MyTableSearch placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onSearch({ keyword: value }, merge({}, page, { current: 1 })); }} suffix={<><MySelect url={"/groupme/common/getCommonOptions"} params={{ type: COMMON_OPTIONS_TYPE.PROFILING_STATUS }} placeholder={l("profiling_adminManage_status", "Status")} onChange={function (v) { return onConditionsChange(v, "status"); }}/>
          <MySelect url={"/groupme/common/advertiser/getAdvertiserOptions"} placeholder={l("common_advertiser", "Advertiser")} onChange={function (v) { return onConditionsChange(v, "advertiserId"); }} style={{ margin: "0 10px" }}/>
          <MyDatePicker onChange={function (v) { return onConditionsChange(v, "createDate"); }}/></>}/>
        <MyTable {...tableProps}/>
      </TableWrapper>
      {uploadModalVisible ? <MyModal visible={uploadModalVisible} maskClosable={false} title={l("profiling_adminManage_uploadModal_title", "Loading For Segment ID")} footer={<>
          <Button type={"primary"} disabled={checkSaveDisabled()} onClick={submit}>
            {l("common_submit", "提交")}
          </Button>
        </>} onCancel={function () {
        setSubmitFlag([]);
        setUploadModalVisible(false);
    }}>
        {renderUploadItem()}
      </MyModal> : null}
    </>);
};
var UploadItemWrapper = styled(FormItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 16px;\n  padding: 14px !important;\n  border: 1px solid #ddd;\n"], ["\n  margin-bottom: 16px;\n  padding: 14px !important;\n  border: 1px solid #ddd;\n"])));
var templateObject_1;
