var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CommonWrapper } from "@components/common/commonWrapper";
import { getImgUrl } from "@helpers/const";
import styled from "styled-components";
export var CaasPage = function () {
    var ico = document.getElementById("ico");
    var icoUrl = "/objects/groupm/img/favicon/groupm.ico";
    ico.href = icoUrl;
    return (<Wrapper>
    <img className={"img"} src={getImgUrl("demo/demo_page1.png")}/>
  </Wrapper>);
};
var Wrapper = styled(CommonWrapper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .img {\n    display: block;\n    margin: auto;\n    max-width: 1340px;\n  }\n"], ["\n  .img {\n    display: block;\n    margin: auto;\n    max-width: 1340px;\n  }\n"])));
var templateObject_1;
