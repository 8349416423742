import axios from "axios";
import { message } from "antd";
import { ENV_URL } from "@helpers/const";
import { map } from "lodash";
var RESPONSE_STATUS_CODE = {
    SUCCESS: 200,
    ERROR: 500,
    NOT_FOUND: 404,
    NO_PERMISSION: 403,
    BUSY: 429
};
export var RESPONSE_CODE = {
    SUCCESS: 10000
};
export var initPostUrl = function (url) {
    if (url.indexOf("http") > -1) {
        return url;
    }
    if (window.location.origin.indexOf("localhost") > -1) {
        return "" + ENV_URL.mock + url;
        // return `${ENV_URL.development}${url}`
    }
    return "" + ENV_URL[process.env.NODE_ENV] + url;
};
export var CancelPost = axios.CancelToken.source();
export function post(url, data) {
    return new Promise(function (resolve, reject) {
        // axios.post(initPostUrl(url), data)
        axios({
            method: "post",
            url: initPostUrl(url),
            data: data,
            cancelToken: CancelPost.token
        })
            .then(function (res) {
            getResponse(res, resolve, reject);
            // resolve(res.static);
        })
            .catch(function (err) {
            if (axios.isCancel(err)) {
                console.log(err);
            }
            reject(err);
        });
    });
}
export var allPost = function (post) {
    return new Promise(function (resolve, reject) {
        axios.all(post)
            .then((axios.spread(function () {
            var resList = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                resList[_i] = arguments[_i];
            }
            var resData = map(resList, function (res) {
                return res.data;
            });
            resolve(resData);
        })))
            .catch(function (err) {
            reject(err);
        });
    });
};
var getResponse = function (res, resolve, reject) {
    if (res.status !== RESPONSE_STATUS_CODE.SUCCESS) {
        if (res.status === RESPONSE_STATUS_CODE.NO_PERMISSION) {
            location.href = "/403";
            return;
        }
        if (res.status === RESPONSE_STATUS_CODE.BUSY) {
            location.href = "/429";
            return;
        }
        reject(res.statusText);
        return;
    }
    initResponseData(res.data, resolve, reject);
};
var initResponseData = function (res, resolve, reject) {
    if (res.code === RESPONSE_CODE.SUCCESS) {
        resolve(res);
        return;
    }
    reject(res.msg);
    // msgError(res.msg)
};
export var msgSuccess = function (res) {
    if (typeof res === "string") {
        message.success(res);
    }
    else if (res && res.msg) {
        message.success(res.msg);
    }
};
export var msgError = function (err) {
    if (err && err.response && err.response.data && err.response.data.code !== RESPONSE_STATUS_CODE.SUCCESS) {
        message.error(err.response.data.msg);
    }
    else if (err && err.response && err.response.statusText !== "") {
        message.error(err.response.statusText);
    }
    else if (typeof err === "string") {
        message.error(err);
    }
    else {
        message.error(err);
    }
};
export var msgInfo = function (err) {
    if (err && err.response && err.response.data && err.response.data.code !== RESPONSE_STATUS_CODE.SUCCESS) {
        message.info(err.response.data.msg);
    }
    else if (err && err.response && err.response.statusText !== "") {
        message.info(err.response.statusText);
    }
    else if (typeof err === "string") {
        message.info(err);
    }
    else {
        message.info(err);
    }
};
