import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
export var columns = function () { return [
    {
        key: "segmentName",
        fixed: "left",
        title: l("common_audience_name", "人群名称"),
        render: [
            {
                key: "segmentName",
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
                // options: {
                //   path: "/techHub/audience/standardAudience/view",
                //   params: {
                //     id: "taskId"
                //   }
                // },
                style: { fontSize: "15px" },
            },
            {
                key: "taskId",
                label: l("common_task_code", "任务Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
            {
                key: "segmentId",
                label: l("common_audience_id", "人群Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "advertiser",
        title: l("common_advertiser", "广告主")
    },
    {
        key: "sourceName",
        title: l("common_data_source", "数据源"),
    },
    {
        key: "audienceSize",
        align: "right",
        title: l("common_audience_size", "人群规模"),
        render: [
            {
                key: "audienceSize",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                    format: "0,0"
                }
            }
        ]
    },
    {
        key: "statusName",
        title: l("common_status", "状态"),
        render: [
            {
                key: "statusName",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "lastUpdate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
            },
        ]
    },
]; };
export var list = [
    {
        taskId: 1,
        segmentName: "Fitness",
        segmentId: 100,
        advertiser: "Demo",
        sourceName: "Talking Data",
        audienceSize: 100000000,
        statusName: "已完成",
        statusId: 1,
        lastUpdateName: "techdata.mplatformcn@groupm.com",
        lastUpdateDate: "2022-12-12",
    },
    {
        taskId: 1,
        segmentName: "High end_translator",
        segmentId: 100,
        advertiser: "Demo",
        sourceName: "Talking Data",
        audienceSize: 100000000,
        statusName: "已完成",
        statusId: 1,
        lastUpdateName: "techdata.mplatformcn@groupm.com",
        lastUpdateDate: "2022-12-12",
    },
];
