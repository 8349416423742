var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import _ from "lodash";
import { Chart, Geom, Tooltip, Coordinate, Legend, Axis, Interaction, G2, registerShape } from "bizcharts";
import DataSet from "@antv/data-set";
// 给point注册一个词云的shape
function getTextAttrs(cfg) {
    return _.assign({}, cfg.style, {
        fontSize: cfg.data.size,
        text: cfg.data.text,
        textAlign: "center",
        fontFamily: cfg.data.font,
        fill: cfg.color,
        textBaseline: "Alphabetic"
    });
}
registerShape("point", "cloud", {
    draw: function (cfg, container) {
        // console.log('cloud cfg', cfg);
        var attrs = getTextAttrs(cfg);
        var textShape = container.addShape("text", {
            attrs: _.assign(attrs, {
                x: cfg.x,
                y: cfg.y
            })
        });
        if (cfg.data.rotate) {
            G2.Util.rotate(textShape, cfg.data.rotate * Math.PI / 180);
        }
        return textShape;
    }
});
var data = [
    {
        "x": "玻尿酸",
        "value": 13832,
        "category": "composition"
    }, {
        "x": "视黄醇",
        "value": 1316,
        "category": "composition"
    }, {
        "x": "视黄醛",
        "value": 3249,
        "category": "composition"
    }, {
        "x": "A酸",
        "value": 2635,
        "category": "composition"
    }, {
        "x": "积雪草",
        "value": 2075,
        "category": "composition"
    }, {
        "x": "胶原蛋白",
        "value": 13832,
        "category": "composition"
    }, {
        "x": "蛋白",
        "value": 1316,
        "category": "composition"
    }, {
        "x": "肌肽",
        "value": 3249,
        "category": "composition"
    }, {
        "x": "A醛",
        "value": 2635,
        "category": "composition"
    }, {
        "x": "神经酰胺",
        "value": 2075,
        "category": "composition"
    }, {
        "x": "植物",
        "value": 13832,
        "category": "composition"
    }, {
        "x": "酵母",
        "value": 1316,
        "category": "composition"
    }, {
        "x": "维生素",
        "value": 3249,
        "category": "composition"
    }, {
        "x": "黄金",
        "value": 2635,
        "category": "composition"
    }, {
        "x": "二裂酵母",
        "value": 2075,
        "category": "composition"
    }, {
        "x": "角鲨烷",
        "value": 1964,
        "category": "composition"
    }, {
        "x": "a醇",
        "value": 1918,
        "category": "composition"
    }, {
        "x": "双重包裹技术",
        "value": 2075,
        "category": "composition"
    }, {
        "x": "洗面奶",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "乳液",
        "value": 1918,
        "category": "skin"
    }, {
        "x": "水乳",
        "value": 2075,
        "category": "skin"
    }, {
        "x": "精华",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "眼霜",
        "value": 1918,
        "category": "skin"
    }, {
        "x": "面膜",
        "value": 815,
        "category": "skin"
    }, {
        "x": "补水面膜",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "防晒",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "早C晚A",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "化妆品",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "高光",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "凝胶",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "喷雾",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "安瓶",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "柔肤水",
        "value": 1964,
        "category": "skin"
    }, {
        "x": "水乳套装",
        "value": 1964,
        "category": "skin"
    }
];
var Wordcloud = /** @class */ (function (_super) {
    __extends(Wordcloud, _super);
    function Wordcloud() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Wordcloud.prototype.render = function () {
        var dv = new DataSet.View().source(data);
        var range = dv.range("value");
        var min = range[0];
        var max = range[1];
        dv.transform({
            type: "tag-cloud",
            fields: ["x", "value"],
            size: [600, 500],
            font: "Verdana",
            padding: 0,
            timeInterval: 5000,
            rotate: function () {
                // tslint:disable-next-line: no-bitwise
                var random = ~~(Math.random() * 4) % 4;
                if (random === 2) {
                    random = 0;
                }
                return random * 90; // 0, 90, 270
            },
            fontSize: function (d) {
                if (d.value) {
                    return ((d.value - min) / (max - min)) * (40 - 12) + 12;
                }
                return 0;
            }
        });
        var scale = {
            x: {
                nice: false
            },
            y: {
                nice: false
            }
        };
        return (<Chart width={800} height={500} margin={"auto"} data={dv.rows} scale={scale} padding={0} autoFit={false}>
				<Tooltip showTitle={false}/>
				<Coordinate reflect="y"/>
				<Axis name="x" visible={false}/>
				<Axis name="y" visible={false}/>
				<Legend visible={false}/>
				<Geom type="point" position="x*y" color="category" shape="cloud" tooltip="value*category"/>
				<Interaction type="element-active"/>
			</Chart>);
    };
    return Wordcloud;
}(React.Component));
export { Wordcloud };
