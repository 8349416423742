var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";
import { getOpcoTitle, sendForgetMail } from "@pages/account/helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { useHistory } from "react-router-dom";
import { validateUsername } from "@pages/account/helpers/validator";
import { l } from "@helpers/multiLangaugeHelper";
import { getImgUrl } from "@helpers/const";
import queryString from "query-string";
var loadingTime = 0;
export var ForgetPassword = function () {
    var history = useHistory();
    var hasUsername = queryString.parse(location.search).username;
    var _a = useState(hasUsername), username = _a[0], setUsername = _a[1];
    var _b = useState(0), time = _b[0], setTime = _b[1];
    useEffect(function () {
        getOpcoTitle("groupm")
            .then(function (res) {
            window.opcoTitle = res.data.opcoTitle || res.data.opco;
            document.title = res.data.opcoTitle || res.data.opco;
        });
    }, []);
    var submit = function () {
        var data = { username: username, languageCode: window.LANG };
        if (errorMsg) {
            return;
        }
        if (!(username && username !== "")) {
            msgError(l("forget_password_email_err", "请输入用户名邮箱"));
            return;
        }
        startLoading();
        sendForgetMail(data)
            .then(function (res) {
            msgSuccess(res);
            history.push("/enter");
        })
            .catch(function (err) {
            msgError(err);
        });
    };
    var startLoading = function () {
        loadingTime = 60;
        setTime(loadingTime);
        var loadingLoop = setInterval(function () {
            loadingTime = loadingTime - 1;
            setTime(loadingTime);
            if (loadingTime === 0) {
                clearTimeout(loadingLoop);
                setTime(0);
            }
        }, 1000);
    };
    var errorMsg = validateUsername(username);
    return <Wrapper>
    <EnterWrapperLeft>
      <BusinessLogo src={getImgUrl("groupm_logo.png")} alt="groupm_logo"/>
      <WelcomeText1>{l("enter_welcome_to_text", "Welcome to")}</WelcomeText1>
      <WelcomeText2>{l("enter_system_name", "GroupM Engine")}</WelcomeText2>
    </EnterWrapperLeft>
    <EnterWrapperRight>
      <EnterContentWrapper>
        <Title>{l("forget_password_title", "Reset password")}</Title>
        <SubTitle>{l("forget_password_sub_title", "The system will send a verification email to the email address")}</SubTitle>
        <InputWrapper>
          <EnterInput placeholder={l("forget_password_email_placeholder", "Please enter email address")} value={username} onChange={function (v) { return setUsername(v.target.value); }} hasError={errorMsg}/>
          {errorMsg ? <InputError>{errorMsg}</InputError> : null}
        </InputWrapper>
        <EnterBtn type="primary" loading={time > 0} disabled={time > 0} onClick={submit}>
          {time > 0 ? time + "s" : l("forget_password_send_email", "Send verification email")}
        </EnterBtn>
      </EnterContentWrapper>
    </EnterWrapperRight>
  </Wrapper>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  background: #ccc url(", ") no-repeat center;\n  background-size: cover;\n"], ["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  background: #ccc url(", ") no-repeat center;\n  background-size: cover;\n"])), getImgUrl("enter_background.png"));
var EnterWrapperLeft = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  margin: 0 70px 0 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  margin: 0 70px 0 0;\n"])));
var BusinessLogo = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 62px;\n  left: 77px;\n  width: 144px;\n  height: 34px;\n  /* margin: 0 0 480px 0; */\n  display: inline-block;\n"], ["\n  position: absolute;\n  top: 62px;\n  left: 77px;\n  width: 144px;\n  height: 34px;\n  /* margin: 0 0 480px 0; */\n  display: inline-block;\n"])));
var WelcomeText1 = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @keyframes amoves {\n    0%{opacity: 0;left: 77px;bottom: 100px;}\n    100%{opacity: 1;left: 77px;bottom: 150px;}\n  }\n  position: absolute;\n  font-size: 76px;\n  font-family: Helvetica;\n  color: #FFFFFF;\n  font-weight: 600;\n  min-width: 499px;\n  height: 66px;\n  line-height: 42px;\n  animation: amoves .7s linear;\n  animation-fill-mode: forwards;\n"], ["\n  @keyframes amoves {\n    0%{opacity: 0;left: 77px;bottom: 100px;}\n    100%{opacity: 1;left: 77px;bottom: 150px;}\n  }\n  position: absolute;\n  font-size: 76px;\n  font-family: Helvetica;\n  color: #FFFFFF;\n  font-weight: 600;\n  min-width: 499px;\n  height: 66px;\n  line-height: 42px;\n  animation: amoves .7s linear;\n  animation-fill-mode: forwards;\n"])));
var WelcomeText2 = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  @keyframes bmoves {\n    0%{opacity: 0;left: 77px;bottom: 50px;}\n    50%{opacity: 0;left: 77px;bottom: 50px;}\n    100%{opacity: 1;left: 77px;bottom: 70px;}\n  }\n  position: absolute;\n  font-size: 50px;\n  font-family: Helvetica;\n  color: #FFFFFF;\n  height: 96px;\n  line-height: 66px;\n  min-width: 443px;\n  animation: bmoves .7s .3s linear;\n  animation-fill-mode: forwards;\n"], ["\n  @keyframes bmoves {\n    0%{opacity: 0;left: 77px;bottom: 50px;}\n    50%{opacity: 0;left: 77px;bottom: 50px;}\n    100%{opacity: 1;left: 77px;bottom: 70px;}\n  }\n  position: absolute;\n  font-size: 50px;\n  font-family: Helvetica;\n  color: #FFFFFF;\n  height: 96px;\n  line-height: 66px;\n  min-width: 443px;\n  animation: bmoves .7s .3s linear;\n  animation-fill-mode: forwards;\n"])));
var EnterWrapperRight = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n  margin: 0 0 0 76px;\n"], ["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n  margin: 0 0 0 76px;\n"])));
var EnterContentWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  overflow: hidden;\n  height: 100%;\n"], ["\n  overflow: hidden;\n  height: 100%;\n"])));
var EnterInput = styled(Input)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100% !important;\n  height: 42px !important;\n  border-color:  ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"], ["\n  width: 100% !important;\n  height: 42px !important;\n  border-color:  ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"])), function (props) { return props.hasError ? "#f20 !important" : "#D2D2D2 !important"; });
var InputWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  margin-top: 26px;\n  text-align: center;\n  font-size: 22px;\n"], ["\n  position: relative;\n  margin-top: 26px;\n  text-align: center;\n  font-size: 22px;\n"])));
var InputError = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color:#f20;\n"], ["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color:#f20;\n"])));
var EnterBtn = styled(Button)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin: 28px auto auto auto;\n  display: block;\n  color: #fff;\n  width: 430px !important;\n  height: 48px;\n  background: #1890FF;\n  border-radius: 4px !important;\n  font-size: 16px;\n"], ["\n  margin: 28px auto auto auto;\n  display: block;\n  color: #fff;\n  width: 430px !important;\n  height: 48px;\n  background: #1890FF;\n  border-radius: 4px !important;\n  font-size: 16px;\n"])));
var Title = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  font-size: 26px;\n  text-align: center;\n"], ["\n  margin-bottom: 10px;\n  font-size: 26px;\n  text-align: center;\n"])));
var SubTitle = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-size: 16px;\n"], ["\n  font-size: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
