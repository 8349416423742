var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { px } from "@/utils/unit";
import numeral from "numeral";
var NUMBER_HEIGHT = 54;
var TIME_ALL = 1000;
var TIME_STEP = 50;
var NumberItem = function (_a) {
    var value = _a.value;
    return (<NumberWrapper>
      {value}
    </NumberWrapper>);
};
export var NumberBoard = function (_a) {
    var value = _a.value;
    var _b = useState(0), showValue = _b[0], setShowValue = _b[1];
    var timer;
    var nowValue = showValue;
    useEffect(function () {
        if (value) {
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
            var diffTimes_1 = TIME_ALL / TIME_STEP;
            var diffValue_1 = (value - showValue) / diffTimes_1;
            nowValue = showValue;
            timer = setInterval(function () {
                if (diffTimes_1 > 0) {
                    nowValue = nowValue + diffValue_1;
                    setShowValue(nowValue);
                    diffTimes_1--;
                }
                else {
                    clearInterval(timer);
                    timer = null;
                }
            }, TIME_STEP);
        }
    }, [value]);
    var newValue = value ? Math.round(showValue) : null;
    return (<Wrapper>
    {newValue || newValue === 0 ?
        numeral(Math.abs(newValue)).format("0,0").split("")
            .map(function (item) { return item === "," ? <PointSpan>,</PointSpan> : (<NumberItem value={Number(item)}/>); }) :
        <NumberItem value={0}/>}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: ", " 0;\n  overflow: hidden;\n"], ["\n  margin: ", " 0;\n  overflow: hidden;\n"])), px(6));
var PointSpan = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: ", ";\n  margin-top: 10px;\n  float: left;\n  font-size: ", ";\n"], ["\n  margin-right: ", ";\n  margin-top: 10px;\n  float: left;\n  font-size: ", ";\n"])), px(4), px(40));
var NumberWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  margin: 0 ", " 0 0;\n  display: inline-block;\n  overflow: hidden;\n  float: left;\n  height: ", ";\n  width: ", ";\n  line-height: ", ";\n  border: ", " solid #5757FF;\n  font-weight: bold;\n  font-size: ", ";\n  text-align: center;\n"], ["\n  position: relative;\n  margin: 0 ", " 0 0;\n  display: inline-block;\n  overflow: hidden;\n  float: left;\n  height: ", ";\n  width: ", ";\n  line-height: ", ";\n  border: ", " solid #5757FF;\n  font-weight: bold;\n  font-size: ", ";\n  text-align: center;\n"])), px(4), px(NUMBER_HEIGHT), px(44), px(NUMBER_HEIGHT), px(1), px(38));
var templateObject_1, templateObject_2, templateObject_3;
