var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
import { getItemLayout, initTextList } from "@helpers/const";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { getOpcoTitle, submitRegister } from "@pages/account/helpers/async";
import { merge, set } from "lodash";
import { MySelect } from "@/components/baseComponents/mySelect";
import { MyCheckBox } from "@components/baseComponents/myCheckBox";
import { MyInput } from "@components/baseComponents/myInput";
var FormItem = Form.Item;
var itemLayout = getItemLayout(5, 18);
var TextArea = Input.TextArea;
export var Register = function () {
    var forceRequest = queryString.parse(location.search).forceRequest;
    var opco = useParams().opco;
    var history = useHistory();
    var email = queryString.parse(location.search).email;
    var _a = useState({}), formData = _a[0], setFormData = _a[1];
    var _b = useState(false), submitting = _b[0], setSubmitting = _b[1];
    useEffect(function () {
        if (location.host.indexOf("www") === -1 && !forceRequest) {
            location.href = "https://www.os.choreograph.cn" + location.pathname;
            return;
        }
        if (window.opcoTitle) {
            document.title = window.opcoTitle;
        }
        else {
            getOpcoTitle(opco || "groupm")
                .then(function (res) {
                window.opcoTitle = res.data.opcoTitle || res.data.opco;
                document.title = res.data.opcoTitle || res.data.opco;
            });
        }
        if (email) {
            setFormData({ email: email });
        }
    }, []);
    var onFormDataChange = function (value, pathKey, callback) {
        var newData = merge({}, formData, { languageCode: window.LANG });
        if (pathKey === "clients") {
            var list = value.split("\n");
            if (list.length === 1 && list[0] === "") {
                list = null;
            }
            set(newData, pathKey, list);
            setFormData(newData);
            return;
        }
        set(newData, pathKey, value);
        setFormData(newData);
        callback ? callback(value, newData) : null;
    };
    var onSubmit = function () {
        var _a;
        if (sameErr || emailErr || lineManagerEmailErr) {
            return;
        }
        if (!formData.email) {
            return msgError(l("account_register_email_noInput_err", "Please input your email"));
        }
        if (!formData.lineManagerEmail) {
            return msgError(l("account_register_lineManager_noInput_err", "Please input your line manager email"));
        }
        if (!formData.agency) {
            return msgError(l("account_register_agency_err", "Please select your agency"));
        }
        if (!formData.clients || formData.clients.length === 0) {
            return msgError(l("account_register_clients_err", "Please select your clients"));
        }
        if (!formData.role) {
            return msgError(l("account_register_role_err", "Please select your role"));
        }
        if (!(((_a = formData.products) === null || _a === void 0 ? void 0 : _a.length) > 0 || formData.otherProducts)) {
            return msgError(l("account_register_product_err", "Please select your products"));
        }
        setSubmitting(true);
        submitRegister(formData)
            .then(function (res) {
            msgSuccess(res);
            setTimeout(function () { return history.push(opco ? "/enter/" + opco : "/enter"); }, 500);
        })
            .catch(function (err) {
            setSubmitting(false);
            msgError(err);
        });
    };
    var initCheckOptions = function (options) {
        return options.map(function (item) { return ({
            value: item,
            label: item
        }); });
    };
    var checkErr = function (value) {
        if (!value) {
            return false;
        }
        var resultList = value.match(/^[a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/);
        return !resultList;
    };
    var checkSameErr = function (email, lineManagerEmail) {
        if (email && email !== "" && lineManagerEmail && lineManagerEmail !== "") {
            return email === lineManagerEmail;
        }
        return false;
    };
    var emailErr = checkErr(formData.email);
    var lineManagerEmailErr = checkErr(formData.lineManagerEmail);
    var sameErr = checkSameErr(formData.email, formData.lineManagerEmail);
    return <Wrapper>
    <ContentWrapper>
      <PageTitle>{l("account_register_userApplication", "User Application")}</PageTitle>
      <Title>
        <Line1 />
        <span>{l("account_register_userInformation", "User Information")}</span>
        <Line1 />
      </Title>
      <FormDataWrapper>
        <div>
          <FormItem required {...itemLayout} label={l("account_register_your_email", "Your Email")}>
            <FormInputWrapper>
              <EmailInput value={formData.email} hasError={emailErr} placeholder={l("account_register_your_email", "Your Email")} onChange={function (v) { return onFormDataChange(v.target.value.toLowerCase(), "email"); }}/>
              {emailErr ? <ErrorMsg>{l("common_email_err_tips", "The mailbox form is not correct!")}</ErrorMsg> : null}
            </FormInputWrapper>
          </FormItem>
          <FormItem required {...itemLayout} label={l("account_register_lineManager_email", "Line-manager Email")}>
            <FormInputWrapper>
              <EmailInput hasError={lineManagerEmailErr} value={formData.lineManagerEmail} placeholder={l("account_register_lineManager_email", "Line-manager Email")} onChange={function (v) { return onFormDataChange(v.target.value.toLowerCase(), "lineManagerEmail"); }}/>
              {lineManagerEmailErr ?
        <ErrorMsg>{l("common_email_err_tips", "The mailbox form is not correct!")}</ErrorMsg> : null}
              {sameErr && !lineManagerEmailErr ?
        <ErrorMsg>
                  {l("common_email_same_err_tips", "Line-manager Email is same as Your Email!")}
                </ErrorMsg> : null}
            </FormInputWrapper>
            <p style={{ margin: "12px 0 0 0", fontSize: "12px" }}>
              {l("account_register_lineManager_email_tips", "Kindly inform that your superior should receive an invite to taskAdmin your application.")}
            </p>
          </FormItem>
          <FormItem required {...itemLayout} label={l("account_register_your_agency_opco", "Your Agency / Opco")}>
            <MySelect showSearch={true} style={{ width: "100%" }} placeholder={l("account_register_agency_placeholder", "GroupM Programmatic Service")} url={"/groupme/common/account/getAgencyOptionsForAccountRequest"} value={formData.agency} onChange={function (v) { return onFormDataChange(v, "agency"); }} params={{ opco: opco ? opco : "groupm" }}/>
          </FormItem>
        </div>
      </FormDataWrapper>
      <Title style={{ marginTop: "20px" }}>
        <Line2 />
        <span>{l("account_register_userRight", "User Rights")}</span>
        <Line2 />
      </Title>
      <FormDataWrapper>
        <div>
          <FormItem required {...itemLayout} label={l("account_register_your_clients", "Your Clients")}>
            <TextArea style={{ height: "200px" }} placeholder={l("account_register_clients_placeholder", "Please input your clients")} value={initTextList(formData.clients)} onChange={function (v) { return onFormDataChange(v.target.value, "clients"); }}/>
          </FormItem>
          <FormItem required {...itemLayout} label={l("account_register_your_role", "Your Role")}>
            <Input value={formData.role} placeholder={l("account_register_role_placeholder", "Please Select Your Role")} onChange={function (v) { return onFormDataChange(v.target.value, "role"); }}/>
          </FormItem>
          <FormItem required {...itemLayout} label={l("account_register_your_products", "申请产品")}>
            <ProductCheck options={initCheckOptions([
        "Audex",
        "GeoS",
        "Landscape",
        "Translator",
        "Smart Planner",
        "Copilot",
        "Measure",
        "OPManager",
        "ModAll",
        "Sage",
    ])} value={formData.products} onChange={function (v) { return onFormDataChange(v, "products"); }}/>
          </FormItem>
          <FormItem {...itemLayout} label={l("account_register_your_products_others", "Other products")}>
            <MyInput placeholder={l("account_register_other_product_tips", "如果有其他产品，请在此处填写，多个产品用'、'分割")} style={{ width: "100%" }} value={formData.otherProducts} onChange={function (v) { return onFormDataChange(v.target.value, "otherProducts"); }}/>
          </FormItem>
          <div style={{ textAlign: "center" }}>
            <SubmitBtn type={"primary"} loading={submitting} onClick={onSubmit}>
              {l("common_submit", "Submit")}
            </SubmitBtn>
          </div>
        </div>
      </FormDataWrapper>
    </ContentWrapper>
  </Wrapper>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #ddd;\n"], ["\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #ddd;\n"])));
var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  padding: 20px;\n  width: 80%;\n  background: white;\n  .ant-row.ant-form-item {\n    margin-bottom: 22px;\n  }\n"], ["\n  position: relative;\n  padding: 20px;\n  width: 80%;\n  background: white;\n  .ant-row.ant-form-item {\n    margin-bottom: 22px;\n  }\n"])));
var FormDataWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  > div {\n    width: 900px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  > div {\n    width: 900px;\n  }\n"])));
var PageTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 500;\n  text-align: center;\n"], ["\n  font-size: 18px;\n  font-weight: 500;\n  text-align: center;\n"])));
var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 10px 0;\n  margin-bottom: 10px;\n  font-size: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  span:nth-child(2) {\n    margin: 0 20px;\n  }\n"], ["\n  padding: 10px 0;\n  margin-bottom: 10px;\n  font-size: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  span:nth-child(2) {\n    margin: 0 20px;\n  }\n"])));
var EmailInput = styled(Input)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: ", ";\n  outline: none !important;\n  box-shadow: none !important;\n"], ["\n  border: ", ";\n  outline: none !important;\n  box-shadow: none !important;\n"])), function (props) { return props.hasError ? "1px solid #f20 !important" : "1px solid #ddd !important"; });
var SubmitBtn = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 30px;\n  width: 100px;\n  height: 40px;\n"], ["\n  margin-top: 30px;\n  width: 100px;\n  height: 40px;\n"])));
var FormInputWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var ErrorMsg = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  bottom: -18px;\n  font-size: 12px;\n  color: #f20;\n"], ["\n  position: absolute;\n  bottom: -18px;\n  font-size: 12px;\n  color: #f20;\n"])));
var Line1 = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: inline-block;\n  width: 288px;\n  height: 1px;\n  background: #000;\n"], ["\n  display: inline-block;\n  width: 288px;\n  height: 1px;\n  background: #000;\n"])));
var Line2 = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: inline-block;\n  width: 306px;\n  height: 1px;\n  background: #000;\n"], ["\n  display: inline-block;\n  width: 306px;\n  height: 1px;\n  background: #000;\n"])));
var ProductCheck = styled(MyCheckBox)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  .ant-checkbox-group-item {\n    width: calc(25% - 8px);\n  }\n"], ["\n  .ant-checkbox-group-item {\n    width: calc(25% - 8px);\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
