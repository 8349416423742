import { allPost, post } from "@helpers/axiosHelper";
export var getDetail = function (taskId, profileType) {
    return post("/groupme/audex/profiling/profile/getDetail", { taskId: taskId, profileType: profileType });
};
export var getPortraitDimensionOptions = function (data) {
    return post("/groupme/audex/profiling/profile/getPortraitDimensionOptions", data);
};
export var getIndustryDimensionOptions = function (data) {
    return post("/groupme/audex/profiling/profile/getIndustryDimensionOptions", data);
};
export var getDimensionOptions = function (data) {
    return allPost([getPortraitDimensionOptions(data), getIndustryDimensionOptions(data)]);
};
export var getTaskDetail = function (taskId) {
    return post("/groupme/audex/profiling/profile/getTaskDetail", { taskId: taskId });
};
export var saveApi = function (data) {
    return post("/groupme/audex/profiling/profile/saveEdit", data);
};
