var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Button } from "antd";
import { MyTable } from "@components/myTable/myTable";
import { merge } from "lodash";
import { l } from "@helpers/multiLangaugeHelper";
import { useHistory } from "react-router-dom";
import { columns, list } from "./helpers/initData";
import { TableWrapper } from "@/components/common/tableWrapper";
import { MyTableSearch } from "@/components/baseComponents/myTableSearch";
export var AudienceExplorerManage = function () {
    var history = useHistory();
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), page = _c[0], setPage = _c[1];
    var _d = useState({}), conditions = _d[0], setConditions = _d[1];
    useEffect(function () {
        onSearch(conditions, page);
    }, []);
    var onSearch = function (conditions, page) {
        var data = __assign({ conditions: conditions }, page);
        setConditions(conditions);
        setLoading(true);
        // getList(data)
        //   .then((res) => {
        setData(list);
        setPage(merge({}, page, { total: 3 }));
        setLoading(false);
        // })
        // .catch(err => {
        //   setLoading(false)
        //   msgError(err)
        // })
    };
    var create = function () {
        history.push("/techHub/audience/audienceTranslate/create");
    };
    var tableProps = {
        data: data,
        columns: columns(),
        page: page,
        loading: loading,
    };
    return (<TableWrapper>
      <MyTableSearch placeholder={l("common_keyword_placeholder", "please input keyword")} 
    // onSearch={(value: any) => onSearch({keyword: value}, merge({}, page, {current: 1}))}
    suffix={<Button type="primary" onClick={create}>
            {l("common_create_task", "新建任务")}
          </Button>}/>
      <MyTable {...tableProps}/>
    </TableWrapper>);
};
