import { l } from "@helpers/multiLangaugeHelper";
import { DATA_AMOUNT } from "@pages/creativeInsight/manage";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
import numeral from "numeral";
export var getOverviewLeftData = function () { return [
    {
        title: l("creative_total_cost", "累计花费"),
        key: "amountCost",
        icon: "icon-leijihuafei",
        formatter: function (v) { return numeral(v).format("0,0"); }
    },
    {
        title: l("creative_total_creative_count", "累计创意数"),
        key: "amountCreativeCount",
        icon: "icon-leijipuguangliang",
        formatter: function (v) { return numeral(v).format("0,0"); }
    },
    {
        title: l("creative_average_finish_video", "平均完播率"),
        key: "averageFinishRate",
        icon: "icon-pingjunwanboshuai",
        formatter: function (v) { return numeral(v).format("0.00%"); }
    },
    {
        title: l("creative_average_activation_rate", "平均投放率"),
        key: "averageActivationRate",
        icon: "icon-pingjuntoufangshuai",
        formatter: function (v) { return numeral(v).format("0.00%"); }
    },
]; };
export var getOverviewRightData = function (type) {
    if (type === DATA_AMOUNT) {
        return [
            {
                title: l("creative_total_bg_size", "累计曝光量"),
                key: "amountLight",
                icon: "icon-leijipuguangliang",
                formatter: function (v) { return numeral(v).format("0,0"); }
            },
            {
                title: l("creative_total_click_size", "累计点击量"),
                key: "amountClick",
                icon: "icon-dianjishuai",
                formatter: function (v) { return numeral(v).format("0,0"); },
                subLabel: l("creative_average_click_rate", "平均点击率"),
                subKey: "averageClickRate",
                subFormatter: function (v) { return numeral(v).format("0.00%"); }
            },
            {
                title: l("creative_total_arrive_size", "累计转化量"),
                key: "averageArriveSize",
                icon: "icon-daozhanhuafei",
                formatter: function (v) { return numeral(v).format("0,0"); },
                subLabel: l("creative_average_arrive_rate", "平均转化率"),
                subKey: "averageArriveRate",
                subFormatter: function (v) { return numeral(v).format("0.00%"); }
            },
        ];
    }
    else {
        return [
            {
                title: l("creative_total_bg_cost", "平均曝光花费"),
                key: "averageLightPrice",
                icon: "icon-leijipuguangliang",
                formatter: function (v) { return numeral(v).format("0,0.00"); },
            },
            {
                title: l("creative_total_click_cost", "平均点击花费"),
                key: "averageClickPrice",
                icon: "icon-dianjishuai",
                formatter: function (v) { return numeral(v).format("0,0.00"); },
                subLabel: l("creative_video_compete_point", "视频竞争力指数"),
                subKey: "competePoint",
                subFormatter: function (v) { return numeral(v).format("0.00"); }
            },
            {
                title: l("creative_total_arrived_cost", "平均转化花费"),
                key: "averageArrivePrice",
                icon: "icon-daozhanhuafei",
                formatter: function (v) { return numeral(v).format("0,0.00"); },
                subLabel: l("creative_video_quality_point", "视频质量指数"),
                subKey: "qualityPoint",
                subFormatter: function (v) { return numeral(v).format("0.00"); }
            },
        ];
    }
};
export var columns = function () { return [
    {
        key: "creative",
        title: l("common_creative", "创意"),
        fixed: "left",
        render: [
            {
                key: "creativeName",
                style: { fontSize: "15px" },
            },
            {
                key: "creativeId",
                label: l("common_id", "ID") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "materialTemplate",
        title: l("creative_material_template", "素材模板"),
    },
    {
        key: "advertisingInfo",
        title: l("creative_advertising_info", "广告位信息"),
    },
    {
        key: "exposureSize",
        align: "right",
        title: l("creative_exposure", "曝光"),
        render: [
            {
                key: "exposureSize",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                    format: "0,0"
                }
            }
        ]
    },
    {
        key: "clickRate",
        align: "right",
        title: l("creative_click_rate", "点击率"),
        render: [
            {
                key: "clickRate",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                    format: "0.00%"
                }
            }
        ]
    },
    {
        key: "finishRate",
        align: "right",
        title: l("creative_finish_video_rate", "完播率"),
        render: [
            {
                key: "finishRate",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                    format: "0.00%"
                }
            }
        ]
    },
    {
        key: "operation",
        title: l("common_operation", "action"),
        fixed: "right",
        render: [
            {
                key: "preview",
                text: l("common_preview", "预览"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL
            }
        ]
    }
]; };
var initData = function (data) {
    var result = [];
    data.forEach(function (item) {
        var point = [item.xAxis, item.yAxis, item.name];
        result.push(point);
    });
    return result;
};
export var pointOption = function (data, formatter) {
    var result = {
        grid: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 30,
            containLabel: true
        },
        tooltip: {
            formatter: function (v) { return formatter(v); }
        },
        xAxis: {
            boundaryGap: true,
            splitLine: {
                show: false
            },
            axisTick: {
                length: -4,
                lineStyle: {
                    color: "#ddd"
                }
            }
        },
        yAxis: {
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            }
        },
        series: [
            {
                symbolSize: 10,
                data: initData(data),
                itemStyle: {
                    color: "#117BF9"
                },
                type: "scatter"
            }
        ]
    };
    return result;
};
