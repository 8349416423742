var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import queryString from "query-string";
import { msgInfo, msgSuccess } from "@helpers/axiosHelper";
import { getSSOUserEnter } from "@pages/account/helpers/async";
export var SSOPage = function () {
    var key = useParams().key;
    var params = queryString.parse(location.search);
    var url = params.url, email = params.email, paramsProps = __rest(params, ["url", "email"]);
    var _a = useState(false), hasCheck = _a[0], setHasCheck = _a[1];
    var _b = useState(url + "?" + queryString.stringify(paramsProps)), redirectUrl = _b[0], setRedirectUrl = _b[1];
    useEffect(function () {
        getSSOUserEnter(key)
            .then(function (res) {
            msgSuccess(res);
            setHasCheck(true);
        })
            .catch(function (err) {
            msgInfo(err);
            setRedirectUrl("/register?email=" + email);
            setHasCheck(true);
        });
    }, []);
    return (<>
    {hasCheck ? <Redirect to={"" + redirectUrl}/> : null}
  </>);
};
