var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
import { ACTION_COLUMNS } from "@/common/commonAction";
export var ADDRESS_GROUP_ENUM;
(function (ADDRESS_GROUP_ENUM) {
    ADDRESS_GROUP_ENUM["MEDIA_GROUP"] = "mediaGroupId";
    ADDRESS_GROUP_ENUM["ADDRESS_GROUP"] = "addressGroupId";
    ADDRESS_GROUP_ENUM["COMPETING_ADDRESS_GROUP"] = "competingAddressGroupId";
})(ADDRESS_GROUP_ENUM || (ADDRESS_GROUP_ENUM = {}));
export var columns = function () { return [
    {
        key: "taskName",
        title: l("common_task_name", "任务名称"),
        fixed: "left",
        render: [
            {
                key: "taskName",
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                style: { fontSize: "15px" },
                options: {
                    path: "/techHub/doohplanning/view",
                    params: {
                        id: "taskId"
                    }
                }
            },
            {
                key: "taskId",
                label: l("common_id", "ID") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "advertiserName",
        title: l("common_advertiser", "广告主"),
    },
    {
        key: "addressCount",
        title: l("media_doohAdPlacement_address_count", "Address Count"),
        render: [
            {
                key: "addressCount",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT
            }
        ]
    },
    {
        key: "adCount",
        title: l("media_doohAdPlacement_ad_count", "DOOH Ad Count"),
        render: [
            {
                key: "adCount",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                }
            }
        ]
    },
    {
        key: "statusName",
        title: l("common_status", "状态"),
        render: [
            {
                key: "statusName",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "lastUpdate",
        title: l("common_last_update", "最后修改"),
        render: [
            {
                key: "lastUpdateName",
            },
            {
                key: "lastUpdateDate",
            },
        ]
    },
    {
        key: "operation",
        title: l("common_operation", "action"),
        fixed: "right",
        render: [
            {
                key: "detailItem",
                text: l("common_operation_detail", "detail"),
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                options: {
                    path: "/techHub/doohplanning/detail",
                    params: {
                        id: "taskId"
                    }
                },
                hide: function (record) { return !record.actionReport; }
            },
            {
                key: "downloadItem",
                type: TABLE_RENDER_TYPE_ENUM.APPLY,
                hide: function (record) { return !record.actionDownload; }
            },
            {
                key: "editItem",
                text: l("common_edit", "edit"),
                type: TABLE_RENDER_TYPE_ENUM.NEW_PAGE,
                options: {
                    path: "/techHub/doohplanning/edit",
                    params: {
                        id: "taskId"
                    }
                },
                hide: function (record) { return !record.actionEdit; }
            },
            {
                key: "delItem",
                text: l("common_del", "删除"),
                type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                hide: function (record) { return !record.actionCancel; },
                options: {
                    text: l("common_del_confirm", "确认删除") + "?"
                }
            },
            {
                key: "more",
                type: TABLE_RENDER_TYPE_ENUM.MORE,
                render: __spreadArrays(ACTION_COLUMNS())
            }
        ]
    },
]; };
export var addressGroupColumns = function (notCompeting, readonly) {
    var result = [
        {
            key: "cityName",
            title: l("common_area_city", "City"),
        },
        {
            key: "address",
            title: l("common_address", "Address"),
        },
        {
            key: "radiusName",
            title: l("common_radius", "Radius"),
        },
        {
            key: "operation",
            title: l("common_operation", "action"),
            fixed: "right",
            render: [
                {
                    key: "delAddressItem",
                    text: l("common_del", "删除"),
                    type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                    hide: !notCompeting,
                    options: {
                        text: l("common_del_confirm", "确认删除") + "?"
                    }
                },
                {
                    key: "delCompetingAddressItem",
                    text: l("common_del", "删除"),
                    type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                    hide: notCompeting,
                    options: {
                        text: l("common_del_confirm", "确认删除") + "?"
                    }
                },
            ]
        }
    ];
    if (readonly) {
        result.splice(result.length - 1, 1);
    }
    return result;
};
export var mediaGroupColumns = function (readonly) {
    var result = [
        {
            key: "cityName",
            title: l("common_area_city", "City"),
        },
        {
            key: "address",
            title: l("common_address", "Address"),
        },
        {
            key: "adType",
            title: l("media_doohAdPlacement_adType", "Ad Type"),
        },
        {
            key: "materialType",
            title: l("media_doohAdPlacement_materialType", "Material Type"),
        },
        {
            key: "scenario",
            title: l("media_doohAdPlacement_scenario", "Scenario"),
        },
        {
            key: "vendor",
            title: l("media_doohAdPlacement_vendor", "Vendor"),
        },
        {
            key: "price",
            title: l("common_price", "Price"),
        },
        {
            key: "adSize",
            title: l("media_doohAdPlacement_adSize", "Ad Size"),
        },
        {
            key: "adCount",
            title: l("media_doohAdPlacement_adCount", "Ad Count"),
            render: [
                {
                    key: "adCount",
                    type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                    options: {
                        formatType: "number" /* NUMBER */,
                    }
                }
            ]
        },
        {
            key: "operation",
            title: l("common_operation", "action"),
            fixed: "right",
            render: [
                {
                    key: "delMediaItem",
                    text: l("common_del", "删除"),
                    type: TABLE_RENDER_TYPE_ENUM.POP_CONFIRM,
                    options: {
                        text: l("common_del_confirm", "确认删除") + "?"
                    }
                },
            ]
        }
    ];
    if (readonly) {
        result.splice(result.length - 1, 1);
    }
    return result;
};
