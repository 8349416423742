import { l } from "@helpers/multiLangaugeHelper";
import { maxBy, orderBy } from "lodash";
import numeral from "numeral";
export var days10ChartOption = function (data, isSpending) {
    var getMaxInterval = function () {
        var _a;
        var max = ((_a = maxBy(data, function (item) { return item.yAxis; })) === null || _a === void 0 ? void 0 : _a.yAxis) || 0;
        var length = max.toFixed(0).toString().length;
        var baseNum = Math.pow(10, length - 1);
        var value = Math.ceil(max / baseNum);
        var newMax = value * baseNum;
        return newMax / 4;
    };
    return {
        grid: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20,
            containLabel: true
        },
        xAxis: {
            type: "category",
            data: data.map(function (item) { return item.xAxis; }) || [],
            boundaryGap: false,
            axisTick: {
                show: false
            },
            axisLabel: {
                color: "#fff"
            }
        },
        yAxis: {
            type: "value",
            axisLine: {
                show: true
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                color: "#fff"
            },
            min: 0,
            max: function (v) {
                var max = v.max;
                var length = max.toFixed(0).toString().length;
                var baseNum = Math.pow(10, length - 1);
                var value = Math.ceil(max / baseNum);
                return value * baseNum;
            },
            interval: getMaxInterval()
        },
        series: [
            {
                data: (data === null || data === void 0 ? void 0 : data.map(function (item) { return item.yAxis; })) || [],
                type: "line",
                smooth: true,
                color: isSpending ? "#F4DA4E" : "#F6885B",
                showSymbol: false
            }
        ]
    };
};
export var slideBarOption = function (data) {
    var newData = orderBy(data, ["value"], ["asc"]);
    var initData = function () {
        return newData.map(function (item) { return ({
            value: item.value,
            itemStyle: {
                color: TOP_COLOR[item.label] || TOP_COLOR.default
            }
        }); });
    };
    return {
        grid: {
            left: "6%",
            right: "10%",
            bottom: "3%",
            top: "2%",
            containLabel: true
        },
        xAxis: {
            type: "value",
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            max: maxBy(newData, function (item) { return item.value; }).value,
        },
        yAxis: {
            type: "category",
            data: newData.map(function (item) { return item.label; }),
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisLabel: {
                color: "#fff",
                fontSize: 14,
            }
        },
        series: [
            {
                type: "bar",
                data: initData(),
                barMaxWidth: "20%",
                showBackground: true,
                backgroundStyle: {
                    color: "rgba(0, 0, 0, 0.1)"
                },
                itemStyle: {
                    borderRadius: 20
                },
                label: {
                    show: true,
                    position: "right",
                    valueAnimation: true,
                    color: "#fff",
                    formatter: function (params) { return "" + numeral(params.value).format("0,0.0a"); }
                }
            },
        ]
    };
};
export var OTHER_MAP = function () { return [
    {
        key: "advertiser", label: l("tv_custom_audience", "Client"),
    },
    // {
    //   key: "media", label: l("tv_media_business", "Media Platform"),
    // },
    {
        key: "account", label: l("tv_active_account", "Account"),
    },
    {
        key: "strategy", label: l("tv_planning", "Strategy"),
    },
    {
        key: "creative", label: l("tv_creative", "Creative"),
    },
]; };
export var TOP_COLOR = {
    "default": "#b2c3ff",
    // agency
    "Mindshare": "#6D358A",
    "Mediacom": "#E1005D",
    "EssenceMediacom": "#E1005D",
    "Wavemaker": "#FF8000",
    "Essence": "#EA533C",
    // platform
    "ADQ": "#296AF0",
    "MP": "#8ADC4B",
    "Ocean Engine": "#37C2C9",
    "磁力引擎": "#4CFDF7",
    "Qianchuan": "#E8D782",
    "磁力金牛": "#2FADFD",
    "Weibo": "#E7920F",
    "Red Book": "#EF3D52",
    "Bilibili": "#F87FA2",
    "百度": "#E10601"
};
