var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { post } from "@helpers/axiosHelper";
import { COMMON_OPTIONS_TYPE } from "@/common/const";
export var enterLogin = function (data) {
    return post("/groupme/common/account/login", data);
};
export var sessionCheck = function (data) {
    return post("/groupme/common/account/checkSession", data);
};
export var updatePsd = function (data) {
    return post("/groupme/common/account/updatePassword", data);
};
export var sendForgetMail = function (data) {
    return post("/groupme/common/account/forgetPassword", data);
};
export var getSSOUserEnter = function (key) {
    return post("/groupme/common/account/ssoEnter", { key: key });
};
export var getEmailOptions = function () {
    return post("/groupme/common/getCommonOptions", { type: COMMON_OPTIONS_TYPE.ACCOUNT_EMAIL_DOMAIN });
};
export var submitRegister = function (data) {
    return post("/groupme/common/account/submitRegister", data);
};
// 校验AAD登录
export var AADLogin = function (data) {
    return post("/groupme/common/account/AADLogin", data);
};
export var ssoCheck = function (data) {
    return post("/sso/set-user", data);
};
export var getOpcoTitle = function (opco) {
    var languageCode = localStorage.getItem("languageCode");
    return post("/groupme/common/account/getOpcoTitle", { opco: opco, languageCode: languageCode });
};
// 校验acontroller AD登录
export var acontrollerLogin = function (data) {
    return post("/groupme/acontroller/login", data);
};
// caas
export var caasLogin = function (data) {
    return post("/groupme/caas/login", data);
};
export var wxLogin = function (data, linkData) {
    return linkData.user && linkData.yzm ? post("/groupme/common/wechat/pcLinkLogin", __assign(__assign({}, data), linkData)) : post("/groupme/common/wechat/pcLogin", data);
};
export var getWxEmailCode = function (email) {
    return post("/groupme/common/wechat/getEmailCode", { email: email });
};
export var checkEmailCode = function (data) {
    return post("/groupme/common/wechat/checkEmailCode", data);
};
