export var OVERVIEW_DATA = {
    amountCost: 933152505,
    amountCreativeCount: 1580,
    averageFinishRate: 0.1388,
    averageActivationRate: 0.7997,
    amountLight: 17780281,
    amountClick: 178274,
    averageClickRate: 0.01,
    averageArriveSize: 79042,
    averageArriveRate: 0.4434,
    averageLightPrice: 52.43,
    averageClickPrice: 5.23,
    competePoint: 4.3,
    averageArrivePrice: 12.04,
    qualityPoint: 8.2,
};
export var LIST_DATA = [
    {
        creativeId: 666627,
        creativeName: "一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 18382,
        clickRate: 0.0219,
        finishRate: 0.3,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/1.mp4"
    },
    {
        creativeId: 666608,
        creativeName: "现在连卸妆膏都加B5泛醇和玻尿酸了？轻松卸妆的同时等于皮肤spa #AHCB5卸妆膏 #dbeauty心动日 #超A实力随心游",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 180330,
        clickRate: 0.0149,
        finishRate: 0.16,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 666586,
        creativeName: "不要再买便宜的精华了！AHC这款B5肌底精华，让你持妆一整天！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 271943,
        clickRate: 0.0256,
        finishRate: 0.18,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 618570,
        creativeName: "一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 378645,
        clickRate: 0.0107,
        finishRate: 0.2,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 618540,
        creativeName: "一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 33625,
        clickRate: 0.0113,
        finishRate: 0.11,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 618527,
        creativeName: "一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 88607,
        clickRate: 0.0122,
        finishRate: 0.15,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 617678,
        creativeName: "【熬夜急救必备】AHC B5肌底精华，修护敏感泛红，赶快囤起来！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 111410,
        clickRate: 0.011,
        finishRate: 0.1,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 616056,
        creativeName: "【立减50元】AHC B5肌底精华，让你轻松拥有水光肌，价格低过双11",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 284911,
        clickRate: 0.0107,
        finishRate: 0.26,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 616054,
        creativeName: "{日期}B5肌底精华活动来了！郑秀晶都在用的精华，超好用！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 140725,
        clickRate: 0.019,
        finishRate: 0.3,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
    {
        creativeId: 615480,
        creativeName: "AHC蓝啵啵水乳又送了！让我直呼好家伙！",
        materialTemplate: "竖版视频_宽高比9:16",
        advertisingInfo: "抖音信息流（广告投放）",
        exposureSize: 56320,
        clickRate: 0.0137,
        finishRate: 0.1,
        videoSrc: "https://tempdata.s3.cn-north-1.amazonaws.com.cn/creative_tagging/ahc_oct_imp_top40/40.mp4"
    },
];
export var CONDITIONS_OPTIONS = [
    {
        label: "基准指标", value: 1, children: [
            { label: "曝光数", value: 11 },
            { label: "点击数", value: 12 },
            { label: "CTR", value: 13 },
            { label: "CPM", value: 14 },
            { label: "CPC", value: 15 },
        ]
    },
    {
        label: "转化指标", value: 2, children: [
            { label: "转化率", value: 21 },
            { label: "CVR", value: 22 },
        ]
    },
    {
        label: "互动指标", value: 3, children: [
            { label: "互动数", value: 31 },
            { label: "互动率", value: 32 },
            { label: "点赞数", value: 33 },
            { label: "评论数", value: 34 },
            { label: "转发数", value: 35 },
        ]
    },
];
export var POINT_DATA = [
    { xAxis: 18382, yAxis: 402, name: "(ID:666627)一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！" },
    { xAxis: 180330, yAxis: 2686, name: "(ID:666608)现在连卸妆膏都加B5泛醇和玻尿酸了？轻松卸妆的同时等于皮肤spa #AHCB5卸妆膏 #dbeauty心动日 #超A实力随心游" },
    { xAxis: 271943, yAxis: 6961, name: "(ID:666586)不要再买便宜的精华了！AHC这款B5肌底精华，让你持妆一整天！" },
    { xAxis: 378645, yAxis: 4051, name: "(ID:618570)一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！" },
    { xAxis: 33625, yAxis: 380, name: "(ID:618540)一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！" },
    { xAxis: 88607, yAxis: 1081, name: "(ID:618527)一直舍不得买的AHC B5精华，终于降价了，低过双11，快抢！" },
    { xAxis: 111410, yAxis: 1225, name: "(ID:617678)【熬夜急救必备】AHC B5肌底精华，修护敏感泛红，赶快囤起来！" },
    { xAxis: 284911, yAxis: 3049, name: "(ID:616056)【立减50元】AHC B5肌底精华，让你轻松拥有水光肌，价格低过双11" },
    { xAxis: 140725, yAxis: 2674, name: "(ID:616054){日期}B5肌底精华活动来了！郑秀晶都在用的精华，超好用！" },
    { xAxis: 56320, yAxis: 772, name: "(ID:615480)AHC蓝啵啵水乳又送了！让我直呼好家伙！" },
];
