var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MySpin } from "@components/baseComponents/mySpin";
import { MyFormWrapper, MyItemWrapper } from "@components/common/myFormWrapper";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import { l } from "@helpers/multiLangaugeHelper";
import { MyInput } from "@components/baseComponents/myInput";
import { MySelect } from "@components/baseComponents/mySelect";
import { merge, remove, set } from "lodash";
import { FooterBtnWrapper } from "@components/common/footerBtnWrapper";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { useHistory } from "react-router-dom";
import { MyTableSearch } from "@components/baseComponents/myTableSearch";
import { MyTable } from "@components/myTable/myTable";
import { columns } from "./helpers/initData";
import { getDetail, getList, saveApi } from "./helpers/async";
import { TableWrapper } from "@/components/common/tableWrapper";
import styled from "styled-components";
import { Tag, Tooltip } from "antd";
import { MyCascader } from "@components/baseComponents/myCascader";
import queryString from "query-string";
import { MyRadio } from "@components/baseComponents/myRadio";
import { InfoCircleOutlined } from "@/utils/antdIcon";
export var AppAudienceEdit = function (_a) {
    var readonly = _a.readonly;
    var DEFAULT_TYPE = "install";
    var _b = queryString.parse(location.search), id = _b.id, isCopy = _b.isCopy;
    var history = useHistory();
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState({ typeId: DEFAULT_TYPE }), formData = _d[0], setFormData = _d[1];
    var _e = useState(false), saving = _e[0], setSaving = _e[1];
    var _f = useState(false), nameError = _f[0], setNameError = _f[1];
    var _g = useState([]), data = _g[0], setData = _g[1];
    var _h = useState(false), tableLoading = _h[0], setTableLoading = _h[1];
    var _j = useState({
        total: null,
        pageSize: 10,
        current: 1,
    }), page = _j[0], setPage = _j[1];
    var _k = useState({}), conditions = _k[0], setConditions = _k[1];
    useEffect(function () {
        if (id) {
            setLoading(true);
            getDetail(Number(id))
                .then(function (res) {
                setFormData(res.data);
                setLoading(false);
                if (isCopy) {
                    delete formData.taskId;
                    delete formData.segmentId;
                    formData.segmentName = formData.segmentName + "_copy";
                }
            })
                .catch(function (err) {
                setLoading(false);
                msgError(err);
            });
        }
        onSearch(conditions, page);
    }, []);
    useEffect(function () {
        if (formData.sourceId) {
            onSearch(conditions, merge(page, { current: 1 }));
        }
    }, [formData.sourceId]);
    var onChange = function (value, pathKey) {
        var newData = merge({}, formData);
        set(newData, pathKey, value);
        setFormData(newData);
    };
    var onConditionsChange = function (v, pathKey) {
        var newConditions = merge({}, conditions);
        set(newConditions, pathKey, v);
        setConditions(newConditions);
        onSearch(newConditions, merge({}, page, { current: 1 }));
    };
    var onSearch = function (conditions, page) {
        var data = __assign({ conditions: __assign(__assign({}, conditions), { sourceId: formData.sourceId }) }, page);
        setConditions(conditions);
        setTableLoading(true);
        getList(data)
            .then(function (res) {
            setData(res.data.list);
            setPage(merge({}, page, { total: res.data.total }));
            setTableLoading(false);
        })
            .catch(function (err) {
            setTableLoading(false);
            msgError(err);
        });
    };
    var onAppListChange = function (record, selected, selectedRows) {
        var newData = merge({}, formData);
        var newList = merge([], formData.appList || []);
        if (selected) {
            newList.push(record);
        }
        else {
            remove(newList, function (item) { return item.id === record.id; });
        }
        set(newData, "appList", newList);
        setFormData(newData);
    };
    var onListAllChange = function (selected, pageRows, changeRows) {
        var newData = merge({}, formData);
        var newList = merge([], formData.appList || []);
        if (selected) {
            newList.push.apply(newList, changeRows);
        }
        else {
            changeRows.forEach(function (item) {
                remove(newList, function (obj) { return obj.id === item.id; });
            });
        }
        set(newData, "appList", newList);
        setFormData(newData);
    };
    var saveCanCheck = function () {
        if (!formData.segmentName || formData.segmentName === "" || !formData.advertiserId)
            return false;
        if (!formData.sourceId)
            return false;
        if (!formData.appList || formData.appList.length < 4 || formData.appList.length > 10)
            return false;
        return !nameError;
    };
    var save = function () {
        setSaving(true);
        saveApi(formData)
            .then(function (res) {
            msgSuccess(res);
            history.push("/techHub/audience/appAudience/manage");
        })
            .catch(function (err) {
            setSaving(false);
            msgError(err);
        });
    };
    var cancelSelected = function (item) {
        var newData = merge({}, formData);
        var newList = merge([], formData.appList);
        remove(newList, function (obj) { return obj.id === item.id; });
        set(newData, "appList", newList);
        setFormData(newData);
    };
    var renderAppList = function () {
        return formData.appList.map(function (item) { return (<Tag key={item.id} style={{ margin: "5px", fontSize: "13px" }} color="blue" closable={!readonly} onClose={function () { return cancelSelected(item); }}>
        {item.appName} ({item.pkgName})
      </Tag>); });
    };
    var tableProps = {
        rowKey: "id",
        data: data,
        columns: columns(),
        page: page,
        loading: tableLoading,
        onChange: function (page) { return onSearch(conditions, page); },
        rowSelection: readonly ? undefined : {
            selectedRowKeys: formData.appList ? formData.appList.map(function (item) { return item.id; }) : [],
            onSelect: onAppListChange,
            onSelectAll: onListAllChange,
        }
    };
    return (<MySpin spinning={loading}>
    <CommonWrapper>
      <MyFormWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_audience_name", "人群名称")}>
            <MyInput style={{ width: FORM_ITEM_WIDTH }} disabled={readonly} value={formData.segmentName} placeholder={l("common_segmentName_placeholder", "请输入人群名称")} onChange={function (v) { return onChange(v.target.value, "segmentName"); }} errorMsg={l("common_task_name_err_msg_tips", "任务名称只能输入字母数字及下划线且长度不超过50")} validateReg={"^[0-9a-zA-Z_]{1,50}$"} errorCallBack={function (flag) { return setNameError(flag); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_advertiser", "Advertiser")}>
            <MySelect disabled={readonly} showSearch={true} style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_select_advertiser", "Select Advertiser")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={formData.advertiserId} onChange={function (v) { return onChange(v, "advertiserId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_data_source", "数据源")}>
            <MySelect disabled={readonly} showSearch={true} style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_select_data_source", "请选择数据源")} url={"/groupme/audex/audience/appAudience/getSourceOptions"} value={formData.sourceId} onChange={function (v) { return onChange(v, "sourceId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required label={<span>
              {l("audience_app_audience_type", "TYPE - 分析类型")}
              <Tooltip title={l("audience_app_audience_type_tips", "活跃表示近三个月有使用APP行为的设备:安装表示大盘收集到所有安装了APP的设备，含活跃设备。")}>
                <InfoCircleOutlined style={{ marginLeft: "4px" }}/>
              </Tooltip>
            </span>}>
            <MyRadio optionType={"button"} disabled={readonly} url={"/groupme/audex/audience/appAudience/getTypeOptions"} value={formData.typeId} onChange={function (v) { return onChange(v.target.value, "typeId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
      </MyFormWrapper>
      <div style={{ margin: "8px" }}>
        {l("audience_app_audience_app_list", "APP列表")}
      </div>
      <AppList>
        <TableWrapper style={{ marginLeft: 0, width: "70%" }}>
          <MyTableSearch placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={function (value) { return onSearch(merge(conditions, { keyword: value }), merge({}, page, { current: 1 })); }} suffix={<>
              <MyCascader style={{ marginRight: "8px" }} changeOnSelect={true} placeholder={l("audience_app_audience_select_category", "选择分类")} url={"/groupme/audex/audience/appAudience/getCategoryOptions"} onChange={function (v) { return onConditionsChange(v, "categoryIdList"); }} params={{ sourceId: formData.sourceId }}/>
              <MySelect style={{ marginRight: "8px" }} placeholder={l("audience_app_audience_select_audienceSize_placeholder", "安装样本区间")} url={"/groupme/audex/audience/appAudience/getAudienceTypeOptions"} onChange={function (v) { return onConditionsChange(v, "audienceSizeType"); }} params={{ sourceId: formData.sourceId }}/>
            </>}/>
          <MyTable {...tableProps}/>
        </TableWrapper>
        <AppTags>
          <div style={{ marginBottom: "10px" }}>{l("audience_app_audience_selected_app", "已选APP(必须不少于4个)")}</div>
          {formData.appList && formData.appList.length > 0 ? renderAppList() : null}
        </AppTags>
      </AppList>
      {readonly ? null : <FooterBtnWrapper buttonList={[
        {
            type: "primary",
            text: l("common_save", "Save"),
            onClick: function () { return save(); },
            disabled: !saveCanCheck(),
            loading: saving
        }
    ]}/>}
    </CommonWrapper>
  </MySpin>);
};
var AppList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var AppTags = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 16px 0;\n  padding: 14px;\n  overflow-y: auto;\n  flex: 1;\n  border-radius: 10px;\n  border: 1px solid #C5C1C1;\n  box-shadow: 0 10px 20px rgb(18 38 63 / 3%);\n"], ["\n  margin: 16px 0;\n  padding: 14px;\n  overflow-y: auto;\n  flex: 1;\n  border-radius: 10px;\n  border: 1px solid #C5C1C1;\n  box-shadow: 0 10px 20px rgb(18 38 63 / 3%);\n"])));
var templateObject_1, templateObject_2;
