var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { getImgUrl } from "@helpers/const";
export var ErrorPage = function () {
    return <Wrapper bg={getImgUrl("404.jpg")}/>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  background:", ";\n  background-size: contain;\n"], ["\n  position: relative;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  background:", ";\n  background-size: contain;\n"])), function (props) { return "url(" + props.bg + ") center no-repeat"; });
var templateObject_1;
