import { post } from "@helpers/axiosHelper";
var DEFAULT_PAGE_TYPE;
(function (DEFAULT_PAGE_TYPE) {
    DEFAULT_PAGE_TYPE["OUT_LINK"] = "outLink";
    DEFAULT_PAGE_TYPE["APPLY_FOR"] = "applyFor";
})(DEFAULT_PAGE_TYPE || (DEFAULT_PAGE_TYPE = {}));
export var outInfoApi = function (url) {
    return post("/groupme/defaultPage/getPageInfo", { url: url, type: DEFAULT_PAGE_TYPE.OUT_LINK });
};
export var emailUsInfoApi = function (url) {
    return post("/groupme/defaultPage/getPageInfo", { url: url, type: DEFAULT_PAGE_TYPE.APPLY_FOR });
};
