var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { useHistory, useParams } from "react-router-dom";
import { l } from "@helpers/multiLangaugeHelper";
import { getImgUrl } from "@helpers/const";
import { getOpcoTitle, sendForgetMail } from "@pages/account/helpers/async";
import { validateUsername } from "@pages/account/helpers/validator";
import queryString from "query-string";
var loadingTime = 0;
export var ForgetPasswordOpco = function () {
    var history = useHistory();
    var opco = useParams().opco;
    var hasUsername = queryString.parse(location.search).username;
    var _a = useState(hasUsername), username = _a[0], setUsername = _a[1];
    var _b = useState(0), time = _b[0], setTime = _b[1];
    useEffect(function () {
        getOpcoTitle(opco)
            .then(function (res) {
            window.opcoTitle = res.data.opcoTitle || res.data.opco;
            document.title = res.data.opcoTitle || res.data.opco;
        });
    }, []);
    var submit = function () {
        var data = { username: username, opco: opco, languageCode: window.LANG };
        if (errorMsg) {
            return;
        }
        if (!(username && username !== "")) {
            msgError(l("forget_password_email_err", "请输入用户名邮箱"));
            return;
        }
        startLoading();
        sendForgetMail(data)
            .then(function (res) {
            msgSuccess(res);
            history.push("/enter/" + opco);
        })
            .catch(function (err) {
            msgError(err);
        });
    };
    var startLoading = function () {
        loadingTime = 60;
        setTime(loadingTime);
        var loadingLoop = setInterval(function () {
            loadingTime = loadingTime - 1;
            setTime(loadingTime);
            if (loadingTime === 0) {
                clearTimeout(loadingLoop);
                setTime(0);
            }
        }, 1000);
    };
    var errorMsg = validateUsername(username);
    return <Wrapper>
    <EnterWrapper>
      <EnterContentWrapper>
        <Title>{l("forget_password_title", "Reset password")}</Title>
        <SubTitle>{l("forget_password_sub_title", "The system will send a verification email to the email address")}</SubTitle>
        <InputWrapper>
          <EnterInput placeholder={l("forget_password_email_placeholder", "Please enter email address")} value={username} onChange={function (v) { return setUsername(v.target.value); }} hasError={errorMsg}/>
          {errorMsg ? <InputError>{errorMsg}</InputError> : null}
        </InputWrapper>
        <EnterBtn type="primary" loading={time > 0} disabled={time > 0} onClick={submit}>
          {time > 0 ? time + "s" : l("forget_password_send_email", "Send verification email")}
        </EnterBtn>
      </EnterContentWrapper>
    </EnterWrapper>
  </Wrapper>;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  background: url(", ") no-repeat center;\n  background-size: cover;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100vh;\n  background: url(", ") no-repeat center;\n  background-size: cover;\n"])), getImgUrl("enterOpco_bg.png"));
var EnterWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n"], ["\n  position: relative;\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 464px;\n  background: #fff;\n  border-radius: 6px;\n"])));
var EnterContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 180px;\n  }\n"], ["\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  > img {\n    margin: 42px auto;\n    display: block;\n    width: 180px;\n  }\n"])));
var EnterInput = styled(Input)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100% !important;\n  height: 42px !important;\n  border-color:  ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"], ["\n  width: 100% !important;\n  height: 42px !important;\n  border-color:  ", ";\n  font-size: 16px;\n  font-weight: 400;\n  outline: none !important;\n  box-shadow: none !important;\n  color: #7D7D7D;\n"])), function (props) { return props.hasError ? "#f20 !important" : "#D2D2D2 !important"; });
var InputWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  margin-top: 26px;\n  text-align: center;\n  font-size: 22px;\n"], ["\n  position: relative;\n  margin-top: 26px;\n  text-align: center;\n  font-size: 22px;\n"])));
var InputError = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color:#f20;\n"], ["\n  position: absolute;\n  right: 3px;\n  bottom: -26px;\n  width: 100%;\n  text-align: right;\n  font-size: 12px;\n  color:#f20;\n"])));
var EnterBtn = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 24px;\n  display: block;\n  color: #fff;\n  width: 100% !important;\n  height: 48px;\n  background: #1890FF;\n  font-size: 16px;\n"], ["\n  margin-top: 24px;\n  display: block;\n  color: #fff;\n  width: 100% !important;\n  height: 48px;\n  background: #1890FF;\n  font-size: 16px;\n"])));
var Title = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  font-size: 26px;\n  text-align: center;\n"], ["\n  margin-bottom: 10px;\n  font-size: 26px;\n  text-align: center;\n"])));
var SubTitle = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 16px;\n"], ["\n  font-size: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
