import * as React from "react";
import { useEffect, useState } from "react";
import { merge, set } from "lodash";
import { l } from "@helpers/multiLangaugeHelper";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { MyInput } from "@components/baseComponents/myInput";
import { useHistory } from "react-router-dom";
import { getFileType, uploadSubmit } from "./helpers/async";
import { MySelect } from "@components/baseComponents/mySelect";
import { FooterBtnWrapper } from "@/components/common/footerBtnWrapper";
import { FORM_ITEM_WIDTH, MyFormItem } from "@components/common/myFormItem";
import { CommonWrapper } from "@components/common/commonWrapper";
import { MyFormWrapper, MyItemWrapper } from "@components/common/myFormWrapper";
export var Edit = function () {
    var history = useHistory();
    var _a = useState({ fileInfo: [] }), data = _a[0], setData = _a[1];
    var _b = useState([]), fileTypeList = _b[0], setFileTypeList = _b[1];
    var _c = useState(false), saving = _c[0], setSaving = _c[1];
    var _d = useState(false), nameError = _d[0], setNameError = _d[1];
    useEffect(function () {
        getFileType({})
            .then(function (res) {
            setFileTypeList(res.data);
        })
            .catch(msgError);
    }, []);
    var onFileUploadDataChange = function (v, pathKey) {
        var newData = merge({}, data);
        set(newData, pathKey, v);
        setData(newData);
    };
    var onFileInfoChange = function (v, ele, idx) {
        if (data.fileInfo.find(function (item) { return item.id === ele.value; })) {
            var newData_1 = merge({}, data);
            newData_1.fileInfo.find(function (item) { return item.id === ele.value; }).file = v;
            setData(newData_1);
            return;
        }
        var newData = merge({}, data);
        var fileInfo = newData.fileInfo || [];
        fileInfo.push({ id: ele.value, file: v });
        set(newData, ["fileInfo"], fileInfo);
        setData(newData);
        // 判断是否已经有该条id对应file文件流的记录，有则找到该条id记录替换对应的file值，无则push一个新的对象id记录及file值
        // fileInfo: [{id: ,file:},{id: ,file:}]
    };
    var checkSaveDisabled = function () {
        if (!data.segmentName || !data.advertiserId || !(data.fileInfo.length >= 1))
            return true;
        return nameError;
    };
    var onFileUploadDataSubmit = function (data) {
        setSaving(true);
        uploadSubmit(data)
            .then(function (res) {
            setSaving(false);
            history.push("/techHub/audience/customUpload/upload");
            msgSuccess(res);
        })
            .catch(msgError);
    };
    var renderInput = function (ele, idx) {
        return (<MyInput placeholder={l("audience_customUpload_submit_ossAddress", "OSS路径地址")} onChange={function (v) { return onFileInfoChange(v.target.value, ele, idx); }} validateReg={"^[o|O][s|S][s|S][\:\/\/]{3}"} errorCallBack={function (flag) { return setNameError(flag); }} errorMsg={l("audience_customUpload_submit_ossAddress_err_msg_tips", "请粘贴正确的OSS路径地址")} style={{ width: "100%" }}/>);
    };
    return (<CommonWrapper>
      <MyFormWrapper>
        <MyItemWrapper>
          <MyFormItem required label={l("common_advertiser", "广告主")}>
            <MySelect style={{ width: FORM_ITEM_WIDTH }} showSearch={true} placeholder={l("common_select_advertiser", "选择广告主")} url={"/groupme/common/advertiser/getAdvertiserOptions"} value={data.advertiserId} onChange={function (v) { return onFileUploadDataChange(v, "advertiserId"); }}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyItemWrapper>
          <MyFormItem required={true} label={l("common_segmentName", "人群名称")}>
            <MyInput style={{ width: FORM_ITEM_WIDTH }} placeholder={l("common_segmentName", "人群名称")} value={data.segmentName} onChange={function (v) { return onFileUploadDataChange(v.target.value, "segmentName"); }} validateReg={"^[0-9a-zA-Z_]{1,50}$"} errorCallBack={function (flag) { return setNameError(flag); }} errorMsg={l("common_audience_segmentName_err_msg_tips", "人群名称只能输入字母数字及下划线且长度不超过50")}/>
          </MyFormItem>
        </MyItemWrapper>
        <MyFormItem style={{ width: "100%" }} label={l("common_fileInfo", "文件信息")} required={true}>
          {fileTypeList && fileTypeList.map(function (ele, idx) { return (<div style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "17px"
    }}>
                <span style={{ width: "70px" }}>{ele.label}</span>
                {renderInput(ele, idx)}
              </div>); })}
        </MyFormItem>
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", color: "#ccc" }}>
          <div>{l("custom_audience_upload_tips0", "上传文件")}</div>
          <div style={{ marginLeft: "24px" }}>
            <div>{l("custom_audience_upload_tips1", "• 请将Device_ID写在 *.txt（UTF-8编码）或 *.csv（UTF-8编码）格式的文档中，一行一个Device_ID。")}</div>
            <div>{l("custom_audience_upload_tips2", "• 然后将单个/多个 *.txt/*.csv 文档进行压缩成Zip格式上传。支持 *.txt/*.csv 格式文件压缩在同一个 Zip 文件中。")}</div>
            <div>{l("custom_audience_upload_tips3", "• 单个Zip文件大小不超过1G。")}</div>
          </div>
        </div>
      </MyFormWrapper>
      <FooterBtnWrapper buttonList={[
        {
            type: "primary",
            text: l("common_submit", "提交"),
            onClick: function () { return onFileUploadDataSubmit(data); },
            disabled: checkSaveDisabled(),
            loading: saving
        }
    ]}/>
    </CommonWrapper>);
};
