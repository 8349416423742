var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { getImgUrl, LOCAL_STORAGE_OPCO_KEY } from "@helpers/const";
import moment from "moment";
import md5 from "md5";
import { AADLogin, getOpcoTitle } from "@pages/account/helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { l } from "@helpers/multiLangaugeHelper";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@helpers/msalConfig";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { QuestionCircleOutlined } from "@/utils/antdIcon";
import { Dropdown, Menu } from "antd";
import { getRedirectUrlParams } from "@/utils/unit";
import { WechatQrcodeEnter } from "@pages/account/components/wechatQrcodeEnter";
var MenuItem = Menu.Item;
export var Enter = function () {
    var history = useHistory();
    var $msalInstance = new PublicClientApplication(msalConfig);
    var directPath = queryString.parse(location.search).directPath;
    var _a = useState(false), QRVisible = _a[0], setQRVisible = _a[1];
    useEffect(function () {
        if (window.opco) {
            history.push("/enter/" + window.opco);
        }
        getOpcoTitle("groupm")
            .then(function (res) {
            window.opcoTitle = res.data.opcoTitle || res.data.opco;
            document.title = res.data.opcoTitle || res.data.opco;
            if (res.data.skin) {
                window.skin = res.data.skin;
            }
            if (res.data.icoUrl) {
                var ico = document.getElementById("ico");
                var icoUrl = "/objects/groupm/img/favicon/" + res.data.icoUrl + ".ico";
                ico.href = icoUrl;
            }
        });
    }, []);
    var replaceStr = function (str, index, char) {
        var nowTime = moment().format("YYYY-MM-DD");
        var strAry = str.split("");
        strAry[index] = char;
        var resloveString = strAry.join("");
        return resloveString + "_" + nowTime;
    };
    var ssoLogin = function () {
        $msalInstance
            .loginPopup({ scopes: [] })
            .then(function (res) {
            var index = moment().get("isoWeekday") - 1;
            var account = res.account.username.toLowerCase();
            var charCodeAscii = account.charCodeAt(index);
            var transformUpperCode = /[a-z0-9]/.test(account[index]) ? String.fromCharCode(charCodeAscii - 32) : account[index];
            var result = replaceStr(account, index, transformUpperCode);
            var data = { username: account, code: md5(result), opco: "groupm", aad: res };
            AADLogin(data).then(function (res) {
                msgSuccess(res);
                localStorage.setItem(LOCAL_STORAGE_OPCO_KEY, "groupm");
                var url = res.data.url || "/techHub/workplace/index";
                if (directPath && directPath !== "") {
                    url = getRedirectUrlParams(true);
                }
                location.href = url;
            }).catch(function (err) {
                if (err.response.data.data && err.response.data.data.noLink) {
                    msgError(err);
                    return;
                }
                if (err.response.data.data && err.response.data.data.opcoCode) {
                    msgError(err);
                    history.push("/enter/" + err.response.data.data.opcoCode);
                    return;
                }
                history.push("/register?email=" + res.account.username);
                msgError(err);
            });
        })
            .catch(function () {
            msgError(l("enter_account_add_err", "AAD登录失败，可能存在微软账号同步延迟"));
            $msalInstance.logoutPopup()
                .catch(function (error) {
                msgError(error);
            });
        });
    };
    var AADLogout = function () {
        $msalInstance.logoutPopup()
            .catch(function (error) {
            msgError(error);
        });
    };
    var menu = (<Menu>
    <MenuItem>
      <span onClick={function () { return history.push("/enter/groupm_external"); }}>
        {l("enter_account_password_enter", "外部账号登录")}
      </span>
    </MenuItem>
    <MenuItem>
      <span onClick={function () { return history.push("/register"); }}>
        {l("account_register", "账号申请")}
      </span>
    </MenuItem>
    <MenuItem>
      <span onClick={function () { return setQRVisible(true); }}>
        {l("account_wechat_qrcode_enter", "微信扫码登录")}
      </span>
    </MenuItem>
  </Menu>);
    return (<Wrapper>
    <Logo src={getImgUrl("logo/choreograph_white.png")} alt={""}/>
    <Title>Data with purpose.</Title>
    <Btn onClick={ssoLogin}>Sign in with GroupM</Btn>
    {process.env.NODE_ENV !== "production" ? <AADLogoutHref onClick={AADLogout}>
      {l("account_AAD_logout", "AAD logout")}
    </AADLogoutHref> : null}
    <OtherWrapper>
      <Dropdown overlay={menu}>
        <LoginOutUser />
      </Dropdown>
    </OtherWrapper>
    <WechatQrcodeEnter visible={QRVisible} setVisible={setQRVisible} directPath={directPath}/>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  height: 100vh;\n  width: 100%;\n  min-width: 1280px;\n  min-height: 670px;\n  background: url(", ");\n  background-size: cover;\n"], ["\n  position: relative;\n  overflow: hidden;\n  height: 100vh;\n  width: 100%;\n  min-width: 1280px;\n  min-height: 670px;\n  background: url(", ");\n  background-size: cover;\n"])), getImgUrl("choreograph_bg.png"));
var Logo = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin-top: 100px;\n  margin-left: 140px;\n  height: 40px;\n  width: 280px;\n"], ["\n  display: block;\n  margin-top: 100px;\n  margin-left: 140px;\n  height: 40px;\n  width: 280px;\n"])));
var slipColor = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0% {\n    background-position: 100% 50%;\n  }\n  20% {\n    background-position: 0 50%;\n  }\n  100% {\n    background-position: 0 50%;\n  }\n"], ["\n  0% {\n    background-position: 100% 50%;\n  }\n  20% {\n    background-position: 0 50%;\n  }\n  100% {\n    background-position: 0 50%;\n  }\n"])));
var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  margin-top: 240px;\n  margin-left: 140px;\n  font-size: 80px;\n  font-weight: bold;\n  background-image: linear-gradient(63deg, #9150F6 0%, #5F9DF8 25%, #9EEFFC 50%, #d7fdfc 52%, #5F9DF8 75%, #9150F6 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  background-size: 200% 100%;\n  background-position: 100% 50%;\n  animation: ", " 10s forwards infinite;\n"], ["\n  display: inline-block;\n  margin-top: 240px;\n  margin-left: 140px;\n  font-size: 80px;\n  font-weight: bold;\n  background-image: linear-gradient(63deg, #9150F6 0%, #5F9DF8 25%, #9EEFFC 50%, #d7fdfc 52%, #5F9DF8 75%, #9150F6 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  background-size: 200% 100%;\n  background-position: 100% 50%;\n  animation: ", " 10s forwards infinite;\n"])), slipColor);
var Btn = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 50px;\n  margin-left: 140px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 68px;\n  line-height: 68px;\n  width: 320px;\n  font-size: 24px;\n  color: #000;\n  border-radius: 54px;\n  cursor: pointer;\n  background: linear-gradient(95deg, #9150F6 0%, #5F9DF8 30%, #9EEFFC 50%, #5F9DF8 70%, #9150F6 100%) no-repeat 0 0;\n  background-size: 200% 100%;\n  transition: all .8s;\n  &:hover {\n    background-position: 100% 0;\n    color: #fff;\n  }\n"], ["\n  margin-top: 50px;\n  margin-left: 140px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 68px;\n  line-height: 68px;\n  width: 320px;\n  font-size: 24px;\n  color: #000;\n  border-radius: 54px;\n  cursor: pointer;\n  background: linear-gradient(95deg, #9150F6 0%, #5F9DF8 30%, #9EEFFC 50%, #5F9DF8 70%, #9150F6 100%) no-repeat 0 0;\n  background-size: 200% 100%;\n  transition: all .8s;\n  &:hover {\n    background-position: 100% 0;\n    color: #fff;\n  }\n"])));
var AADLogoutHref = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 10px;\n  left: 20px;\n  font-size: 12px;\n  color: #999;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  bottom: 10px;\n  left: 20px;\n  font-size: 12px;\n  color: #999;\n  cursor: pointer;\n"])));
var OtherWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n"], ["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n"])));
var LoginOutUser = styled(QuestionCircleOutlined)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: #fff;\n  font-size: 16px;\n"], ["\n  color: #fff;\n  font-size: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
