import { l } from "@helpers/multiLangaugeHelper";
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
export var columns = function () { return [
    {
        key: "segmentName",
        fixed: "left",
        title: l("common_audience_name", "人群名称"),
        render: [
            {
                key: "segmentName",
                style: { fontSize: "15px" },
            },
            {
                key: "taskId",
                label: l("common_task_code", "任务Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
            {
                key: "segmentId",
                label: l("common_audience_id", "人群Id") + ": ",
                labelStyle: { fontSize: "12px", color: "#95aac9" },
                style: { color: "#95aac9" },
            },
        ]
    },
    {
        key: "profileType",
        title: l("profiling_adminManage_", "Profile Type")
    },
    {
        key: "advertiserName",
        title: l("profiling_adminManage_advertiserName", "Advertiser")
    },
    {
        key: "size",
        align: "right",
        title: l("profiling_adminManage_audience_size", "Audience Size"),
        render: [
            {
                key: "size",
                type: TABLE_RENDER_TYPE_ENUM.FORMAT,
                options: {
                    formatType: "number" /* NUMBER */,
                }
            }
        ]
    },
    {
        key: "createDate",
        title: l("profiling_adminManage_createTime", "Create Date")
    },
    {
        key: "statusName",
        title: l("profiling_adminManage_status", "Status"),
        render: [
            {
                key: "statusName",
                type: TABLE_RENDER_TYPE_ENUM.STATUS
            }
        ]
    },
    {
        key: "operation",
        title: l("operation_action", "Action"),
        fixed: "right",
        width: 160,
        render: [
            {
                key: "upload",
                text: l("upload", "upload"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
            },
            {
                key: "complete",
                // text: record.statusId === 1 ? l("profiling_adminManage_complete", "complete"): null,
                hide: function (record) { return record.statusId === 1; },
                text: l("profiling_adminManage_complete", "complete"),
                type: TABLE_RENDER_TYPE_ENUM.MODAL,
            }
        ]
    },
]; };
