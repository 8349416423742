export var msalConfig = {
    auth: {
        clientId: "2c41a790-5637-4277-bff9-db195ca9fce4",
        authority: "https://login.microsoftonline.com/86cbe1bb-213f-4271-b174-bd59d03c87a3",
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
    },
};
