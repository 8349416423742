var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Result } from "antd";
import { l } from "@/helpers/multiLangaugeHelper";
export var EnvErr = function () {
    var code = useParams().code;
    return (<Wrapper>
    <Result status="500" title={"" + l("errEnv_tips_title", "异常环境联接")} subTitle={<>
        <div>error code: {code}</div>
        <div>
          {l("errEnv_tips", "请尽快联系系统管理员")}，
          <a>{l("techTeam_email", "techdata.mplatformcn@groupm.com")}</a>
        </div>
      </>}/>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n\n"])));
var templateObject_1;
